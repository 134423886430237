import { GET_POPULAR_SEARCHES_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  bikeNames: [],
  cityID: 0,
  isLoading: false
}

export default function popularBikeReducer(state = intialState, action) {

  switch (action.type) {
    case GET_POPULAR_SEARCHES_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case GET_POPULAR_SEARCHES_ACTION.SUCCESS:
      const { cityID, bikeNames } = action.payload;
      return newState(state, { cityID, bikeNames, isLoading: false })

    case GET_POPULAR_SEARCHES_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}