export const HOME = 'HOME'
export const SELL_BIKE = 'SELL_BIKE'
export const SELL_BIKE_VALUATION = 'SELL_BIKE_VALUATION'
export const FRANCHISE = 'FRANCHISE'
export const INVESTORS = 'INVESTORS'
export const BIKE_LIST = 'BIKE_LIST'
export const BIKE_DETAIL = 'BIKE_DETAIL'
export const HOW_ITWORKS = 'HOW_ITWORKS'
export const CAREER = 'CAREER'
export const CONTACT_US = 'CONTACT_US'
export const FAQ = 'FAQ'
export const PRIVACY_POLICY = 'PRIVACY_POLICY'
export const TERM_OF_USE = 'TERM_OF_USE'
export const TERMS_AND_CONDITION = 'TERMS_AND_CONDITION'
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const THANKYOU = 'THANKYOU'
export const STORE_LOCATOR = 'STORE_LOCATOR'
export const JEV_LIST = 'JEV_LIST'
export const JEV_VEHICLE_DETAIL = 'JEV_VEHICLE_DETAIL'
export const STORE_DETAILS = 'STORE_DETAILS'
export const CONTACT_LESS_DELIVERY = 'CONTACT_LESS_DELIVERY'
export const CONTACT_LESS_SERVICE = 'CONTACT_LESS_SERVICE'
export const DOOR_STEP_DELIVERY = 'DOOR_STEP_DELIVERY'
export const EXCHANGE_PAGE = 'EXCHANGE_PAGE'
export const AD_SELL_PAGE = 'AD_SELL_PAGE'
export const SELL_FB_THANKYOU = 'SELL_FB_THANKYOU'
export const GOOGLE_ADS_SELL = 'GOOGLE_ADS_SELL'
export const Sell_Ads_Google_ThankYou = 'Sell_Ads_Google_ThankYou'
export const CEO_PAGE = 'CEO_PAGE'
export const NEW_SERVICE = 'NEW_SERVICE'
export const NEW_BIKES = 'NEW_BIKES'