import { getRecentlyBikes } from '../../redux/actions/recentlyBoughtAction'
import { getCredrPopularBikes } from '../../redux/actions/credrPopularBikesAction'
import { getCustomerTestimonials } from '../../redux/actions/testimonialsAction'
import { getStorePage } from '../../redux/actions/storePageAction'
import { getAllTopSellingBikes } from '../../redux/actions/topSellingBikesAction'
import { getStoreList } from '../../redux/actions/storeListAction'
import { getAllBrands } from '../../redux/actions/brandAction'
import { setCurrentCity } from '../../redux/actions/selectedCityAction'
import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { getCredrPopularSearches } from '../../redux/actions/popularSearchesAction'
import { STORE_DETAILS } from '../constants/pageConstatnts'
import { parseString } from '../utility'

export function storePagePreload(store, url) {
  const promises = []
  promises.push(store.dispatch(getAllBrands()))
  promises.push(store.dispatch(setCurrentPage(STORE_DETAILS)))
  const params = Object.assign({}, url.params)
  if ('cityName' in params) {
    const state = store.getState()
    const cities = state.cities.cityList
    const cityName = parseString(params.cityName)
    promises.push(store.dispatch(getStorePage({ storeName: parseString(params.showroomName) })))
    const city = cities.find(cityObject => {
      return cityName.toLowerCase() === cityObject.cityName.toLowerCase()
    })
    if (city) {
      promises.push(store.dispatch(setCurrentCity(city.cityName)))
      promises.push(store.dispatch(getCredrPopularSearches(city.cityId)))
      promises.push(store.dispatch(getRecentlyBikes(city.cityId)))
      promises.push(store.dispatch(getCredrPopularBikes(city.cityId)))
      promises.push(store.dispatch(getStoreList(city.cityId)))
      promises.push(store.dispatch(getCustomerTestimonials(city.cityId)))
      promises.push(store.dispatch(getAllTopSellingBikes(city.cityId)))
    }
  }

  return promises
}