import { SET_LOADER } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  show: false
}

export default function loaderReducer(state = intialState, action) {

  if (action.type === SET_LOADER) {
    const { show } = action.payload
    return newState(state, { show })
  }

  return state;
}
