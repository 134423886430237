export function filterPincodeFromMapResult(results) {

  let pincode = ''

  for (let i = 0; i < results.length; i++) {
    for (let j = 0; j < results[i].address_components.length; j++) {
      for (let k = 0; k < results[i].address_components[j].types.length; k++) {
        if (results[i].address_components[j].types[k] === "postal_code") {
          pincode = results[i].address_components[j].short_name;
          break;
        }
      }
      if (pincode) break;
    }
    if (pincode) break;
  }
  console.log("map Result", pincode)
  return pincode
}