import { BANNER_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  desktopBanner: [],
  mobileBanner: [],
  isLoading: false
}

export default function bannerReducer(state = intialState, action) {

  switch (action.type) {
    case BANNER_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case BANNER_ACTION.SUCCESS:
      const { desktopBanner, mobileBanner } = action.payload;
      return newState(state, { desktopBanner, mobileBanner, isLoading: false })

    case BANNER_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}