import { Base64 } from 'js-base64'

export function getStringyfyUrlParameter(parameter) {
  return Base64.encode(JSON.stringify(parameter))
}

export function getParsedUrlParameter(parameter) {
  const jsonString = Base64.decode(parameter)
  let json = {};
  try {
    json = JSON.parse(jsonString)
  } catch {
    json = undefined;
  }
  return json;
}