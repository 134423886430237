export function getUrlSanatizedString(stringData) {
  if (stringData) {
    if (stringData.split(' ').length > 1) {
      return stringData.split(' ').join('-')
    } else {
      return stringData
    }
  }
  return stringData
}

export function getBikeTypeUrl(bikeType) {
  return bikeType.concat('s').toLowerCase()
}

export function getBikeName(make, model, variant) {
  return `${make ? `${make}` : ''}${model ? ` ${model}` : ''}${variant ? ` ${variant}` : ''}`
}

export function parseString(string) {
  if (string) {
    if (string.split('-').length > 1) {
      return string.split('-').join(' ')
    } else {
      return string
    }
  }
  return string
}


export function isRegistrationNumber(number) {
  if (number) {
    const registrationNumberRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{2,3}[0-9]{4}$/
    return registrationNumberRegex.test(number)
  }
}