import {SELECT_CITY_ACTION} from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  cityList: [],
  isLoading: false
}

export default function citySelectorReducer(state = intialState, action) {

  switch (action.type) {
    case SELECT_CITY_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case SELECT_CITY_ACTION.SUCCESS:
      const { cityList } = action.payload;
      return newState(state, { cityList, isLoading: false })

    case SELECT_CITY_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}