import { SET_CURRENT_PAGE } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  currentPage: ''
}

export default function currentPageReducer(state = intialState, action) {

  switch (action.type) {
    case SET_CURRENT_PAGE:
      const { currentPage } = action.payload
      return newState(state, { currentPage })

    default:
      return state;
  }
}