import { getAllBanners } from '../../redux/actions/bannerAction'
import { getAllBrands } from '../../redux/actions/brandAction'
import { getAllPopularBikes } from '../../redux/actions/popularBikeListAction'
import { setCurrentCity } from '../../redux/actions/selectedCityAction'
import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { HOME } from '../constants/pageConstatnts'
import { parseString } from '../utility'

export function homePagePreload(store, url) {
  const promises = []
  promises.push(store.dispatch(getAllBanners()))
  promises.push(store.dispatch(getAllBrands()))
  promises.push(store.dispatch(setCurrentPage(HOME)))
  if ('path' in url) {
    if (url.path === '/') {
      promises.push(store.dispatch(getAllPopularBikes()))
      promises.push(store.dispatch(setCurrentCity()))
    } else {
      const urlParam = parseString(url.path.split('/').pop())
      const state = store.getState()
      const cities = state.cities.cityList
      const city = cities.find(cityObject => {
        return urlParam.toLowerCase() === cityObject.cityName.toLowerCase()
      })
      if (city) {
        promises.push(store.dispatch(getAllPopularBikes(city.cityId)))
        promises.push(store.dispatch(setCurrentCity(city.cityName)))
      }
    }
  }
  return promises
}