import { RECENTLY_BOUGHT_BIKES_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  isLoading: false,
  bikeList: []
}

export default function popularBikeReducer(state = intialState, action) {

  switch (action.type) {
    case RECENTLY_BOUGHT_BIKES_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case RECENTLY_BOUGHT_BIKES_ACTION.SUCCESS:
      const { bikeList } = action.payload
      return newState(state, { bikeList, isLoading: false })

    case RECENTLY_BOUGHT_BIKES_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}