import { BIKE_LIST_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  bikeList: [],
  budgetList: [],
  kmRange: {},
  brands: [],
  total: 0,
  isLoading: false
}

export default function bikeListReducer(state = intialState, action) {

  switch (action.type) {
    case BIKE_LIST_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case BIKE_LIST_ACTION.SUCCESS:
      const { bikeList, budgetList, kmRange, brands, total } = action.payload;
      return newState(state, { bikeList, budgetList, kmRange, brands, total, isLoading: false })

    case BIKE_LIST_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}