import { getBanners } from '../../core/services/miscService'
import { BANNER_ACTION } from '../../core/constants/actions'

export function getAllBanners() {
  return dispatch => {
    dispatch(request())
    return getBanners()
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const desktopBanner = apiResponse.bannerResponse.webBannerList
          const mobileBanner = apiResponse.bannerResponse.mobileBannerList
          dispatch(success({
            desktopBanner,
            mobileBanner
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: BANNER_ACTION.REQUEST } }
  function success(banners) { return { type: BANNER_ACTION.SUCCESS, payload: { ...banners } } }
  function failure(error) { return { type: BANNER_ACTION.FAILURE, payload: { ...error } } }
}