import { getCitiesOnType } from '../../core/services/miscService'
import { CITIES_ACTION } from '../../core/constants/actions'
import sortBy from 'lodash/sortBy'

export function getAllCities(type) {
  return dispatch => {
    dispatch(request())
    return getCitiesOnType(type)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const { cityList } = apiResponse
          dispatch(success({
            cityList: sortBy(cityList, 'cityName')
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: CITIES_ACTION.REQUEST } }
  function success(cities) { return { type: CITIES_ACTION.SUCCESS, payload: { ...cities } } }
  function failure(error) { return { type: CITIES_ACTION.FAILURE, payload: { ...error } } }
}