import { ALL_STORE_LIST_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  allStoreList: [],
  isLoading: false
}

export default function allStoreListReducer(state = intialState, action) {

  switch (action.type) {
    case ALL_STORE_LIST_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case ALL_STORE_LIST_ACTION.SUCCESS:
      const { allStoreList } = action.payload
      return newState(state, { allStoreList, isLoading: false })

    case ALL_STORE_LIST_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    case ALL_STORE_LIST_ACTION.RESET:
      return intialState

    default:
      return state;
  }
}