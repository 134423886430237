import moment from 'moment'

export function getYear(date) {
  if (date) {
    return date.split('-').shift()
  }
  return date
}

export function getOnlyDate(date){
  if (date && moment(date).isValid()) {
    const datefield = moment(date).format('DD-MMM-YYYY')
    return `${datefield}`
  }
  return '-'
}