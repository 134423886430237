import { SET_BIKE_FILTER, RESET_BIKE_FILTER } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  filter: {}
}

export default function filterReducer(state = intialState, action) {

  switch (action.type) {
    case SET_BIKE_FILTER:
      const { filter } = action.payload
      return newState(state, { filter })

    case RESET_BIKE_FILTER:
      return intialState

    default:
      return state;
  }
}