import { SET_SHOW } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  show: ''
}

export default function showAppReducer(state = intialState, action) {

  switch (action.type) {
    case SET_SHOW:
      const { show } = action.payload
      return newState(state, { show })

    default:
      return state;
  }
}