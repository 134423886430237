import Cookies from 'universal-cookie'

const cookie = new Cookies()

function setCity(city) {
  cookie.set('currentCity', city, { path: '/',secure: true })
}

function getCity() {
  return cookie.get('currentCity')
}

function removeCity() {
  cookie.remove('currentCity', { path: '/',secure: true })
}

function getGa() {
  return cookie.get('_ga')
}

function setBikeListPayload(payload) {
  cookie.set('payload', payload, { path: '/',secure: true })
}

function removeBikeListPayload() {
  cookie.remove('payload', { path: '/' })
}

function getBikeListPayload() {
  return cookie.get('payload')
}

export {
  setCity,
  getCity,
  removeCity,
  getGa,
  setBikeListPayload,
  getBikeListPayload,
  removeBikeListPayload
}