import { SET_DEVICE } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  device: ''
}

export default function deviceReducer(state = intialState, action) {

  switch (action.type) {
    case SET_DEVICE:
      const { device } = action.payload
      return newState(state, { device })

    default:
      return state;
  }
}