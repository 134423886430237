import React, { Component, Fragment } from 'react'
import CareAppHeader from '../../components/headerCareApp'
import Loader from '../../components/Partials/FullPageLoader'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class CareLayout extends Component {

  render() {
    return (
      <Fragment>
        <Loader />
        <CareAppHeader />
        <div className={this.props.location.state === '?care=yes' ? 'wrapper' : 'wrapper careAppWrapper'}>
          {this.props.children}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  device: state.deviceDetail.device
})

export default withRouter(connect(mapStateToProps)(CareLayout));