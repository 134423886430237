import { SELL_BIKE_SEARCH_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  sellBikes: [],
  isLoading: false
}

export default function sellSearchBikeReducer(state = intialState, action) {

  switch (action.type) {
    case SELL_BIKE_SEARCH_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case SELL_BIKE_SEARCH_ACTION.SUCCESS:
      const { sellBikes } = action.payload
      return newState(state, { sellBikes, isLoading: false })

    case SELL_BIKE_SEARCH_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    case SELL_BIKE_SEARCH_ACTION.RESET:
      return intialState

    default:
      return state
  }
}