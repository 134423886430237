export function getAmount(amount) {
  if (amount) {
    const amountString = amount.toString();
    var lastThree = amountString.substring(amountString.length - 3);
    var otherNumbers = amountString.substring(0, amountString.length - 3);
    if (otherNumbers !== '')
      lastThree = ',' + lastThree;
    const convertedAmount = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return convertedAmount;
  }
  return amount;
}

export function getFinalAmount(amount) {
  if (amount !== undefined && amount !== null) {
    if (`${amount}`.includes('.')) {
      const seperateWithDot = `${amount}`.split('.')
      const newSeparatedAmount = getComaSeparated(seperateWithDot[0])
      return `\u20B9${newSeparatedAmount}`
    }
    if (amount === 'NA') {
      return '-'
    }
    if (amount === '') {
      return '-'
    }
    if (amount === '-') {
      return '-'
    }
    else {
        const newAmount = getComaSeparated(amount)
        return `\u20B9${newAmount}`
    }
  }
  return "-";
}

export function getComaSeparated(amount) {
  const amountString = `${amount}`
  var lastThree = amountString.substring(amountString.length - 3);
  var otherNumbers = amountString.substring(0, amountString.length - 3);
  if (otherNumbers !== '')
    lastThree = ',' + lastThree;
  const convertedAmount = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return `${convertedAmount}`
}