export function getMediumSource(DOCUMENT) {
  const referer = DOCUMENT.referrer
  const data = {}
  if (referer && referer.includes('google')) {
    data.type = 'Google'
    data.utmMedium = 'Organic'
  } else {
    data.type = 'Direct'
    data.utmMedium = 'Website'
  }
  return data
}