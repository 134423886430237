import { getStringyfyUrlParameter } from './'
import query from 'query-string'

export function getPath(pathname, filter, search = '') {
  if (search) {
    const param = query.parse(search)
    if (Object.keys(filter).length) {
      param.f = getStringyfyUrlParameter(filter)
      const { page, ...rest } = param
      return pathname.endsWith('/') ? `${pathname.slice(0, -1)}?${query.stringify({ ...rest })}` : `${pathname}?${query.stringify({ ...rest })}`
    } else {
      const { f, page, ...rest } = param
      if (Object.keys({ ...rest }).length) {
        const newSearch = query.stringify({ ...rest })
        return `${pathname}?${newSearch}`
      } else {
        return pathname
      }
    }
  } else {
    if (Object.keys(filter).length) {
      return pathname.endsWith('/') ? `${pathname.slice(0, -1)}?f=${getStringyfyUrlParameter(filter)}` : `${pathname}?f=${getStringyfyUrlParameter(filter)}`
    } else {
      return pathname
    }
  }
}