import { SET_THANK_YOU, RESET_THANK_YOU } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  formData: {}
}

export default function formDataReducer(state = intialState, action) {

  switch (action.type) {
    case SET_THANK_YOU:
      const { formData } = action.payload
      return newState(state, { formData })

    case RESET_THANK_YOU:
      return intialState

    default:
      return state;
  }
}