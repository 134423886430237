import { getPopularBikes } from '../../core/services/bikeServices'
import { POPULAR_BIKE_LIST_ACTION } from '../../core/constants/actions'

export function getAllPopularBikes(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getPopularBikes(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const popularBikes = apiResponse.popularList
          dispatch(success({
            popularBikes
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: POPULAR_BIKE_LIST_ACTION.REQUEST } }
  function success(popularBikes) { return { type: POPULAR_BIKE_LIST_ACTION.SUCCESS, payload: { ...popularBikes } } }
  function failure(error) { return { type: POPULAR_BIKE_LIST_ACTION.FAILURE, payload: { ...error } } }
}