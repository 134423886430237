import React, { Fragment } from "react"
import { Link } from 'react-router-dom'
import { getUrlSanatizedString } from '../../../core/utility'

const getCityName = city => {
  return city || 'India'
}

const getUrl = (currentCity, type, bike) => {
  if (currentCity)
    return `/all-used-${type}-in-${getUrlSanatizedString(currentCity)}/${bike}`
  return `/all-used-${type}/${bike}`
}

const getBikeUrl = city => {
  if (city) {
    return `/all-used-bikes-in-${getUrlSanatizedString(city)}`
  }
  return `/all-used-bikes`
}

const SeoFooter = ({ currentCity, cities }) => (
  <div className="footer pt-0" >
    <div className="innerBlock">
      <h2 className="font16 font-semibold text-white" >
        <Link className="font16 font-semibold text-white" to={getBikeUrl(currentCity)}>Second Hand Bikes/Used Bikes in {getCityName(currentCity)}</Link>
      </h2>
      <p className="font-light font14 text-white">
        Second Hand Bikes in {getCityName(currentCity)} are quite popular amongst people in the city. Second hand bikes are sold in a price range which is much lower than the price of the brand-new models after complete bike inspection points . Used bikes in {getCityName(currentCity)} are available to the customers in very good conditions and at the best prices from various CredR Showrooms across the {currentCity || 'country'}.
			</p>

      <h2 className="font16 font-semibold text-white">
        <Link className="font16 font-semibold text-white" to="/all-used-bikes">Buy Used Bikes</Link> | <Link className="font16 font-semibold text-white" to="/all-used-scooters">Buy Used Scooters</Link>
      </h2>
      <p className="font-light font14 text-white">
        {
          Boolean(cities.length) &&
          cities.filter(city => city.cityType === 'FUNCTIONAL')
            .map((city, indx, arr) => {
              if (arr.length - 1 === indx)
                return <Link key={city.cityName} className="footer-link" to={`/all-used-bikes-in-${getUrlSanatizedString(city.cityName)}`}>Used Bikes in {city.cityName}</Link>
              return <Fragment key={city.cityName}><Link className="footer-link" to={`/all-used-bikes-in-${getUrlSanatizedString(city.cityName)}`}>Used Bikes in {city.cityName}</Link> | </Fragment>
            })
        }
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to="/sellbikes">Sell Your Bike/Scooter</Link>
      </h2>
      <p className="font-light font14 text-white">
        CredR provides the facility to let you sell your used bikes and scooters in the following cities - Delhi, Noida, Ghaziabad, Gurgaon, Manesar, Palwal, Faridabad, Pune and Bangalore.
      </p>

      {/* <h2>
        <Link className="font16 font-semibold text-white" to="/door-step-service">Service Your Bike/ Scooter</Link>
      </h2>
      <p className="font-light font14 text-white">
        Why should you risk or delay getting your bike serviced when you can get your bike serviced at your convenience? CredR has a high quality bike service plan where you can now get your bikes serviced by trained and fully equipped auto experts. With CredR Care you can get your bike serviced at your doorstep with minimum contact, ensuring customer safety and satisfaction.
      </p> */}

      <h2 className="font16 font-semibold text-white">
        Popular Used Bikes / Second Hand Bikes Brands in {getCityName(currentCity)} - <span className="font-light">Top Used Bike Brands in {getCityName(currentCity)} Include:</span>
      </h2>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Hero')}>Popular Hero Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Glamour')}>Hero Glamour</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-HF-Deluxe')}>Hero HF Deluxe</Link> | Hero Karizma ZMR | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Maestro-Edge')}>Hero Maestro Edge</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Passion')}>Hero Passion</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Pleasure')}>Hero Pleasure</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Achiever')}>Hero Achiever</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Splendor')}>Hero Splendor</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Xtreme')}>Hero Xtreme</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Hero-Duet')}>Hero Duet</Link> | Hero Destini
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Honda')}>Popular Honda Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        Honda Activa-i | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Aviator')}>Honda Aviator</Link> | Honda CB Shine | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Unicorn')}>Honda Unicorn</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Dio')}>Honda Dio</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Dream')}>Honda Dream</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Activa')}>Honda Activa</Link> | Honda CD 110 Dream DX | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Livo')}>Honda Livo</Link> | Honda Hornet | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Navi')}>Honda Navi</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Cliq')}>Honda Cliq</Link> | Honda Grazia | Honda XBlade | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Honda-Activa-5G')}>Honda Activa 5G</Link>
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'TVS')}>Popular TVS Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Jupiter')}>TVS Jupiter</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Scooty')}>TVS Scooty</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Star-City')}>TVS Star City</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Sport')}>TVS Sport</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Wego')}>TVS Wego</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Apache')}>TVS Apache</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-Victor')}>TVS Victor</Link> | TVS NTorq | TVS Radeon | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'TVS-XL')}>TVS XL</Link>
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Bajaj')}>Popular Bajaj Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-Avenger')}>Bajaj Avenger</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-Platina')}>Bajaj Platina</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-Pulsar')}>Bajaj Pulsar</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-Discover')}>Bajaj Discover</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-Dominar')}>Bajaj Dominar</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-CT100')}>Bajaj CT100</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Bajaj-V')}>Bajaj V</Link>
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Royal-Enfield')}>Popular Royal Enfield Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Royal-Enfield-Bullet')}>Royal Enfield Bullet</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Royal-Enfield-Classic')}>Royal Enfield Classic</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Royal-Enfield-Thunderbird')}>Royal Enfield Thunderbird</Link> | Royal Enfield Himalayan | Royal Enfield Continental GT
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Yamaha')}>Popular Yamaha Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-Ray')}>Yamaha Ray</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-YZF')}>Yamaha YZF</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-Fazer')}>Yamaha Fazer</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-FZ')}>Yamaha FZ</Link> | Yamaha SZ RR | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-Alpha')}>Yamaha Alpha</Link> | Yamaha Saluto | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Yamaha-Fascino')}>Yamaha Fascino</Link>
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Suzuki')}>Popular Suzuki Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Suzuki-Access')}>Suzuki Access</Link> | Suzuki Hayate | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Suzuki-V-Storm')}>Suzuki V-Storm</Link> | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Suzuki-Intruder')}>Suzuki Intruder</Link> | Suzuki Let's | <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Suzuki-Gixxer')}>Suzuki Gixxer</Link> | Suzuki Burgman Street
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Piaggio')}>Popular Piaggio Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        Aprilia SR | Vespa
      </p>

      <h2>
        <Link className="font16 font-semibold text-white" to={getUrl(currentCity, 'bikes', 'Mahindra')}>Popular Mahindra Bikes</Link>
      </h2>
      <p className="font-light font14 text-white">
        <Link className="footer-link" to={getUrl(currentCity, 'bikes', 'Mahindra-Centuro')}>Mahindra Centuro</Link> | Mahindra Mojo | Mahindra Gusto
      </p>
    </div>
  </div>
)

export default SeoFooter