import { SET_TITLE } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  title: ''
}

export default function titleReducer(state = intialState, action) {

  switch (action.type) {
    case SET_TITLE:
      const { title } = action.payload
      return newState(state, { title })

    default:
      return state;
  }
}