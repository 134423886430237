import { SET_CURRENT_CITY } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  currentCity: ''
}

export default function currentCityReducer(state = intialState, action) {

  switch (action.type) {
    case SET_CURRENT_CITY:
      const { currentCity } = action.payload
      return newState(state, { currentCity })

    default:
      return state;
  }
}