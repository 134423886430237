import { GET_AREA_NAME_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  areaList: [],
  isLoading: false
}

export default function getAreasReducer(state = intialState, action) {

  switch (action.type) {
    case GET_AREA_NAME_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case GET_AREA_NAME_ACTION.SUCCESS:
      const { areaList } = action.payload
      return newState(state, { areaList, isLoading: false })

    case GET_AREA_NAME_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state
  }
}