import { BIKE_LIST_ACTION } from '../../core/constants/actions'
import { getBikesByFilter } from '../../core/services/bikeServices'

export function getBikes(filter = { pageNumber: 1 }) {
  return dispatch => {
    dispatch(request())
    return getBikesByFilter(filter)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const bikeList = apiResponse.listOfInventoryByFilter
          const budgetList = apiResponse.budgetMinMaxList
          const kmRange = apiResponse.kmDrivenMinMaxValue
          const brands = apiResponse.vehicleFilterList
          const total = apiResponse.count
          dispatch(success({
            bikeList,
            budgetList,
            kmRange,
            brands,
            total
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: BIKE_LIST_ACTION.REQUEST } }
  function success(inventory) { return { type: BIKE_LIST_ACTION.SUCCESS, payload: { ...inventory } } }
  function failure(error) { return { type: BIKE_LIST_ACTION.FAILURE, payload: { ...error } } }
}