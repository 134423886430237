import { SET_DRAWER } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  isOpen: false
}

export default function drawerPageReducer(state = intialState, action) {

  if (action.type === SET_DRAWER) {
    const { isOpen } = action.payload
    return newState(state, { isOpen })
  }
  return state
}
