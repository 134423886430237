import { handleError } from './handleError'
import { handleResponse } from './handleResponse'
import { newState } from './updateState'
import { getUrlSanatizedString, getBikeTypeUrl, parseString, getBikeName } from './urlSanatizedString'
import { getAmount, getFinalAmount } from './currencyPipe'
import { getYear, getOnlyDate } from './yearFromDate'
import { getParsedUrlParameter, getStringyfyUrlParameter } from './urlParameter'
import { getPath } from './getPath'
import { uppercaseFirst } from './uppercaseFirst'
import { getPayload } from './getPayload'
import { navigateToBike, navigateToBikeCareApp } from './bikeNavigation'
import { getMediumSource } from './getMediumSource'
import { initializeGA } from './setGA'
import { getContent } from './makeModelContent'
import { getFaq } from './faqMakeModel'
import { getRange } from './arrays'
import { debounce } from './debounce'
import { gtag } from './setGtag'
import { getLastYear } from './getMinYear'
import { showKmsDriven } from './showKmsDriven'
import { filterPincodeFromMapResult } from './mapPincode'

export {
  handleError,
  handleResponse,
  newState,
  getUrlSanatizedString,
  getAmount,
  getBikeTypeUrl,
  parseString,
  getYear,
  getParsedUrlParameter,
  getStringyfyUrlParameter,
  getPath,
  uppercaseFirst,
  getPayload,
  navigateToBike,
  getMediumSource,
  initializeGA,
  getContent,
  getBikeName,
  getRange,
  debounce,
  getFaq,
  gtag,
  navigateToBikeCareApp,
  getOnlyDate,
  getLastYear,
  showKmsDriven,
  getFinalAmount,
  filterPincodeFromMapResult
}