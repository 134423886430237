import { SET_SELECTED_STORE, RESET_SELECTED_STORE } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  stores: []
}

export default function formDataReducer(state = intialState, action) {

  switch (action.type) {
    case SET_SELECTED_STORE:
      const { stores } = action.payload
      return newState(state, { stores })

    case RESET_SELECTED_STORE:
      return intialState

    default:
      return state;
  }
}