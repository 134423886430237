import { STORE_LIST_ACTION } from '../../core/constants/actions'
import { getStores } from '../../core/services/miscService'

export function getStoreList(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getStores(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const storeList = apiResponse.storeListByCityId
          dispatch(success({ storeList }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: STORE_LIST_ACTION.REQUEST } }
  function success(storeList) { return { type: STORE_LIST_ACTION.SUCCESS, payload: { ...storeList } } }
  function failure(error) { return { type: STORE_LIST_ACTION.FAILURE, payload: { ...error } } }
}

export function resetStore() {
  return dispatch => {
    dispatch({ type: STORE_LIST_ACTION.RESET })
  }
}