import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

class ScrollToTop extends PureComponent {

  componentDidMount() {
    const { pathname, search } = this.props.location
    const hemlet = Helmet.peek()
    const url = `${pathname}${search}`
    ReactGA.set({ page: url })
    if (hemlet && hemlet.title) {
      ReactGA.pageview(url, '', hemlet.title.toString());
    } else {
      ReactGA.pageview(url);
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname, search } = this.props.location
    const { title } = this.props
    const hemlet = Helmet.peek()
    const url = `${pathname}${search}`
    const { location } = prevProps
    const prevUrl = `${location.pathname}${location.search}`
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
    if (url !== prevUrl) {
      ReactGA.set({ page: url })
      if (hemlet && hemlet.title) {
        ReactGA.pageview(url, '', hemlet.title.toString());
      } else {
        ReactGA.pageview(url)
      }
    }
    if (prevProps.title !== title) {
      ReactGA.pageview(url, '', title);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(connect(state => ({ title: state.pageTitle.title }))(ScrollToTop));
