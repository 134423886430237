import { BIKE_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  vehicle: {},
  isLoading: false
}

export default function bikeReducer(state = intialState, action) {

  switch (action.type) {
    case BIKE_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case BIKE_ACTION.SUCCESS:
      const { vehicle } = action.payload;
      return newState(state, { vehicle, isLoading: false })

    case BIKE_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}