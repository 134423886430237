import { BIKE_INSPECTION_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  inspection: [],
  sqiRating: '',
  remarks: '',
  isLoading: false
}

export default function inspectionReducer(state = intialState, action) {

  switch (action.type) {
    case BIKE_INSPECTION_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case BIKE_INSPECTION_ACTION.SUCCESS:
      const { inspection, sqiRating, remarks } = action.payload;
      return newState(state, { inspection, sqiRating, remarks, isLoading: false })

    case BIKE_INSPECTION_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}