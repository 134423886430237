import React, { Component } from 'react'
import { connect } from 'react-redux'
import { searchBike } from '../../../redux/actions/bikeSearchAction'
import { getCity, setCity, removeCity, getGa, setBikeListPayload, removeBikeListPayload } from '../../../core/services/storageService'
import { getAllCities } from '../../../redux/actions/citiesAction'
import { saveSearchResult } from '../../../core/services/miscService'
import { setCurrentCity } from '../../../redux/actions/selectedCityAction'
import { isMobileOnly, isTablet, isAndroid, isIOS, osName, browserName, getUA } from 'react-device-detect';
import { withRouter } from 'react-router-dom'
import { getUrlSanatizedString, debounce } from '../../../core/utility';
import { setSearch } from '../../../redux/actions/searchAction'
import { resetBikeFilter } from '../../../redux/actions/bikeFilterAction'
import { BIKE_DETAIL, BIKE_LIST } from '../../../core/constants/pageConstatnts'
import query from 'query-string'
import { matchPath } from 'react-router-dom';
import { cityRegex } from '../../../core/constants/routeConstants'
import { setSideBar } from '../../../redux/actions/sidebarAction'
import complaintsicon from '../../../assets/images/complaintsicon.svg'
import { Link } from 'react-router-dom'

class SearchBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedCity: 'select',
      selectedType: 'Buy',
      searching: false,
      searchKey: ''
    }
    this.debounceBikeSearch = debounce(this.debounceBikeSearch, 400)
  }

  componentDidMount() {
    const { pathName } = this.props.location
    this.props.dispatch(getAllCities('buy'))
    if (pathName === '/') {
      removeCity()
    } else {
      if (getCity()) {
        this.setState({ selectedCity: getCity().cityId })
        this.props.dispatch(setCurrentCity(getCity().cityName))
      }
    }
  }

  onCityChange = (e) => {
    const { cities } = this.props
    const id = Number(e.target.value);
    const city = cities.find(cityData => {
      return cityData.cityId === id
    })
    if (city) {
      setCity(city)
      this.props.dispatch(setCurrentCity(city.cityName))
    } else {
      removeCity()
      this.props.dispatch(setCurrentCity())
    }
    this.setState({ selectedCity: e.target.value })
  }

  debounceBikeSearch = (searchKey) => {
    this.props.dispatch(searchBike(searchKey))
  }

  onSearch = (e) => {
    const searchKey = e.target.value
    this.setState({ searchKey })
    this.debounceBikeSearch(searchKey)
    if (searchKey) {
      this.setState({ searching: true })
    } else {
      this.setState({ searching: false })
    }
  }

  onTypeChange = (e) => {
    if (e.target.value === 'Sell') {
      this.props.history.push('/sellbikes')
    }
    if (e.target.value === "Exchange") {
      this.props.history.push('/sell-your-bike-buy-new-bike')
    }
  }

  enterHandler = (e) => {
    if (e.key === 'Enter') {
      this.setState({ searching: false })
      this.bikeSearch()
    }
  }

  sendSearchData = () => {
    const isAndroidOrIos = isAndroid ? 'android' : isIOS ? 'ios' : ''
    const deviceType = isTablet ? 'tablet' : isMobileOnly ? 'mobile' : 'desktop'
    const userAgent = getUA
    const searchString = this.state.searchKey
    let cityId = 0
    let gaCookieId = ''

    if (getCity()) {
      cityId = getCity().cityId
    }
    if (getGa()) {
      gaCookieId = getGa()
    }
    const searchData = {
      isAndroidOrIos,
      deviceType,
      userAgent,
      searchString,
      cityId,
      osName,
      browserName,
      gaCookieId
    }
    saveSearchResult(searchData)
  }

  bikeSearch = (bike = '') => {
    const { history, dispatch, location } = this.props
    const searchParameters = {}
    const queryParams = query.parse(location.search)
    const CareApp = 'care' in queryParams
    const BuyAtHome = 'buyathome' in queryParams

    searchParameters.pageNumber = 1


    if (bike.bikeName !== 'Others') {

      if (bike) {
        if (BuyAtHome) {
          const { makeID, modelID, variantID, make, model, variant } = bike;
          searchParameters.makeId = makeID;
          searchParameters.modelId = modelID;
          searchParameters.variantId = variantID;
          searchParameters.storeIdList = ["20", "5063", "3760"]
          this.setState({ searchKey: `${make} ${model} ${variant}` })
        }
        else {
          const { makeID, modelID, variantID, make, model, variant } = bike;
          searchParameters.makeId = makeID;
          searchParameters.modelId = modelID;
          searchParameters.variantId = variantID;
          this.setState({ searchKey: `${make} ${model} ${variant}` })
        }
      } else {
        if (BuyAtHome) {
          if (this.state.searchKey) {
            const { searchKey } = this.state
            if (searchKey.startsWith('*')) {
              searchParameters.registrationNumber = searchKey.replace('*', '')
              searchParameters.storeIdList = ["20", "5063", "3760"]
            } else {
              searchParameters.searchText = this.state.searchKey
              searchParameters.storeIdList = ["20", "5063", "3760"]
              this.sendSearchData()
            }
          }
        }
        else {
          if (this.state.searchKey) {
            const { searchKey } = this.state
            if (searchKey.startsWith('*')) {
              searchParameters.registrationNumber = searchKey.replace('*', '')
            } else {
              searchParameters.searchText = this.state.searchKey
              this.sendSearchData()
            }
          }
        }
      }
      setBikeListPayload(searchParameters)
      dispatch(setSearch(this.state.searchKey))
      if (getCity()) {
        if (CareApp) {
          return history.push(`/all-used-bikes-in-${getUrlSanatizedString(getCity().cityName)}/${location.search}`)
        }
        else {
          console.log("came here1", location.pathname.includes("-used-scooters"))
          if(location.pathname.includes("used-scooters") || location.pathname.includes("used-bikes"))
          return history.push(location.pathname)
          else
          return history.push(`/all-used-bikes-in-${getUrlSanatizedString(getCity().cityName)}`)
         
        }
      }
      if (BuyAtHome) {
        if (getCity()) {
          return history.push(`/all-used-bikes-buy-at-home-in-${getUrlSanatizedString(getCity().cityName)}?buyathome`)
        }
        else {
          return history.push(`/all-used-bikes-buy-at-home?buyathome`)
        }
      }
      else {
        if (CareApp) {
          return history.push('/all-used-bikes/?care=yes')
        }
        else {
          if(location.pathname.includes("used-scooters") || location.pathname.includes("used-bikes"))
          return history.push(location.pathname)
          else return history.push('/all-used-bikes')
        }
      }
    }
    //getCity() ?  history.push(`/all-used-bikes-in-${getUrlSanatizedString(getCity().cityName)}`) : history.push('/all-used-bikes')
  }

  resetSearch = () => {
    setTimeout(() => {
      this.setState({ searching: false })
    }, 150)
  }

  componentDidUpdate(prevProps) {
    const { currentCity, currentPage, dispatch } = this.props
    if (prevProps.currentCity !== currentCity) {
      if (currentCity) {
        this.setState({ selectedCity: getCity().cityId })
      } else {
        this.setState({ selectedCity: 'select' })
      }
    }

    if (prevProps.currentPage !== currentPage) {
      if (currentPage !== BIKE_DETAIL && currentPage !== BIKE_LIST) {
        this.setState({ searchKey: '' })
        removeBikeListPayload()
        dispatch(resetBikeFilter())
      }
    }
    if (this.props.onChangeOfCity) {
      this.props.onChangeOfCity(this.state.selectedCity)
    }
  }

  getTrueOrFalse = () => {
    const { location } = this.props
    return matchPath(location.pathname, {
      path: `/all-used-bikes-in-:city(${cityRegex})-:area`,
      exact: true,
    }) || matchPath(location.pathname, {
      path: `/all-used-scooters-in-:city(${cityRegex})-:area`,
      exact: true,
    })
  }

  //  toggleSideBar = () => {
  //   const {isOpen}=this.props
  //   this.props.dispatch(setSideBar(!isOpen))
  // }

  render() {
    const { cities, searchBikes, location, device } = this.props
    const { selectedCity, selectedType, searching } = this.state
    const queryParams = query.parse(location.search)
    const CareApp = 'care' in queryParams
    return (
      <div className={CareApp ? 'searchBar careAppSearch rounded' : "searchBar rounded"}>
        <div className="position-relative searchBar-bg-container">
          {
            !CareApp &&
            <div className="float-left location rounded-left">
              <select title="Select Option" className="form-control rounded-0 customFormcontrol" onChange={this.onCityChange} value={selectedCity}>
                <option value="select">Select City</option>
                {Boolean(cities.length) && (
                  cities.map(city => (<option key={city.cityId} value={city.cityId}>{city.cityName}</option>))
                )}
              </select>
              <i className="downArrow float-right"></i>
                

            </div>
            
          }
          {
            !CareApp &&
            <div className="buySell float-left">
              <select title="Select Option" className="form-control rounded-0 customFormcontrol" onChange={this.onTypeChange} value={selectedType}>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
                <option value="Exchange">Exchange</option>
              </select>
              <i className="downArrow float-right"></i>
            </div>
          }
          
          <div className="sepration float-left" />
          <input type="text" placeholder="Search your bike" className="form-control searchInput" value={this.state.searchKey} onChange={this.onSearch} onKeyPress={this.enterHandler} onBlur={this.resetSearch}
            disabled={this.getTrueOrFalse()} />
          <ul className="list-group position-absolute elasticSearchList">
            {
              (Boolean(searchBikes.length) && searching) && searchBikes.map(bike => (
                <div key={bike.bikeId} className="searchListElastic">
                  {/* <li className="list-group-item" onClick={() => this.bikeSearch(bike)} >{bike.make} {bike.model} {bike.variant}</li> */}
                  <li className="list-group-item" onClick={() => this.bikeSearch(bike)} >{bike.bikeName === 'Others' ? 'No Results Found' : `${bike.make} ${bike.model} ${bike.variant}`} </li>
                </div>
              ))
            }
          </ul>
          <div className="btn-primary searchIcon rounded-right" onClick={() => this.bikeSearch()}>
            <i className="icon"></i>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cities: state.cities.cityList,
  searchBikes: state.bikeSearch.searchBikeList,
  currentCity: state.currentCity.currentCity,
  currentPage: state.activePage.currentPage,
  device: state.deviceDetail.device,
  isOpen: state.sidebar.isOpen
})

export default connect(mapStateToProps)(withRouter(SearchBar))
