import React from 'react'
import { Switch } from 'react-router-dom'
import '../scss/style.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import '../scss/style.scss'
import { renderRoutes } from 'react-router-config'
import { routes } from './routes'
import Layout from '../containers/layout'
import CareLayout from '../containers/CareLayout'
import ScrollToTop from './scrollToTop'
import '../components/UI/Chip/chip.scss'
import query from 'query-string'

function App() {

  const location = useLocation()
  const device = useSelector(state => state.deviceDetail.device)

  const getSearch = () => {
    const params = query.parse(location.search)
    if('care' in params || location.state === '?care=yes'){
      return true
    }
    else{
      return false
    }
  }

  return (
    <ScrollToTop>
      {
        getSearch() && device === 'phone' ?
          <CareLayout>
            <Switch>
              {renderRoutes(routes)}
            </Switch>
          </CareLayout>
          :
          <Layout>
            <Switch>
              {renderRoutes(routes)}
            </Switch>
          </Layout>
      }
    </ScrollToTop>
  )
}

export default App