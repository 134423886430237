import { getUrlSanatizedString, getBikeTypeUrl } from './'

export function navigateToBike(bike, careSearch) {
  if (bike) {
    let { inventoryId, make, model, variant, cityName, locationName, inventoryType } = bike
    make = getUrlSanatizedString(make)
    model = getUrlSanatizedString(model)
    variant = getUrlSanatizedString(variant)
    cityName = getUrlSanatizedString(cityName)
    let url = inventoryType ? `/all-used-${getBikeTypeUrl(inventoryType)}-in-` : '/all-used-bikes-in-'
    url = locationName ? `${url}${cityName}-${getUrlSanatizedString(locationName)}/${make}-${model}${variant ? `-${variant}` : ''}/${inventoryId}`
      : `${url}${cityName}/${make}-${model}-${variant ? `-${variant}` : ''}/${inventoryId}/${careSearch}`

      return url
  }
  return '/all-used-bikes'
}

export function navigateToBikeCareApp({ bike, careSearch }) {
  if (bike) {
    let { inventoryId, make, model, variant, cityName, locationName, inventoryType } = bike
    make = getUrlSanatizedString(make)
    model = getUrlSanatizedString(model)
    variant = getUrlSanatizedString(variant)
    cityName = getUrlSanatizedString(cityName)
    let url = inventoryType ? `/all-used-${getBikeTypeUrl(inventoryType)}-in-` : '/all-used-bikes-in-'
    url = locationName ? `${url}${cityName}-${getUrlSanatizedString(locationName)}/${make}-${model}${variant ? `-${variant}` : ''}/${inventoryId}${careSearch}`
      : `${url}${cityName}/${make}-${model}-${variant ? `-${variant}` : ''}/${inventoryId}${careSearch}`

    return url
  }
  return '/all-used-bikes'
}