import {SELECT_BRAND_ACTION} from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  brandList: [],
  isLoading: false
}

export default function brandSelectorReducer(state = intialState, action) {

  switch (action.type) {
    case SELECT_BRAND_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case SELECT_BRAND_ACTION.SUCCESS:
      const { brandList } = action.payload;
      return newState(state, { brandList, isLoading: false })

    case SELECT_BRAND_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}