import React, {Component} from 'react'
import './trigger.scss'
import credrlogo from '../../assets/images/credrlogo.png'
import fiveStar from '../../assets/images/FiveStar.png'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

export default class TriggeringOnclick extends Component {

    linkToPlaystore = () => {
        const GAEvent = { category: 'App Click Deep Linking' }
        GAEvent.action = 'App Click Deep Linking Clicked'
        ReactGA.event(GAEvent)
        window.location.href = 'http://corporateservice.app.link'
    }

    closeMobileView = () => {
        const GAEvent = { category: 'App Click Deep Linking' }
        GAEvent.action = 'App Click Deep Linking Dismiss'
        ReactGA.event(GAEvent)
        const {onClose} = this.props
        onClose()
    }

    render(){
    return (
        <>
            <div id='TriggerWrap'>
                    <div className='row' style={{height:116}}>
                        <div className='col-7' style={{height:116}}>
                            <img src={credrlogo} alt='mobile' className='credrLogoPadd' />
                            <div className='wrapStar'>
                            <h2 className='font-bold font20 mb-0'>CredR Bike Care</h2>
                            <img src={fiveStar} alt="fivestar" style={{width:99}}/>
                            <p><span style={{color: '#da2128'}}> Faster </span> on mobile app</p>
                            </div>
                        </div>
                    <div className='col-5' style={{height:116}}>
                        <div className='buttonWrap'>
                                <button className='installButton' onClick={this.linkToPlaystore}>Install Now</button>
                                <br />
                                <button className='dismissButton' onClick={this.closeMobileView}>Dismiss</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
}