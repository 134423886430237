import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUrlSanatizedString } from '../../../core/utility'
import { useLocation } from 'react-router-dom'
import './upperfooter.scss'

const BuyLandingFooter = ({ currentCity }) => {

    const [disable, setDisable] = useState(true)
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/buy-landing-page-for-zest-bikes') {
            setDisable(false)
        }
    }, [])

    const onHomeIconChange = () => {
        window.scrollTo(0, 0)
    }

    return (
        <>
        <div id='zestFooter'>
            <div className="newBuyLandingFooter">
                <div class="header__bg"></div>  
                    <div className='rectRatings'>
                        <div className='iconsWrap'>
                            Exclusive used Zest scooty for sale at CredR.<br className='responsive' /> Book second hand TVS Zest today <br className='responsive' />
                        <button className='bookNow' onClick={onHomeIconChange}>Book Now</button>
                        </div>
                    </div>
                    <h5 className="font-semibold font16 addressWrap">Address</h5>
                    <p className='address'>
                    2nd Floor, A, 212, 1st Main Rd, Stage 2, Domlur, Bengaluru, Karnataka 560071
                    <br />
                    CIN - U50404MH2015PTC261907
                    <br />
                    {/* Call: +91 90755 55557 */}
                    <br />
                    Email: cs@credr.com
                    </p>
                    <h5 className="font-semibold font16 addressWrap" style={{marginTop: '25px'}}>Info</h5>
                    <p className='address'>
                        <Link to="/Terms-of-use" style={{color: '#fff'}}>Terms of Use</Link>
                        <br/>
                        <Link to ="/oem-t&c" style={{color:"#fff"}}>Terms & Conditions</Link>
                    <br />
                    <Link to="/privacy-policy" style={{color: '#fff'}}>Privacy Policy</Link>
                    </p>
            </div>
        </div>
        </>
    )
}

export default BuyLandingFooter