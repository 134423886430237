import { SIMILAR_BIKE_LIST_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  similarBikes: [],
  isLoading: false
}

export default function similarBikeReducer(state = intialState, action) {

  switch (action.type) {
    case SIMILAR_BIKE_LIST_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case SIMILAR_BIKE_LIST_ACTION.SUCCESS:
      const { similarBikes } = action.payload;
      return newState(state, { similarBikes, isLoading: false })

    case SIMILAR_BIKE_LIST_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}