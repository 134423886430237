import { bikeDetailPreload } from './bikeDetailPreload'
import { bikeListPreload } from './bikeListPreload'
import { franchaisePreload } from './franchisePreload'
import { homePagePreload } from './homePage'
import { investorsPreload } from './investorsPreload'
import { sellBikePreload } from './sellBikePreload'
import { careerPreload } from './careerPagePreload'
import { contactUsPreload } from './contactUsPreload'
import { privacyPolicyPreload } from './privacyPolicyPreload'
import { termOfUsePreload } from './termOfUsePreload'
import { faqPreload } from './faqPreload'
import { howItWorksPreload } from './howItWorksPreload'
import { storePagePreload } from './storeDetailsPreload'
import { contactLessDeliveryPreload } from './contactLessDeliveryPreload'
import { contactLessServicePreload } from './contactLessServicePreload'
import { sellPageValuationPreload } from './sellPageValuationPreload'
import { exchangeBikePreLoad } from './exchangePagePreLoad'
import {termAndConditionPreload} from './termAndConditionPreload'

export {
  bikeDetailPreload,
  bikeListPreload,
  franchaisePreload,
  homePagePreload,
  investorsPreload,
  sellBikePreload,
  careerPreload,
  contactUsPreload,
  privacyPolicyPreload,
  termOfUsePreload,
  termAndConditionPreload,
  faqPreload,
  howItWorksPreload,
  storePagePreload,
  contactLessDeliveryPreload,
  contactLessServicePreload,
  sellPageValuationPreload,
  exchangeBikePreLoad
}