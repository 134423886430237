import { getPopularSearches } from '../../core/services/storePageServices'
import { GET_POPULAR_SEARCHES_ACTION } from '../../core/constants/actions'

export function getCredrPopularSearches(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getPopularSearches(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const { cityID, bikeNames } = apiResponse.listOfPopularSearch
          dispatch(success({
            cityID,
            bikeNames
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: GET_POPULAR_SEARCHES_ACTION.REQUEST } }
  function success(popularSearches) { return { type: GET_POPULAR_SEARCHES_ACTION.SUCCESS, payload: { ...popularSearches } } }
  function failure(error) { return { type: GET_POPULAR_SEARCHES_ACTION.FAILURE, payload: { ...error } } }
}