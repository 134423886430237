let baseurl = ''
let paymentUrl= ''
let elasticBase = ''
let RAZORPAY_ID = ''
let baseCCurl = ''
let inspectionUrl = ''
let qrUrl = ''
let RECAPTCHA_KEY=''

/*if (process.env.NODE_ENV === 'development') {
  baseurl = 'http://dev.api.credr.com:8082'
  baseCCurl = 'http://dev.api.credr.com:8080'
  inspectionUrl = 'http://dev.api.credr.com:8081'
  elasticBase = 'http://elastic.credr.com:8080/el'
  RAZORPAY_ID = 'rzp_test_NSit0sRQqaEPXw'
}else if (process.env.NODE_ENV === 'test') {
  baseurl = 'https://qaapi.credr.com:8082'
  inspectionUrl = 'https://qaapi.credr.com:8081'
  elasticBase = 'https://qaapi.credr.com:9000/el'
  RAZORPAY_ID = 'rzp_test_NSit0sRQqaEPXw'
}else if (process.env.NODE_ENV === 'qa') {
  inspectionUrl = 'https://qaapi.credr.com:8081'
  baseurl = 'https://qaapi.credr.com:8082'
  baseCCurl = 'https://qaapi.credr.com:8080'
  elasticBase = 'https://qaapi.credr.com:9000/el'
  RAZORPAY_ID = 'rzp_test_NSit0sRQqaEPXw'
}else if (process.env.NODE_ENV === 'production') {
  inspectionUrl = 'https://prodapi.credr.com:8081'
  // baseurl = 'https://apiv2.credr.com'
  baseurl = 'https://prodapi.credr.com:8082'
  baseCCurl = 'https://prodapi.credr.com:8080'
  // elasticBase = 'https://apiv2.credr.com/web'
  // elasticBase = 'https://prodapi.credr.com:8082'
  elasticBase = 'https://prodapi.credr.com:9000/el'
  RAZORPAY_ID = 'rzp_live_NSByPicxepheoL'
}*/

if (process.env.NODE_ENV === 'development') {
  baseurl = 'https://qaapi.credr.com:8082';
	qrUrl = 'https://qaapi.credr.com:8080';
  paymentUrl = 'https://qaapi.credr.com:9085';
	elasticBase = 'https://elastic.credr.com:8080/el';
	RAZORPAY_ID = 'rzp_test_NSit0sRQqaEPXw';
  RECAPTCHA_KEY='6LfjT90pAAAAADJWe0IFUPl_p2nZGt4kAdsfZWjN';
} else {
	baseurl = process.env.REACT_APP_API_URL+'8082';
  qrUrl = process.env.REACT_APP_API_URL+'8080';
  paymentUrl = process.env.REACT_APP_API_URL+'9085';
  inspectionUrl = process.env.REACT_APP_API_URL+'8081';
	elasticBase = process.env.REACT_APP_API_URL+'9000/el';
  RAZORPAY_ID = process.env.REACT_APP_RAZORPAY_ID;
  if(process.env.REACT_APP_ENV==="qa" || process.env.REACT_APP_ENV==="qanew"){
    RECAPTCHA_KEY='6LfjT90pAAAAADJWe0IFUPl_p2nZGt4kAdsfZWjN';
  }
  else{
    RECAPTCHA_KEY='6Lc829wpAAAAAPwq7hnI47XNK7evlAGPtBwY_Mkf';
  }
  
}
console.log(process.env,"process.env aakash")
console.log(process.env.REACT_APP_API_URL,"process.env.REACT_APP_API_URL aakash")
export const API_ENDPOINTS = {
  BIKES: {
    GET_BUDGET_BIKE_LIST: `${baseurl}/web/getInventoryByBudget`,
    GET_POPULAR_BIKE_LIST: `${baseurl}/web/getPopularBikes/<CITY_ID>`,
    GET_ALL_MAKES: `${baseurl}/web/getAllMakes`,
    GET_BIKE: `${baseurl}/web/getVehicleDetail/<BIKE_ID>`,
    GET_INSPECTION_DETAILS: `${baseurl}/web/getInspectionParameters/<BIKE_ID>`,
    GET_SIMILAR_BIKES: `${baseurl}/web/similarbikes`,
    GET_FILTERED_BIKES: `${baseurl}/web/getInventoryByFilter`,
    GET_MANUFACTURERS: `${baseurl}/web/manufactures`,
    GET_MODELS: `${baseurl}/web/models/<BM_ID>`,
    GET_VARIANTS: `${baseurl}/web/variants/<MODEL_ID>`,
    SEARCH_BIKES: `${elasticBase}/search/<CITY_ID>/<SEARCH>`,
    SEARCH_BIKES_EL: `${baseurl}/web/searchBikes/<SEARCH>`,
    SELL_BIKE_SEARCH: `${baseurl}/web/mmvSearch/<SEARCH>`,
    GET_TOP_SELLING_BIKES: `${baseurl}/web/popularBikes/<CITY_ID>`,
    SEARCH_BIKE_BY_CITY: `${baseurl}/web/sellOnlyMmv/<CITY_ID>/<MMV>`,
    GET_EXCHANGE_BIKE_BRAND_LIST: `${baseurl}/web/getOEMBikeList?city=<CITY_ID>`,
    GET_EXCHANGE_BIKE_MODEL_LIST: `${baseurl}/web/exchangeBike/models/<MAKE_ID>`,
    GET_CITY_LIST: `${baseurl}/web/getOEMEnabledCityList`,
    GET_EXCHANGE_SELL_PRICE: `${baseurl}/web/getOEMBikePrice?city=1&dealer=1&map_mmv_id=1`,
    GET_BIKE_INFO: `${baseurl}/web/bike/info`,
    GET_STORE_LIST_EXCHANGE: `${baseurl}/web/store/list/<LEAD_ID>`,
    UPDATE_STORE_LIST: `${baseurl}/web/book/store/`,
    GET_EXCHANGE_TO_SELL: `${baseurl}/web/sell/update`,
    GET_MFG_YEARS: `${baseurl}/web/getBvYears/<VARIANT_ID>`,
    GET_VARIANTS_BY_ID: `${baseurl}/web/variant/<MODEL_ID>`,
    GET_RTO: `${baseurl}/web/rtoCodes?cityId=<CITY_ID>`,
    GET_RTO_PROCURABLE: `${baseurl}/web/getRto/<CITY_ID>`,
    SUBMIT_RTO_CODE: `${baseurl}/web/nextStage`,
    GENERATE_BUY_OTP: `${baseurl}/web/sandeshBuy`,
    VERIFY_BUY_OTP: `${baseurl}/web/verifyOtpBuyInventory`,

    GET_ACTIVE_CITIES_EXCHANGE: `${baseurl}/web/getActiveCities/exchange`,
    GET_ACTIVE_CITIES_BUY: `${baseurl}/web/getActiveCities/buy`,
    GET_MODEL_VARIANTS_LIST_EXCHANGE: `${baseurl}/web/getModelAndVariantsForExchange/<MAKE_ID>`,
    GET_LEAD_INSPECTION_SLOTS: `${baseurl}/web/leadDetails/<LEAD_ID>`,
  },
  MISC: {
    GET_BANNERS: `${baseurl}/web/getbannerimage`,
    GET_CITIES: `${baseurl}/web/getAllCities`,
    GET_STORE_LIST: `${baseurl}/web/storelist/<CITY_ID>`,
    GET_ALL_STORES: `${baseurl}/web/storeslist`,
    SAVE_SEARCH: `${baseurl}/web/saveSearchHistory`,
    GET_AREA_NAME: `${baseurl}/web/areaNames`,
    GET_LOCALITY_NAME: `${baseurl}/web/locality`,
    UPLOAD_IMAGES: `${baseurl}/web/uploadUserDocs`,
    GET_MAKES: `${baseurl}/web/getMake/<CITY_ID>`,
    GET_MODELS: `${baseurl}/web/getModel/<MAKE_NAME>/<CITY_ID>`,
    GET_VARIANTS_BY_MODEL: `${baseurl}/web/getVariant/<MODEL_NAME>`,
    GENERATE_OTP: `${baseurl}/web/sandeshSell`,
    VERIFY_OTP: `${baseurl}/web/verifyOTP`,
    SHOWROOM_LIST: `${baseurl}/web/getFhdList/<CITY_ID>`,
    GET_TIME_SLOTS: `${baseurl}/logistics/getAvailableTimeSlots`,
    VALIDATE_COUPON: `${baseurl}/web/validate/coupon`,
    GET_AVIALABLE_TIME_SLOTS: `${baseurl}/web/getAvailableTimeSlots`,
    GET_SERVICE_CITIES: `${baseurl}/web/getcities`,
    UPDATE_MOBILE_NUMBER: `${baseurl}/web/edit`,
    GET_RUNNER_AVAILABLE_SLOTS: `${baseurl}/web/getRunnerAvailableTimeSlots`,
    CHECK_PAYMENT_STATUS: `${baseurl}/web/shouldIPayForBuyAtHome`,
    GET_BIKE_DETAILS: `${baseurl}/web/getPaymentUrlDetails`,
    GET_NOTIFICATION: `${baseurl}/web/notifyMe`,
    GET_SERVICE_SLOTS: `${baseurl}/web/service/getAvailableTimeSlots`,
    GET_CITY_LIST_ON_TYPE: `${baseurl}/web/getActiveCities/<TYPE>`,
    GET_SERVICE_LEAD_DETAILS: `${inspectionUrl}/inspection/service/getServiceLeadDetails`,
    GET_FRANCHISE_OTP: `${baseurl}/web/createOtpForFranchise`,
    GET_VERIFY_OTP : `${baseurl}/web/verifyOtpForFranchise`,
    GET_QR_DATA : `${qrUrl}/turboxo/getLeadDetailsForFSM/<LEAD_ID>`,
    GET_SERVICE_PLANS: `${baseurl}/web/getServiceCode`
  },
  LEAD: {
    CREATE_LEAD: `${baseurl}/web/createLead`,
    CREATE_SELL_LEAD: `${baseurl}/web/create/lead`,
    FRANCHISE_LEAD: `${baseurl}/web/capture/franchiselead`,
    UPDATE_LEAD: `${baseurl}/web/customer/update `,
    CREATE_SELL_LEAD_V2: `${baseurl}/web/sell_only_v2`,
    VALIDATE_RTO_CODES: `${baseurl}/web/toValidateRtoCodes`,
    UPDATE_SELL_LEAD: `${baseurl}/web/patchSellOnlyLead`,
    UPDATE_SELL_ADS_LEAD: `${baseurl}/web/sell/api2`,
    GET_SELL_LEAD: `${baseurl}/web/getLeadDetails/<LEAD_ID>`,
    GET_REASONS: `${baseurl}/web/reasons`,
    GET_SELL_BRANDS: `${baseurl}/web/makes/<REASON_ID>`,
    EXCHANGE_LEAD: `${baseurl}/web/registerOEMExchangeCustomer`,
    BUY_ADS_LEAD: `${baseurl}/web/buy/lead`,
    GET_ADS_BANNER: `${baseurl}/web/adPage/urlContent/adPageUrl`,
  },
  STORE: {
    GET_STORE_DESCRIPTION: `${baseurl}/web/getStore`,
    GET_POPULAR_BIKES: `${baseurl}/web/credrPopularBikes/<CITY_ID>`,
    GET_POPULAR_SEARCHES: `${baseurl}/web/popularSearch/<CITY_ID>`,
    GET_TESTIMONIALS: `${baseurl}/web/getTestimonials/<CITY_ID>`,
    GET_RECENTLY_BOUGHT_BIKES: `${baseurl}/web/getRecentlyPurchasedVehicles/<CITY_ID>`,
  },
  PAYMENTS: {
    CREATE_ORDER: `${baseurl}/web/process_payment`,
    CREATE_ORDER_FITNESS: `${paymentUrl}/razorPay/createOrderForFitnessReport`,
    CREATE_ORDER_ON_PAYMENT_PORT: `${paymentUrl}/payments/process_payment`,
    VERIFY_PAYMENT: `${baseurl}/web/verify_payment`,
    VERIFY_PAYMENT_FITNESS: `${paymentUrl}/razorPay/verifyFitnessReportPayment`,
    VERIFY_PAYMENT_ON_PAYMENT_PORT: `${paymentUrl}/payments/verify_payment`,

  },
  PARTNERS_APIS: {
    JEV: {
      LIST_VEHICLE: `${baseurl}/web/getjevinventory`,
    },
  },
  IMAGES: {
    IMAGES_BASE_PATH: "https://dqbkg06i6c7gd.cloudfront.net/assets/images",
    IMAGES_BASE_PATH_ZEST:"https://dqbkg06i6c7gd.cloudfront.net/assets/images/zestbikesbuylandingpage",
    IMAGE_USER_DOCS_PATH:"https://s3.ap-south-1.amazonaws.com/credruserdocuments",
      PDF_PATH:"https://credr-fitness-report.s3.ap-south-1.amazonaws.com/sample"
  },
};

export const MAP_API_KEY = "AIzaSyCb9hQrJLn40QibztarPYBlusGbT4X_0D8";
export const GOOGLE_MAP_LOCATION_KEY =
  "AIzaSyD9Q9dmCB7p5fcO-2lGwup9eytEgR_jvYs";
export { RAZORPAY_ID,RECAPTCHA_KEY };
