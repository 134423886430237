import { SET_SEARCH } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  search: ''
}

export default function searchReducer(state = intialState, action) {

  switch (action.type) {
    case SET_SEARCH:
      const { search } = action.payload
      return newState(state, { search })

    default:
      return state;
  }
}