import { goToStorePage } from '../../core/services/storePageServices'
import { GET_STORE_ACTION } from '../../core/constants/actions'
import { setLoader } from './loaderAction'

export function getStorePage(store) {
  return dispatch => {
    dispatch(setLoader(true))
    dispatch(request())
    return goToStorePage(store)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const storeData = apiResponse.storeDetails
          dispatch(success({
            storeData
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
        dispatch(setLoader(false))
      })
  }

  function request() { return { type: GET_STORE_ACTION.REQUEST } }
  function success(storeData) { return { type: GET_STORE_ACTION.SUCCESS, payload: { ...storeData } } }
  function failure(error) { return { type: GET_STORE_ACTION.FAILURE, payload: { ...error } } }
}