import { getTopSellingBikes } from '../../core/services/bikeServices'
import { TOP_SELLING_BIKE_LIST_ACTION } from '../../core/constants/actions'

export function getAllTopSellingBikes(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getTopSellingBikes(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const { popularList: topSellingBikes } = apiResponse
          dispatch(success({
            topSellingBikes
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: TOP_SELLING_BIKE_LIST_ACTION.REQUEST } }
  function success(popularList) { return { type: TOP_SELLING_BIKE_LIST_ACTION.SUCCESS, payload: { ...popularList } } }
  function failure(error) { return { type: TOP_SELLING_BIKE_LIST_ACTION.FAILURE, payload: { ...error } } }
}