import { SET_BIKE_FILTER, RESET_BIKE_FILTER } from '../../core/constants/actions'

export function setBikeFilter({ filter }) {
  return dispatch => {
    dispatch({
      type: SET_BIKE_FILTER,
      payload: { filter }
    })
  }
}

export function resetBikeFilter() {
  return dispatch => {
    dispatch({
      type: RESET_BIKE_FILTER
    })
  }
}