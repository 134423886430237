import { JEV_LIST_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  jevInventory: [],
  isLoading: false
}

export default function jevListReducer(state = intialState, action) {
  switch (action.type) {
    case JEV_LIST_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case JEV_LIST_ACTION.SUCCESS:
      const { jevInventory } = action.payload
      return newState(state, { jevInventory, isLoading: false })

    case JEV_LIST_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}