function debounce(funcToExecute, executeAfter) {
  let timer
  return function () {
    clearTimeout(timer)
    const context = this
    const args = arguments
    timer = setTimeout(() => {
      funcToExecute.apply(context, args)
    },
      executeAfter
    )
  }
}

export { debounce }