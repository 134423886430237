import React from 'react'

 function getFaqHTMLString(makeModel) {
  
  switch (makeModel) {
    case 'HERO-GLAMOUR': return [{
      question: 'What is the average mileage of Hero Glamour?',
      answer: 'The average mileage given by Hero Glamour is 57 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Glamour introduced in the Indian market?',
      answer: 'The Hero Glamour bike was launched in the year 2008.',
      id: 'collapseTwo'
    },
    {
      question: 'Is Hero Glamour bike suitable for daily commute?',
      answer: 'Yes, the Hero Glamour bike has long seat, excellent handle bar support and a good mileage which makes it a perfect fit for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'What are the colours available in Hero Glamour?',
      answer: `The Hero Glamour bike is available in 5 different colours blaze, sports red, radiant red, techno blue anf tornado grey.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy Hero Glamour online at CredR?',
      answer: 'Yes, you can buy the Hero Glamour bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-HF-DELUXE': return [{
      question: 'What is the average mileage of Hero HF Deluxe?',
      answer: 'The average mileage given by Hero HF Deluxe bike is  70 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero HF Deluxe bike introduced in the Indian market?',
      answer: 'The Hero HF Deluxe bike was launched in December 2019.',
      id: 'collapseTwo'
    },
    {
      question: 'Is Hero HF Deluxe bike suitable for daily commute?',
      answer: 'Yes, the Hero HF Deluxe bike has long seat, powerful headlamps for safe driving during the night and a good mileage which makes it a perfect fit for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero HF Deluxe?',
      answer: `The Hero HF Deluxe bike is available in 5 colours namely heavy grey / green, techno blue, black / red, heavy grey / black and black / purple.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero HF Deluxe bike online at CredR?',
      answer: 'Yes, you can buy the Hero HF Deluxe bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-MAESTRO-EDGE': return [{
      question: 'What is the average mileage of Hero Mastero Edge scooter?',
      answer: 'The average mileage given by Hero Mastero Edge scooter is 45 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Mastero Edge scooter introduced in the Indian market?',
      answer: 'The Hero Mastero Edge scooter was launched in year 2012 .',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Mastero Edge scooter suitable for daily commute?',
      answer: 'Yes, the Hero Mastero Edge scooter has long and wide seating style, under seat storage and a good mileage which makes it a perfect scooter for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero Mastero Edge scooter?',
      answer: `The Hero Mastero Edge scooter is available in 8 different colours in India. The eight colours include matte vernier grey, matte techno blue, matte brown, prismatic purple, stealth black, panther black, pearl fadeless white and matte red.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Mastero Edge scooter online at CredR?',
      answer: 'Yes, you can buy the Hero Mastero Edge scooter online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]
    case 'USED-BIKES-IN-PUNE-SUZUKI-ACCESS': return [
      {
      question: 'What is the average mileage of Suzuki Access?',
      answer: 'The average mileage of second hand Suzuki access is 53 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'Is Suzuki Access good for long drives?',
      answer: 'The used access scooty is perfect for daily commuting. It has great power, comfort in sitting, best absorbers, light handle, comfortable seat and beat mileage advantages that makes it great in the long run. ',
      id: 'collapseTwo'
    },
    {
      question: 'Is Suzuki Access a good scooter?',
      answer: 'The most demanding Suzuki Access is considered the best scooter in India. It is quite unusual as the scooty is powered by a bigger 125cc motor, and it generates decent power & torque figures. It has an impressive fuel efficiency as well. ',
      id: 'collapseThree'
    },
    {
      question: ' Which colour is best in Suzuki Access 125?',
      answer: 'The best Suzuki access colour is grey and black of all the colours, and it is the highest sold model of all. ',
      id: 'collapseFour'
    },
    {
      question: ' Is Suzuki Access worth buying?',
      answer: 'The well-featured Suzuki access is rich in every segment and misses out on fancy upgrades. The used scooty has a high-tech engine, side stand indicator, and updated BS6, and it has almost everything required to continue as a perfect two-wheeler for daily commuting. ',
      id: 'collapseFive'
    },
    {
      question: 'How many colours are available in Suzuki Access scooty?',
      answer: 'There are seven unique colours for Suzuki Access scooty. It includes dark greenish-blue, deep blue, mirage white, sparkle black, platinum silver, Fibroin grey and Bordeaux red.',
      id: 'collapseSix'
    },
    {
      question: 'How many gears are available in Suzuki Access 125?',
      answer: 'There are CVT gears that are available in Suzuki Access 125.',
      id: 'collapseSeven'
    },
    {
      question: 'How many top variants of Suzuki Access 125 are available?',
      answer: 'There are six top Suzuki Access variants which include: Suzuki Access Drum Cast, Suzuki Access STD, Suzuki Access Disc CBS, Suzuki Access Disc CBS Special Edition, Suzuki Access Drum Alloy Bluetooth and Suzuki Access Disc Alloy Bluetooth.',
      id: 'collapseEight'
    },

  ]
    case 'HERO-PASSION': return [{
      question: 'What is the average mileage of the Hero Passion Pro bike?',
      answer: 'The average mileage given by Hero Passion Pro bike is 60 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Passion Pro bike introduced in the Indian market?',
      answer: 'The Hero Passion Pro bike was launched in year 2012 for sale.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Passion Pro bike suitable for daily commute?',
      answer: 'Yes, the Hero Passion Pro bike has long seat, good mileage and excellent braking support which makes it a perfect bike for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero Passion Pro bike?',
      answer: `The Hero Passion Pro bike is available in 4 different colours in India. The 4 colours include glaze black, moon yellow, grey metallic and sports red.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Passion Pro bike online at CredR?',
      answer: 'Yes, you can buy the Hero Passion Pro bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-PLEASURE': return [{
      question: 'What is the average mileage of Hero Pleasure scooter?',
      answer: 'The average mileage if Hero Pleasure scooter is around 50kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Pleasure scooter introduced in the Indian market?',
      answer: 'The Hero Pleasure scooter was launched was launched in the year 2006.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Pleasure scooter suitable for daily commute?',
      answer: 'Defenitely, the Hero Pleasure scooter bike has a comfortable seat, above average fuel economy and extra storage support which makes it a perfect scooter for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero Pleasure scooter?',
      answer: `There is a total of 7 colours variants in the Hero Pleasure scooter.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Pleasure scooter online at CredR?',
      answer: 'Yes, you can buy the Hero Pleasure scooter online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-ACHIEVER': return [{
      question: 'What is the average mileage of Hero Achiever?',
      answer: 'The average mileage level of Hero Achiever is said to be around 50 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Achiever bike introduced in the Indian market?',
      answer: 'The Hero Achiever bike was launched was launched in the year 2006.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Achiever for daily commute?',
      answer: 'Yes, with an average mileage economy the Hero Achiever bike has an above average fuel economy and excellent suspension which makes it a perfect bike for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero Achiever bike?',
      answer: `There is a total of 5 colours available with the Hero Achiever bike.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Achiever bike online at CredR?',
      answer: 'Yes, you can buy the Hero Achiever bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-SPLENDOR-PLUS': return [{
      question: 'What is the average mileage of Hero Splendor Plus?',
      answer: 'The average mileage level of Hero is said to be 80 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was Hero Splendor Plus bike introduced in the Indian market?',
      answer: 'The Hero Splendor bike was initially launched in the year 1994 while the Hero Splendor Plus was launched in the year 2016.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Splendor Plus bike suitable for daily commute?',
      answer: 'Yes, the Hero Splendor bike was designed specifically to support the daily commute. With a good comfortable seat and above average fuel economy system the Hero Splendor is one of the best bikes for commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Hero Splendor Plus bike?',
      answer: `The Hero Splendor Plus bike is available in combinations of back / silver, balck / purple, black / sports red, grey / green, bumble bee yellow, firefly golden and beatle red.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Splendor Plus bike online at CredR?',
      answer: 'Yes, you can buy the Hero Splendor Plus bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-XTREME': return [{
      question: 'What is the average mileage of Hero Xreme 200 S?',
      answer: 'The average mileage level of Hero Xreme is said to be 39 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When is the Hero Xreme 200 S bike expected to be introduced in the Indian market?',
      answer: 'The Hero Xreme 200 S is all set to be launched in the end of this year.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Xreme 200 S bike suitable for daily commute?',
      answer: 'No, the Hero Xtreme 200 S is preferably suited for longer road trips. The Hereo Xtreme 200 S bike is known for their power and sporty look.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available Hero Xreme 200 S in bike?',
      answer: `The Hero Xtreme 200 S, still hasn’t given a specific date but rumors have said that the bike is to be launched in 2 colour variants.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Xreme 200 S bike online at CredR?',
      answer: 'Yes, you can buy the Hero Hero Xreme 200 S bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HERO-DUET': return [{
      question: 'What is the average mileage of Hero Duet scooter?',
      answer: 'The average mileage level of Hero Duet scooter is said to be 28 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'When was  the Hero Duet scooter introduced in the Indian market?',
      answer: 'The Hero Duet scooter was laucnhed in the 2015. ',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Hero Duet scooter bike suitable for daily commute?',
      answer: 'Yes, the Hero Duet scooter bike has a comfortable seat, above average fuel economy and extra storage support which makes it a perfect scooter for daily commute.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available Hero Duet scooter in bike?',
      answer: `The Hero Duet scooter is available in 6 different colours namely red, silver, white, grey, black, matte grey and green.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Hero Hero Duet scootere online at CredR?',
      answer: 'Yes, you can buy the all Hero Duet scooters online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-AVIATOR': return [{
      question: 'What is the average mileage of Honda Aviator?',
      answer: 'The average mileage level of Honda Aviator is said to be upto 45 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is are the highlights of Honda Aviator scooter?',
      answer: 'Extra long seats, storage space, Honda Eco Technology are some of the special features of the Honda Aviator scooter.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Aviator scooter suitable for daily commute?',
      answer: 'Yes, with added unique features the Honda Aviator scooter is specially made for daily commuters.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Aviator scooter?',
      answer: `The Honda Aviator scooter is available in 4 different colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Aviator online at CredR?',
      answer: 'Yes, you can buy the Honda Aviator scooter online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-UNICORN': return [{
      question: 'What is the average mileage of Honda Unicorn?',
      answer: 'The average mileage level of Honda Unicorn bike is said to be upto 52 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is are the highlights of Honda Unicorn bike?',
      answer: 'Comfortable and supportable frame, excellant suspension and supportive grab rail with added fuele economy range are some of the best features of Honda Unicorn bike.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Unicorn bike suitable for daily commute?',
      answer: 'Yes, the Honda Unicorn bike was specifically launched to support daily commuters. The Honda Unicorn is one of the famous commute bikes.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Unicorn?',
      answer: `The Honda Unicorn bike is available in 3 different colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Unicorn bike online at CredR?',
      answer: 'Yes, you can buy the Honda Unicorn online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-DIO': return [{
      question: 'What is the average mileage of Honda Dio?',
      answer: 'The average mileage level of Honda Dio is 48 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is are the highlights of a Honda Dio scooter?',
      answer: 'Stylish yet simple design, automatic gearbox, extra storage and good suspension are some of the best features of Honda Dio scooters.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Dio scooter suitable for daily commute?',
      answer: 'Yet another scooter series from Honda, the Honda Dio is an example of daily commute scooters.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Dio?',
      answer: `The Honda Dio scooter is available in 7 different colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Dio scooter at CredR?',
      answer: 'Yes, you can buy Honda Dio online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-DREAM': return [{
      question: 'What is the average mileage of Honda Dream?',
      answer: 'The average mileage level of Honda Dream is 74 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is are the highlights of a Honda Dream bike?',
      answer: 'Aesthetic simple design and good fuel economy range, the Honda Dream model is a commuter bike suitable for regular drives and long road trips.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Dream bike is suitable for daily commute?',
      answer: 'Yes, the Honda Dream is an example of daily commute scooters.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Dream?',
      answer: `The Honda Dream bike is available in 4 unique colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Dream bike at CredR?',
      answer: 'Yes, you can buy Honda Dream bike online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-ACTIVA': return [{
      question: 'What is the average mileage of Honda Activa?',
      answer: 'The average mileage level of Honda Activa is 50kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What  are the highlights of a Honda Activa scooter?',
      answer: 'Honda Activa is one of the largest selling scooters in India. The scooter is known for its exclusive fuel efficiency system, comfortable big seat, good suspension and extra storage space are some of the highlights of this scooter.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Activa suitable for daily commuters?',
      answer: 'Yes, the Honda Activa scooter is definitely the best example of daily commute scooters with its features like extra mileage, comfortable seat support and extra storage space.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Activa?',
      answer: `The Honda Activa scooter is available in 5 unique colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Activa scooter at CredR?',
      answer: 'Yes, you can buy from a wide range of  Honda Activa models online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-LIVO': return [{
      question: 'What is the average mileage of Honda Livo?',
      answer: 'The average mileage of Honda Livo is 60 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What  are the highlights of a Honda Livo scooter?',
      answer: 'Honda Livo is one of the best selling scooters in India. The scooter is known for its fuel efficiency system, good seat and extra storage space are some of the highlights of this scooter.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Livo suitable for daily commuters?',
      answer: 'Yes, the Honda Livo scooter is one of the best daily commute scooters with its features like extra mileage, comfortable seat support and extra storage space.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Livo?',
      answer: `The Honda Livo scooter is available in 5 unique colours. Athletic Blue, Metallic Black, Imperial Red, Metallic Matt Axis Grey Metallic.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Livo scooter online at CredR?',
      answer: 'Yes, you can buy from a wide range of  Honda Livo models online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-NAVI': return [{
      question: 'What is the average mileage of Honda Navi?',
      answer: 'The average mileage of Honda Navi is 47 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What are the highlights of Honda Navi?',
      answer: 'Honda Navi is the apt combination of a bike and scooter. The Honda Navi is featured with a combi brake system, automatic gear and the seating style of a bike which makes it pretty unique. ',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Navi suitable for daily commuters?',
      answer: 'Yes, the Honda Navi bike scooter is one of the best daily commute bikes with its features like extra mileage, comfortable seat and support making it rank among the best of bikes.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Navi?',
      answer: `The Honda Navi collection comes in 6 unique colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Navi online at CredR?',
      answer: 'Yes, you can buy the  Honda Navi models online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-CLIQ': return [{
      question: 'What is the average mileage of Honda Cliq?',
      answer: 'The average mileage of Honda Cliq 60 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What are the highlights of Honda Cliq?',
      answer: 'Honda Cliq is the best automatic scooter in the market for its lightness. The Honda Cliq scooter gets a wide footboard, USB charging port, minimalist design and 14 litre storage capacity making it one of the most special scooters in the market.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Cliq suitable for daily commuters?',
      answer: 'Yes, the Honda Cliq scooter is one of the best daily commuter scooters mainly because of its light weight model and other features like extra mileage and support.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Cliq?',
      answer: `The Honda Cliq collection comes in 4 colours Patriot Red, Orcus Grey, Morrocan Blue and Black.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Cliq scooter online at CredR?',
      answer: 'Yes, you can buy the Honda Cliq models online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'HONDA-ACTIVA-5G': return [{
      question: 'What is the average mileage of Honda Activa?',
      answer: 'The average mileage level of Honda Activa is 50kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What are the highlights of a Honda Activa scooter?',
      answer: 'Honda Activa is one of the largest selling scooters in India. The scooter is known for its exclusive fuel efficiency system, comfortable big seat, good suspension and extra storage space are some of the highlights of this scooter.',
      id: 'collapseTwo'
    },
    {
      question: 'Is the Honda Activa suitable for daily commuters?',
      answer: 'Yes, the Honda Activa scooter is definitely the best example of daily commute scooters with its features like extra mileage, comfortable seat support and extra storage space.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Honda Activa?',
      answer: `The Honda Activa scooter is available in 5 unique colours.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I buy the Honda Activa scooter online at CredR?',
      answer: 'Yes, you can buy from a wide range of  Honda Activa models online at CredR and can get it delivered at your doorstep.',
      id: 'collapseFive'
    }]

    case 'BAJAJ-AVENGER': return [{
      question: 'What is the average mileage of Bajaj Avenger?',
      answer: 'The average mileage level of Bajaj Avenger bike is said to be around is 50kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine of  Bajaj Avenger?',
      answer: 'The Bajaj Avenger bike’s engine is powered with a 160cc single cylinder engine.',
      id: 'collapseTwo'
    },
    {
      question: 'Which Bajaj bike has the best seating style?',
      answer: 'The Bajaj Avenger bike has the best seating style due it incredibly low seat height and style.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Bajaj Avenger?',
      answer: `The Bajaj Avenger bike comes in 2 different paint schemes.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I sell my current bike in exchange for a Bajaj Avenger?',
      answer: 'Yes, CredR offers a new scheme were customers can sell their existing bike and can buy any bike from CredR and can ride it back home.',
      id: 'collapseFive'
    }]

    case 'BAJAJ-PLATINA': return [{
      question: 'What is the average mileage of Bajaj Platina?',
      answer: 'The average mileage level of Bajaj Platina bike is said to be around is 75kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine power of Bajaj Platina?',
      answer: 'The Bajaj Platina bike’s engine is powered with a 102cc single cylinder engine.',
      id: 'collapseTwo'
    },
    {
      question: 'Can the Bajaj Platina bike be used for long drives?',
      answer: 'Yes, the Bajaj Platina bike has a great seat structure along with efficient fuel economy range and 13 litre fuel tank capacity which makes it suitable for long drives.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Bajaj Platina?',
      answer: `The Bajaj Platina bike comes in 2 different colours balck and red.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I sell my current bike in exchange for a Bajaj Platina?',
      answer: 'Yes, CredR offers a new scheme were customers can sell their existing bike and can buy any bike from CredR and can ride it back home. You can now sell you current bike and can buy a Bajaj Platina bike in perfect condition from us.',
      id: 'collapseFive'
    }]

    case 'BAJAJ-PULSAR': return [{
      question: 'What is the average mileage of Bajaj Pulsar 150 ?',
      answer: 'The average mileage level of Bajaj Pulsar 150 bike is said to be around  55kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine power of Bajaj Pulsar 150?',
      answer: 'The Bajaj Pulsar 150 bike’s engine is powered with a 149.5cc aircooled fuel injected single cylinder engine with a BS6 update.',
      id: 'collapseTwo'
    },
    {
      question: 'Can the Bajaj Pulsar bike be used for daily commute?',
      answer: 'Yes, the Bajaj Pulsar 150 bike has a great seat style along with an efficient fuel economy system which makes it suitable for long drives.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Bajaj Pulsar 150?',
      answer: `The Bajaj Pulsar 150 bike comes in 6 different colour schemes.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I sell my current bike in exchange for a Bajaj Pulsar 150?',
      answer: 'Yes, CredR offers a new scheme were customers can sell their existing bike and can buy any bike from CredR and can ride it back home. You can now sell you current bike and can buy a Bajaj Pulsar 150 bike in perfect condition from us.',
      id: 'collapseFive'
    }]
    case 'ALL-USED-BIKES-AHMEDABAD': return [
      {
        question: 'How many second hand bikes are available in Ahmedabad?',
        answer: 'There are more than 567+ second hand bikes in Ahmedabad readily available for you. Booking or buying these bikes is possible online and offline. You can take a test ride and buy your favorite 2nd hand bike.',
        id: 'collapseOne'
      },
      {
        question: 'What are the most popular brands available for second hand bikes in Ahmedabad?',
        answer: 'The most popular brands available for second hand bikes in Ahmedabad are Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, Bajaj, Piaggio, Jawa, and more.',
        id: 'collapseTwo'
      },
      {
        question: 'What will be the starting price of used bikes in Ahmedabad?',
        answer: 'The starting price of used bikes in Ahmedabad varies from the below range of Rs.20,000 onwards. Once you have visited the website, you can filter the budget ranges and explore your kind of used bikes.',
        id: 'collapseThree'
      },
      {
        question: 'How to buy a second hand bike in Ahmedabad?',
        answer: `Buying a second hand bike in Ahmedabad is made easy and quick. By visiting the online website or downloading the CredR app, you can witness the extensive range of bikes that are listed in order. By choosing the location and budget, you’ll be able to narrow down the buying options for the used-wheelers. You can check out the list of the available bikes and get your choice of bike. It is necessary to fill in the details accurately. Take a test ride from home at Rs.399 only. Further, you can decide on the bike.`,
        id: 'collapseFour'
      },
      {
        question: 'Where should we buy second hand bikes in Ahmedabad?',
        answer: <>
          <div className="row">
            <div className="col-12">
              <p>You’ll get an assorted collection of top brands bikes like Honda, Hero, Suzuki, TVS, Royal Enfield, KTM, Yamaha, Royal Enfield, and many more used bikes in Ahmedabad from the CredR showroom and online as well. There are numerous reasons for you to choose CredR. It offers you ample benefits like:</p>
            </div>
            <ul>
              <li>Great price</li>
              <li>1 Month engine protect</li>
              <li>Refurbished bikes</li>
              <li>Budget-friendly</li>
              <li>No legal hassle</li>
            </ul>

            <div className="col-12">
              <p>Get your favorite second hand motorcycle from CredR’s Ahmedabad showroom.</p>
            </div>
          </div>
        </>,
        id: 'collapseFive'
      }
    ]
    case 'BAJAJ-DOMINAR-250': return [{
      question: 'What is the average mileage of Bajaj Dominar 250?',
      answer: 'The average mileage level of Bajaj Dominar 250 bike is said to be around  30 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine power of Bajaj Dominar 250?',
      answer: 'The Bajaj Dominar 250 bike’s engine is powered with a 248cc single cylinder, liquid cooled engine.',
      id: 'collapseTwo'
    },
    {
      question: 'Can the Bajaj Dominar 250 bike be used for daily commute?',
      answer: 'Yes, the Bajaj Dominar 250 bike has a great seat style along with an efficient fuel economy system which makes it suitable for both daily commute and long drives.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Bajaj Dominar 250?',
      answer: `The Bajaj Dominar 250 bike comes in 2 different colour schemes.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I sell my current bike in exchange for a Bajaj Dominar 250?',
      answer: 'Yes, CredR offers a new scheme were customers can sell their existing bike and can buy any bike from CredR and can ride it back home. You can now sell you current bike and can buy a Bajaj Dominar 250 bike in perfect condition from us.',
      id: 'collapseFive'
    }]

    case 'BAJAJ-CT-100': return [{
      question: 'What is the average mileage of Bajaj CT100?',
      answer: 'This high fuel efficiency bike is said to have an average mileage level of 70 kmpl.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine power Bajaj CT100?',
      answer: 'The Bajaj CT100 bike’s engine is powered with a 115cc air cooled single cylinder engine.',
      id: 'collapseTwo'
    },
    {
      question: 'Can the Bajaj CT100 bike be used for daily commute?',
      answer: 'Yes, the Bajaj CT100 bike has a an excellent fuel efficiency system which makes it compactable for both daily commute and long drives.',
      id: 'collapseThree'
    },
    {
      question: 'How many colours are available in Bajaj CT100?',
      answer: `The Bajaj CT100 bike comes in 3 different colour schemes.`,
      id: 'collapseFour'
    },
    {
      question: 'Can I sell my current bike in exchange for a Bajaj CT100?',
      answer: 'Yes, CredR offers a new scheme were customers can sell their existing bike and can buy any bike from CredR and can ride it back home. You can now sell you current bike and can buy a Bajaj CT100 bike in perfect condition from us.',
      id: 'collapseFive'
    }]

    case 'ROYAL-ENFIELD-BULLET-350': return [{
      question: 'Which is the most popular Royal Enfield bike?',
      answer: 'The Royal Enfield Bullet 350 is the most popular bike from Royal Enfield.',
      id: 'collapseOne'
    },
    {
      question: 'How many variants are there in Royal Enfield Bullet 350?',
      answer: 'There is a total of 2 variants in the Royal Enfield Bullet 350 category.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the weight of Royal Enfield Bullet 350 bike?',
      answer: 'The weight of a Royal Enfield Bullet 350 is estimated around 186kg ',
      id: 'collapseThree'
    },
    {
      question: 'Which is the most sold colour scheme in Royal Enfield Bullet 350?',
      answer: `The black coloured Royal Enfield 350 is the most sold variant.`,
      id: 'collapseFour'
    },
    {
      question: 'How can I exchange my bike for a Royal Enfield Bullet 350 bike?',
      answer: 'You can simply sell your bike at our website https://www.credr.com/sellbikes and can buy a Royal Enfield Bullet 350 bike at a great exchange rate.',
      id: 'collapseFive'
    }]
    case 'ROYAL-ENFIELD-CLASSIC': return [{
      question: 'Which is the more efficient Royal Enfield Classic 350 or Royal Enfield Interceptor?',
      answer: 'When compared between the two, the Royal Enfield Classic 350 is the most efficient bike.',
      id: 'collapseOne'
    },
    {
      question: 'How many variants are there in Royal Enfield Classic 350?',
      answer: 'There is a total of 2 variants in the Royal Enfield Classic 350 category.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the fuel tank capacity of Royal Enfield Classic 350 bike?',
      answer: 'The fuel tank capacity of the Royal Enfield Classic 350 is around 13.5L.',
      id: 'collapseThree'
    },
    {
      question: 'Which is the most sold colour scheme in Royal Enfield Classic 350?',
      answer: `The Royal black coloured Royal Enfield Classic 350 is the most sold variant.`,
      id: 'collapseFour'
    },
    {
      question: 'How can I exchange my bike for a Royal Enfield Classic 350 bike?',
      answer: 'You can simply sell your bike at our website https://www.credr.com/sellbikes and can buy a Royal Enfield Classic 350 bike at a great exchange rate.',
      id: 'collapseFive'
    }]

    case 'ROYAL-ENFIELD-THUNDERBIRD': return [{
      question: 'Which is the most comfortable Royal Enfield Bike?',
      answer: 'The Royal Enfield Thunderbird is the most comfortable bike due to its better seating style.',
      id: 'collapseOne'
    },
    {
      question: 'How many variants of Royal Enfield Thunderbird is available?',
      answer: 'There are two models in Royal Enfield Thunderbird bike- kick start and self start variants.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the engine power of Royal Enfield Thunderbird?',
      answer: 'The Royal Enfield Thunderbird has a 346cc  single cylinder, 4 stroke, aircooled engine.',
      id: 'collapseThree'
    },
    {
      question: 'What is the mileage of Royal Enfield Thunderbird bike?',
      answer: `The mileage level of Royal Enfield Thunderbird is around 35 kmpl.`,
      id: 'collapseFour'
    },
    {
      question: 'How can I exchange my bike for a Royal Enfield Thunderbird bike?',
      answer: 'You can simply sell your bike at our website https://www.credr.com/sellbikes and can buy a Royal Enfield Thunderbird bike at a great exchange rate.',
      id: 'collapseFive'
    }]

    case 'YAMAHA-RAY': return [{
      question: 'What are the competition models of Yamaha Ray ZR 125?',
      answer: 'Some of the best competition models for Yamaha Ray ZR 125 are Honda Activa 5G, Suzuki Access 125 and TVS Jupiter.',
      id: 'collapseOne'
    },
    {
      question: 'Did Yamaha Ray ZR 125 get BS6 update?',
      answer: 'Yes, the Yamaha Ray ZR 125 scooter recently got its BS6 update.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the fuel tank capacity of Yamaha Ray ZR 125?',
      answer: 'The fuel tank capacity of Yamaha Ray ZR 125 is around 21 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Yamaha Ray ZR 125 comfortable for long rides ?',
      answer: `With an average mileage of 45 kmpl, the Yamaha Ray ZR 125 is suitable for long rides when compared to most scooters.`,
      id: 'collapseFour'
    },
    {
      question: 'What are the colours available in Yamaha Ray ZR 125?',
      answer: 'The 7 different colour variants in Yamaha Ray ZR 125 are Cyan Blue, Sparkle Green, Dark Matt Blue, Matt Red Metallic and Purplish Blue Metallic.',
      id: 'collapseFive'
    }]

    case 'YAMAHA-FAZER': return [{
      question: 'What are the competition models of Yamaha Fazer?',
      answer: 'Some of the best competition models for Yamaha Fazer include Bajaj Pular, Hero Xtreme and Suzuki Gixxer.',
      id: 'collapseOne'
    },
    {
      question: 'Did Yamaha Fazer bike get BS6 update?',
      answer: 'No, the Yamaha Fazer bike has not gotten any update recently.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the fuel tank capacity of Yamaha Fazer?',
      answer: 'The fuel tank capacity of Yamaha Fazer is around 14 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Yamaha Fazer  comfortable for road trips ?',
      answer: `With an average mileage of 45 kmpl and excellent seating style Yamaha Fazer bike is suitable for long rides and daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'What are the colours available in Yamaha Fazer model?',
      answer: 'The 3 different colour variants in Yamaha Fazer bike is Ravine Red, Wilderness Black and Terrain White.',
      id: 'collapseFive'
    }]

    case 'YAMAHA-FZ': return [{
      question: 'What are the competition models of Yamaha FZ?',
      answer: 'Some of the competition models for Yamaha FZ  include Honda Dream, Hero HF Deluxe, Suzuki Intruder etc.',
      id: 'collapseOne'
    },
    {
      question: 'Did Yamaha FZ get BS6 update?',
      answer: 'No, the Yamaha FZ bike has not got any updates recently.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the fuel tank capacity of Yamaha FZ?',
      answer: 'The fuel tank capacity of Yamaha FZ  is around 14 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Yamaha FZ bike comfortable for daily commute ?',
      answer: `With an average mileage of 40 kmpl, the Yamaha FZ bike is suitable for regular daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'What are the colours available in Yamaha FZ bike?',
      answer: 'The colours in Yamaha FZ bike include dark matt blue, racing blue, metallic red, metallic black, dark knight, gey and cyan blue and matt black.',
      id: 'collapseFive'
    }]

    case 'YAMAHA-ALPHA': return [{
      question: 'What are the competition models of Yamaha Alpha?',
      answer: 'Some of the competition models for the Yamaha Alpha scooter are Honda Activa, Honda Dio, Mahindra Centuro etc.',
      id: 'collapseOne'
    },
    {
      question: 'Did the Yamaha Alpha scooter get BS6 update?',
      answer: 'No, the Yamaha Alpha scooter did not get BS6 update.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the under the seat storage capacity of Yamaha Alpha scooter?',
      answer: 'The under the seat storage capacity of Yamaha Alpha scooter is around 21 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Yamaha Alpha scootercomfortable for daily commute ?',
      answer: `With an average mileage of 60 kmpl, the Yamaha Alpha scooter is best suited for regular daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'How many colours are the colours available in Yamaha Alpha scooter?',
      answer: 'There are about 11 colour variants in the Yamaha Alpha scooter variant.',
      id: 'collapseFive'
    }]

    case 'YAMAHA-FASCINO': return [{
      question: 'What are the competition models of Yamaha Fascino?',
      answer: 'Some of the competition models for the Yamaha Fascino scooter are Honda Activa, Honda Dio, Aprilla and Vespa.',
      id: 'collapseOne'
    },
    {
      question: 'Did the Yamaha Fascino scooter get a BS6 update?',
      answer: 'Yes, the Yamaha Fascino scooter received a BS6 update recently.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the under the seat storage capacity of Yamaha Fascino scooter?',
      answer: 'The under the seat storage capacity of Yamaha Fascino scooter is around 21 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Yamaha Fascino scooter comfortable for daily commute ?',
      answer: `With an average mileage of 58 kmpl, the Yamaha Fascino scooter is best suited for daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'How many colours are the colours available in Yamaha Fascino scooter model?',
      answer: 'There are about 7 colour variants in the Yamaha Fascino scooter model.',
      id: 'collapseFive'
    }]

    case 'SUZUKI-ACCESS-125': return [{
      question: 'What are the competition models of Suzuki Access 125?',
      answer: 'Some of the competition models for the Suzuki Access 125cc scooter are Honda Activa, Honda Dio, Yamaha Ray and TVS Jupiter.',
      id: 'collapseOne'
    },
    {
      question: 'Did the Suzuki Access 125 scooter get a BS6 update?',
      answer: 'Yes, the Suzuki Access 125 scooter received a BS6 update earlier this year.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the under the seat storage capacity of Yamaha Fascino scooter?',
      answer: 'The under the seat storage capacity of Suzuki Access 125 scooter is around 21.8 litres.',
      id: 'collapseThree'
    },
    {
      question: 'Is Suzuki Access 125 scooterscooter comfortable for daily commute ?',
      answer: `With an above average mileage and wide seater style the Suzuki Access 125 scooter is best suited for daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'How many colours are the colours available in Suzuki Access 125 scooter model?',
      answer: 'There are about 5 colour variants in the Suzuki Access 125 scooter model.',
      id: 'collapseFive'
    }]

    case 'SUZUKI-HAYATE': return [{
      question: 'What is the engine capacity of Suzuki Hayate?',
      answer: 'The Suzuki Hayate model bike has a 112cc single cylinder aircooled engine.',
      id: 'collapseOne'
    },
    {
      question: 'When was the Suzuki Hayate bike launched?',
      answer: 'The Suzuki Hayate bike was launched in the year 2016.',
      id: 'collapseTwo'
    },
    {
      question: 'Did the Suzuki Hayate bike get a BS6 update?',
      answer: 'No, the Suzuki Hayate bike did not get a BS6 update.',
      id: 'collapseThree'
    },
    {
      question: 'What is the fuel tank capacity of Suzuki Hayate?',
      answer: `The fuel tank capacity of Suzuki Hayate is around 10 liters.`,
      id: 'collapseFour'
    },
    {
      question: 'Does Suzuki Hayate bike support comfortable daily commute ?',
      answer: 'Yes, the Suzuki Hayate supports daily commute with a mileage of 50 kmpl.',
      id: 'collapseFive'
    }]

    case 'SUZUKI-INTRUDER': return [{
      question: 'What is the engine capacity of Suzuki Intruder?',
      answer: 'The Suzuki Intruder model bike has a 155cc single cylinder aircooled engine.',
      id: 'collapseOne'
    },
    {
      question: 'When was the Suzuki Hayate bike launched?',
      answer: 'The Suzuki Hayate bike was launched in the year 2016.',
      id: 'collapseTwo'
    },
    {
      question: 'Did the Suzuki Intruder bike get a BS6 update?',
      answer: 'Yes, the Suzuki Intruder bike recently got its BS6 update.',
      id: 'collapseThree'
    },
    {
      question: 'What is the fuel tank capacity of Suzuki Intruder?',
      answer: `The fuel tank capacity of Suzuki Intruder 11 liters.`,
      id: 'collapseFour'
    },
    {
      question: 'Does Suzuki Intruder bike support comfortable daily commute ?',
      answer: 'Yes, the Suzuki Intruder bike supports daily commute but is best suited for long drives.',
      id: 'collapseFive'
    }]

    case 'SUZUKI-GIXXER': return [{
      question: 'What is the engine capacity of Suzuki Gixxer?',
      answer: 'The Suzuki Gixxer model bike has a 155cc fuel injected single cylinder engine.',
      id: 'collapseOne'
    },
    {
      question: 'When was the Suzuki Gixxer bike launched?',
      answer: 'The Suzuki Gixxer bike was launched in the year 2014.',
      id: 'collapseTwo'
    },
    {
      question: 'Did the Suzuki Gixxer bike get a BS6 update?',
      answer: 'Yes, the Suzuki Gixxer bike recently got its BS6 update.',
      id: 'collapseThree'
    },
    {
      question: 'What is the fuel tank capacity of Suzuki Gixxer?',
      answer: `The fuel tank capacity of Suzuki Gixxer is 12 liters.`,
      id: 'collapseFour'
    },
    {
      question: 'Does the Suzuki Gixxer bike support comfortable daily commute ?',
      answer: 'Yes, the Suzuki Gixxer bike supports daily commute but is best suited for long drives.',
      id: 'collapseFive'
    }]

    case 'TVS-JUPITER': return [{
      question: 'What are the special features of TVS Jupiter?',
      answer: `The TVS Jupiter has a special CVTi Technology for it's engine which increases it on road performance.`,
      id: 'collapseOne'
    },
    {
      question: 'Which is better for daily commute, TVS Jupiter or TVS Apache?',
      answer: 'Both are equally best for both short and long drives as they have a great on road performance and fuel efficiency.',
      id: 'collapseTwo'
    },
    {
      question: 'Can I buy a TVS Jupiter scooter online in Bangalore?',
      answer: 'Yes, you can buy TVS Jupiter scooters and other bikes online at CredR and can get it delivered to your doorstep.',
      id: 'collapseThree'
    },
    {
      question: 'How efficient is the BS6 update on TVS Jupiter?',
      answer: `The BS6 update on TVS Jupiter has increased the fuel economy and the total performance of the bike.`,
      id: 'collapseFour'
    },
    {
      question: 'What are the rival models for TVS Jupiter?',
      answer: 'Scooters like Honda Activa 6G, Suzuki Access 125 and Yamaha Ray are some of the rivals of TVS Jupiter.',
      id: 'collapseFive'
    }]

    case 'TVS-SPORT': return [{
      question: 'Is TVS Sport among the most popular bikes in India?',
      answer: 'Yes, TVS Sport is one of the most fuel efficient and lightweight bikes in the market.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine capacity of the TVS Sport bike?',
      answer: 'The TVS Sport bike has a 109cc single cylinder air cooled engine.',
      id: 'collapseTwo'
    },
    {
      question: 'How many variants are available in the TVS Sport bike?',
      answer: 'There are totally 2 variants in TVS Sport bike kick start and self start.',
      id: 'collapseThree'
    },
    {
      question: 'What is the fuel economy range of TVS Sport?',
      answer: `The TVS Sport has a fuel economy range of 75 kmpl.`,
      id: 'collapseFour'
    },
    {
      question: 'What are the rival models of TVS Sport?',
      answer: 'Rivals from other brands include Hero Passion Pro, Honda Glamour and Bajaj CT 100.',
      id: 'collapseFive'
    }]

    case 'TVS-SCOOTY': return [{
      question: 'Is the seating style of TVS Scooty comfortable?',
      answer: 'The TVS Scooty has a normal size seat and is comparatively smaller than the seats of recently launched scooters.',
      id: 'collapseOne'
    },
    {
      question: 'How many colour variants are available in TVS Scooty Pep Plus?',
      answer: 'A total of 7 unique colour variants are available in TVS Scooty Pep Plus.',
      id: 'collapseTwo'
    },
    {
      question: 'What types of brakes are offered in TVS Scooty Pep Plus?',
      answer: 'The TVS Scooty Pep Plus scooter is equipped with drum brakes.',
      id: 'collapseThree'
    },
    {
      question: 'Is TVS Scooty Pep Plus scooter light in weight?',
      answer: `The TVS Scooty Pep Plus weighs about 93kg and it is very light to drive.`,
      id: 'collapseFour'
    },
    {
      question: 'What is the warranty period while buying a TVS Scooty Pep Plus scooter in CredR?',
      answer: 'Any bike or scooter bought from CredR gets a warranty period of 6 months on their engine and gearbox and also gets a 7 day buy protection plan.',
      id: 'collapseFive'
    }]

    case 'TVS-STAR-CITY': return [{
      question: 'How many variants are available in the TVS Star City model bike?',
      answer: 'There 2 variants in the TVS Star City bike model.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine capacity of TVS Star City bike?',
      answer: 'The TVS Star City bike has a 109.7cc aircooled single cylinder engine.',
      id: 'collapseTwo'
    },
    {
      question: 'What are the new updates available with TVS Star City bike?',
      answer: 'The new TVS Star City bike has new features like USB charger, Highpower LED lamps and a CBS braking system.',
      id: 'collapseThree'
    },
    {
      question: 'Can I buy a TVS Star City bike online at CredR?',
      answer: `Yes you can buy TVS Star City bike and other models of bikes and scooters online at CredR and can get it delivered directly at your doorstep.`,
      id: 'collapseFour'
    },
    {
      question: 'Which is more fuel efficient TVS Star City or TVS Sport?',
      answer: 'The TVS Star City bike has a mileage of 80 kmpl making it the most fuel efficient bike.',
      id: 'collapseFive'
    }]

    case 'TVS-WEGO': return [{
      question: 'What is the engine capacity of TVS Wego scooter?',
      answer: 'The TVS Wego scooter has a 109cc aircooled single cylinder engine.',
      id: 'collapseOne'
    },
    {
      question: 'Is TVS Wego scooter lighter to drive?',
      answer: 'The TVS Wego scooter weighs about 108 kg and is very light to drive.',
      id: 'collapseTwo'
    },
    {
      question: 'What is the special feature in TVS Wego scooter?',
      answer: 'The TVS Wego scooter has a large under the seat storage, drum brakes and special grab rail for support.',
      id: 'collapseThree'
    },
    {
      question: 'Can the TVS Wego scooter be used for long rides?',
      answer: `No, TVS Wego scooter is more suitable for daily commute.`,
      id: 'collapseFour'
    },
    {
      question: 'What is the mileage range of TVS Wego?',
      answer: 'The TVS Wego scooter has a mileage of 70 kmpl.',
      id: 'collapseFive'
    }]

    case 'TVS-APACHE': return [{
      question: '"How many variants are available in TVS Apache?',
      answer: 'There 2 variants in the TVS Apache bike model.',
      id: 'collapseOne'
    },
    {
      question: 'What is the engine capacity of TVS Apache bike?',
      answer: 'The TVS Star City bike has a 109.7cc aircooled single cylinder engine.',
      id: 'collapseTwo'
    },
    {
      question: 'What are the new updates available with TVS Apache bike?',
      answer: 'The new TVS Apache bike got a special anti locking breaking system with a special BS6 update recently.',
      id: 'collapseThree'
    },
    {
      question: 'Can I buy a TVS Star City bike online at CredR?',
      answer: `Yes you can buy TVS Star City bike and other models of bikes and scooters online at CredR and can get it delivered directly at your doorstep.`,
      id: 'collapseFour'
    },
    {
      question: 'Which is more fuel efficient TVS Apache  or TVS Sport?',
      answer: 'The TVS Sport bike has a mileage of 75 kmpl making it the more fuel efficient than TVS Apache.',
      id: 'collapseFive'
    }]

    case 'TVS-XL': return [{
      question: 'Why is TVS XL most popular in India?',
      answer: 'The TVS XL is more efficient for carrying payload and hence it is more popular among small scale merchants.',
      id: 'collapseOne'
    },
    {
      question: 'Is TVS XL scooter lighter to drive?',
      answer: 'The TVS XL scooter is slightly heavy which gives a balance for driving with heavy load.',
      id: 'collapseTwo'
    },
    {
      question: 'How many variants of TVS XL is available in the market?',
      answer: 'There are 2 variants TVS XL  available in the market.',
      id: 'collapseThree'
    },
    {
      question: 'What is the engine capacity of TVS XL?',
      answer: `The TVS XL engine scooter has a 99.7cc single cylinder engine.`,
      id: 'collapseFour'
    },
    {
      question: 'What types of brakes are available with TVS XL?',
      answer: `The TVS XL scooter has drum brakes on both variants in India.`,
      id: 'collapseFive'
    }]

    case 'MAHINDRA-CENTURO': return [{
      question: 'When was Mahindra Centuro scooter launched?',
      answer: 'The Mahindra Centuro scooter was launched in the year 2013.',
      id: 'collapseOne'
    },
    {
      question: 'Is Mahindra Centuro heavy to drive?',
      answer: 'The Mahindra Centuro scooter is a bit heavy to drive as it weighs around 111kg.',
      id: 'collapseThree'
    },
    {
      question: 'Can you drive Mahindra Centuro for longer roadtrip?',
      answer: `The Mahindra Centuro scooter is generally suited for short commute.`,
      id: 'collapseFour'
    },
    {
      question: 'How many variants are available Mahindra Centuro scooter?',
      answer: 'The Mahindra Centuro has about 5 different variants.',
      id: 'collapseFive'
    },
    {
      question: 'How efficient is Mahindra Centuro scooter?',
      answer: 'The Mahindra Centuro has a mileage level of 80 kmpl making it highly effecient.',
      id: 'collapseTwo'
    }]
    case 'USED-BIKES-IN-BANGALORE': return [{
      question: '  How many second-hand Bikes are available in Bangalore?',
      answer: `There is an assorted range of 976 second hand bikes available in Bangalore. The second hand motorcycles are from multiple brands, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra etc.`,
      id: 'collapseOne'
    },
    {
      question: '  What are the most popular brands available for second-hand Bikes in Bangalore?',
      answer: 'The most popular brands available from second hand bikes in Bangalore include Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra etc. Top variants include Royal Enfield Classic, Hero Glamor, Bajaj Avenger Street, TVS Apache 160, Honda Unicorn 160, Yamaha Fz, Suzuki Gixxer SF & TVS Sport and many more.',
      id: 'collapseTwo'
    },
    {
      question: ' Which is the best Second Hand Bike to buy in Bangalore?',
      answer: `If you’re looking for second hand bikes in Bangalore, you should look no further than the huge portfolio at CredR of used bikes. You can find family friendly, vintage, modern, sports and city bikes at CredR. Some of our previous customers' most popular choices include Royal Enfield Thunderbird 350, Suzuki Intruder, Yamaha FzS Std, Bajaj Pulsar150DTs-i, Honda Unicorn 160 and many more.
      -            Every bike you buy on CredR is the best second hand bikes in Bangalore because we care for our clients. The used bikes sold on our platform goes through a series of quality checks before being available for sale.`,
      id: 'collapseThree'
    },
    {
      question: 'Is it better to buy a Used Bike in Bangalore?',
      answer: 'Indeed. Who wouldn’t like to fulfil their dream of owning a bike to go on road trips or simply flaunt it in front of your friends! It is a great decision to buy used bikes for sale in Bangalore. You get the comfort and feel of a new bike at the price of a used one. Buying from CredR ensures that your bike has passed multiple quality checks before being listed on the website. The second hand bike you buy through CredR will always be just as new.',
      id: 'collapseFour'
    },
    {
      question: ' How to buy a second Hand Bike in Bangalore?',
      answer: 'Buying used bikes on CredR is a straightforward process. Log on to credr.com, search through our vast online portfolio of used bikes and finalize the one you like. You can find the likes of motorcycles, scooters and performance bikes. Order it through our online portal and get a doorstep delivery or simply visit our showrooms in all major Indian cities. You can take the second hand bike you like on a test drive before making the final purchase. Can it get easier than this? NOC and other documentation transfer are on us!',
      id: 'collapseFive'
    },
    {
      question: ' Should I buy a new or Used Bike in Bangalore?',
      answer: `Deciding to buy new or a second hand bikes in Bangalore is a choice dependent on various factors. Answer this question – Do you want to save a few bucks when buying a bike you always dreamt of? A second hand bike in Bangalore on CredR is as good as new. And now you know the answer! Purchasing used bikes in Bangalore is a smart choice you need to make today. Second hand bikes also come with a lesser insurance premium!
            All you have to do is search for your dream high- performance, quality bike on CredR, and we’ll take care of the rest.` ,
      id: 'collapseSix'
    },
    {
      question: ' Can I trust CredR as a place to buy a Second Hand Bike?',
      answer: 'Absolutely, without a doubt. CredR is India’s largest and the most trusted platform for used bikes. CredR doesn’t just list any second- hand bike on its platform. We pride ourselves on only bringing the best to our customers. Our second hand bikes are the best in the market at the price we offer. This is because we make sure that whichever bike, we decide to put up on sale, has undergone an extensive quality check before being sold to you. CredR takes the quality of its products very seriously. This is why 3,00,000+ customers trust us.',
      id: 'collapseSeven'
    }

    ]
    case 'USED-SCOOTERS': return [{
      question: 'Which is the best second hand scooty to buy in 2021?',
      answer: 'Talk of the second hand scooty, the options to choose from are many – Honda Activa 3G, Honda Activa 4G, TVS Jupiter, Piaggio Vespa, Hero Maestro Edge, Suzuki Access, Yamaha Ray Ray ZR are a top few. That’s not all. Filter the search results based on your preferred brands or your budget and buy one before it’s gone. All 2nd hand scooty on CredR go through more than 120 inspection checks, assuring you of the robust quality of your two wheeler. If you’re still not sure of which used scooty to buy, contact our customer support for more assistance.',
      id: 'collapseOne'
    },
    {
      question: ' Is it better to buy second hand scooty?',
      answer: 'Absolutely. It is one of the best decisions you’ll make. Not only a second hand scooty fits in your budget but also lets you enjoy the privileges of owning a high hand model. Buying a scooty pep second hand or Honda activa for less than half its showroom price is a deal nobody would want to miss. With CredR’s Gold Standard, you’re covered with a comprehensive warranty for six months, Free 7- Day Buy Protect, assured RC transfer and assistance for all paper transfer with doorstep delivery of your bike. Buying a second hand scooty was never so fun!',
      id: 'collapseTwo'
    },
    {
      question: ' What will be the starting price of a second hand scooty in India?',
      answer: 'With a plethora of choices available, the price range of a 2nd hand scooty in India starts from as low as ₹20,000. Do the math. It is less than half the showroom price of a brand- new model of your favourite scooty.',
      id: 'collapseThree'
    },
    {
      question: ' Which is better to buy second hand bike or scooty?',
      answer: `If you are confused between buying a second hand scooty or a bike, you should consider your needs for buying one. For example, think of the reasons why you’re buying a second hand two wheeler and what purpose it will be used for. Second hand bikes are better for tough city terrains or even longer rides whereas used scooty make a great vehicle for comparatively shorter rides. It also depends on the rider’s personal preference – performance bikes, sports bikes, vintage bikes, used scooty with best style and mileage and so on. Browse through various options and choose the one you like. Visit CredR’s website or get in touch with our customer support for more assistance.`,
      id: 'collapseFour'
    },
    {
      question: '  Should I buy a used or a new scooty?',
      answer: 'If you want to own a higher end model of your favourite scooty at incredible prices, buying a 2nd hand scooty is your best bet. Every used scooty on CredR undergo levels of quality check before being available for resale. Since a used scooty has travelled a few kilometres, the insurance premium to be paid is lower, saving you extra cash on maintenance expenses of your scooty. Who wouldn’t want a deal like that? Simply log on to CredR, browse, book and get your used scooty delivered home! You can also visit your nearest CredR showroom in your city to take a test- drive before you buy.',
      id: 'collapseFive'
    },
    {
      question: '    How do I buy a used scooter in india?',
      answer: 'CredR, India’s largest and most trusted consumer brand for used two wheelers is at your service. When you want to buy a used scooter, anywhere in India, all you have to do is to go through hundreds of options listed on the CredR website, and book it for yourself. CredR brings you two wheelers at affordable prices from the best brands – Honda, Yamaha, Hero, Mahindra, TVS, Royal Enfield, and the list is endless. If you’re the one who prefers to pick your used scooty or bike from the showroom, search for the nearest CredR showroom in your city on our website and give it a go. With 30+ showrooms in 8+ cities in India, we’re expanding to other cities too.',
      id: 'collapseSix'
    }
    ]
    case 'USED-SCOOTERS-IN-BANGALORE': return [{
      question: ' Which scooter is best for second hand in Bangalore?',
      answer: 'If you’re looking to buy second hand scooters, visit CredR and scroll through hundreds of options that are available. You can find used scooters in Bangalore from brands like Honda, Bajaj, Hero and so many more. You can either pick your favourite second hand scooter online or get in touch with our experts to suggest one based on your preferences. The second hand scooters that are the best buy are in perfect condition (because we do stringent quality checks) and come with no hassles at low premium prices. Your options are not limited, but your wallet is definitely saved. A choicest few second hand scooters are – Honda Activa 5G, Honda Maestro Edge, Yamaha Ray Ray ZR and many more.',
      id: 'collapseOne'
    },
    {
      question: 'Is it good to buy a used scooter in Bangalore?',
      answer: 'When India’s largest and most trusted brand for buying and selling used scooters is selling one – we bet it is a decision you will never regret. Founded in 2014, CredR has been growing immensely because every customer is the king. All second hand scooters pass necessary quality checks and are advertised at economical prices, fitting in every budget. At CredR, we take pride in making the entire process of buying your favourite second hand scooters online flexible and fun!',
      id: 'collapseTwo'
    },
    {
      question: ' Which is the lowest price used scooters in Bangalore?',
      answer: 'Honda Activa Std, TVS Scooty Streak Std are some of second hand scooters available for less than ₹25,000, only on CredR. Not just these, Honda Navi Std, Honda Activa Dlx, among others, from top- notch brands, including Bajaj, Aprilia, TVS, are available for super low prices. Get the best in class used scooters in Bangalore and go easy on your pockets while getting full benefits of a new scooter. CredR has a scooter for every budget. Just log in, browse and book!',
      id: 'collapseThree'
    },
    {
      question: '  Which is the best second Hand scooter to buy in Bangalore?',
      answer: 'The best second hand scooter to buy in Bangalore is one that passes all quality checks at CredR and suits your individual needs. Be it buying a scooter for female members of your family or yourself, CredR hosts you a huge online portfolio of used scooters in Bangalore that you can pick and choose from. Whether you wish you widen your portfolio of passionate scooters or need to gift one from your first-ever salary, there is a scooter for everyone. Already have a second hand scooter you wish to buy on your mind? Wait no further, and browse now.',
      id: 'collapseFour'
    },
    {
      question: ' Which used scooter is best to buy in 2021 at Bangalore?',
      answer: 'The answer is simple - the ones you buy from CredR. Don’t trust us, but trust more than 300,000+ customers across the country’s length and breadth who have bought used scooters from CredR. The best part of buying from CredR is that all of our used scooters undergo extensive quality checks before they are sold. So, you can be sure that you are getting amazing second hand scooters at affordable prices. There is a range of options to choose from. Filter using your budget criteria or simply your preferred brand, and order it for doorstep delivery. You may also want to visit a CredR showroom nearest to your place, take a test-drive and take it home! ',
      id: 'collapseFive'
    }
    ]
    case 'USED_BIKES_DELHI': return [{
      question: 'What should I check before buying a second hand bike in Delhi?',
      answer:
        <>
          <div className="row">
            <div className="col-12">
              <p>Booking a used bike/ used scooter at CredR is easy and convenient.</p>
            </div>
            <ul>
              <li>Find a purpose to buy a used bike</li>
              <li>Visit CredR website</li>
              <li>Kindly choose from our assorted collection</li>
              <li>Choose a city, brand, model & variant</li>
              <li>Go for a test ride</li>
              <li>Ensure all documentation</li>
              <li>Enjoy additional benefits</li>
            </ul>
          </div>
        </>,
      id: 'collapseOne'
    },
    {
      question: 'Can I Buy Used Bikes Online In Delhi?',
      answer: 'Booking a used bike at CredR is made easy and feasible for all. The swift online service of buying a used bike/used scooter from CredR is fast. We offer contactless service at your doorstep.',
      id: 'collapseTwo'
    },
    {
      question: ' Can I exchange my used bikes at CredR in Delhi?',
      answer: 'Of course, you can. Exchanging bikes at CredR from your preferred locations is possible. You will enjoy swift service, doorstep service, 100% genuine and quick selling of your motorcycle; the paper transfer is done with ease. Enjoy a great deal and innumerable benefits.',
      id: 'collapseThree'
    },
    {
      question: 'Is it good to buy second hand Bikes in Delhi?',
      answer: 'Investing in a used bike from CredR in Delhi is extremely beneficial. At CredR, the bikes are chosen based on 120+ parameters of inspection by an auto expert. The used motorcycles are refurbished to maintain good quality. It is undoubtedly a great investment to buy a used bike.',
      id: 'collapseFour'
    },
    {
      question: 'How can I book a used bike on CredR?',
      answer: 'To book a used bike, you need to submit your details like name, mobile number and address. It will further take you to the option of a test drive from your doorstep at ₹399. You can also choose to go to the Delhi showroom to buy your favorite two-wheeler.',
      id: 'collapseFive'
    }
    ]
    case 'ALL-USED-BIKES-BANG': return [{
      question: 'Why should I choose CredR for used bikes?',
      answer: 'CredR Your Trusted Used Two-Wheeler Brand makes buying, selling, and exchanging bikes easy and convenient. CredR is unique as they sell refurbished bikes at an affordable range. They offer transparent and genuine customer-friendly service to ensure your desires related to bikes are fulfilled. Browse through the assorted collection of two-wheelers to buy a used bike, book, and test drive at ₹399 and enjoy innumerable benefits at one go.',
      id: 'collapseOne'
    },
    {
      question: 'Is it a good idea to buy refurbished bikes?',
      answer: 'Of course, refurbished bikes make the old bike look new. At CredR, the bikes once collected from a seller are checked on 120+ parameters by an auto expert. The damaged parts are repaired and refurbished to give it a unique look. You’ll not even realize that this is a showroom of used bikes. We take the 100% guarantee on quality bike sales.',
      id: 'collapseTwo'
    },
    {
      question: 'How much I’ll be benefitted buying a used bike from CredR?',
      answer:
        <>
          <div className="row">
            <div className="col-12">
              <p>Buying a used bike from CredR will benefit you in many ways.</p>
            </div>
            <ul>
              <li>Easy and convenient service</li>
              <li>Swift booking for two-wheeler</li>
              <li>Test drive at ₹399 only</li>
              <li>Doorstep service</li>
              <li>Hassle-free documentations</li>
              <li>Affordable price</li>
              <li>6 months warranty</li>
              <li>7 days buy protect</li>
            </ul>
          </div>
        </>
    },
    {
      question: 'Can I buy a second Hand bike on EMI in Bangalore?',
      answer: 'Of course, with CredR, you can buy a second hand bike on EMI in Bangalore. Each used two-wheeler has a diverse set of EMI plans. Our executives will guide you once you submit all the details required to buy a used bike.',
      id: 'collapseThree'
    },
    {
      question: 'Can I get a refund if I choose not to buy a used bike?',
      answer: 'Once you have submitted the details on CredR buy page, you’ll be guided for a test ride at ₹399 only. You can choose the bike and decide to pay us. There is a benefit of 7 days buy protection in which you will get any unforeseen bike-related issue resolved free of cost. Ride it out worry-free on your CredR bike. ',
      id: 'collapseFour'
    }
    ]

    case 'ALL_USED_BIKES_JAIPUR': return [
      {
        question: 'Is it a good idea to buy used bikes in Jaipur?',
        answer:
          <>
            <div className="row">
              <div className="col-12">
                <p>To book a used bike in Jaipur from CredR is a good investment. You’ll get innumerable benefits. The bikes are all refurbished for quality purposes. The brand ensures the below points;</p>
              </div>
              <ul>
                <li>Swift & transparent service</li>
                <li>Refurbished bikes at an affordable price</li>
                <li>120+ level of inspection by experienced auto experts</li>
                <li>6 months of warranty</li>
                <li>7-day buy product</li>
                <li>No legal hassle</li>
              </ul>
            </div>
          </>,
        id: 'collapseOne'
      },
      {
        question: 'What will I check before buying Pre-owned bikes in Jaipur?',
        answer:
          <>
            <div className="row">
              <div className="col-12">
                <p>Investing in pre-owned bikes in Jaipur is a good decision. If you are dealing with CredR, the entire process of buying/selling/exchanging will be smooth and swift. Make sure you keep a check on few points;</p>
              </div>
              <ul>
                <li>Shortlist your preferable brand</li>
                <li>Set the budget</li>
                <li>Go for a test ride</li>
                <li>Check the mileage of the bike to date</li>
                <li>Check in the motor noise, brakes, and clutches.</li>
              </ul>
              <div className="col-12">
                <p>If these points seem alright, then you can go ahead and buy a two-wheeler. Our dedicated executives will help you to go through the process as quickly as possible.</p>
              </div>
            </div>
          </>,
        id: 'collapseTwo'
      },
      {
        question: 'What are the most popular brands available for used bikes in Jaipur?',
        answer: 'There are numerous top-selling brand models available at CredR. It includes the TVS, Suzuki, Yamaha, Hero, Honda, Bajaj, KTM, Kawasaki, Royal Enfield, Piaggio, and many more.',
        id: 'collapseThree'
      },
      {
        question: ' How can I exchange a used bike?',
        answer: 'At CredR, buying a used bike, selling your old bike, and exchanging used bikes are possible. It will just take a few minutes to process. Now for safety purposes, self-inspection is easily accessible. Based on the videos and bike images, you’ll get an excellent price for your old bike. You can either book a new bike or can opt for buying a used bike from CredR.',
        id: 'collapseFour'
      },
      {
        question: 'How many pre-owned used bikes are available in Jaipur?',
        answer: 'Among all, 1000+ used bikes are accessible from the CredR website. Browse through the online website to choose one of your favorite two-wheelers & avail the hosts of benefits.',
        id: 'collapseFive'
      }
    ]

    case 'USED_SCOOTERS_JAIPUR': return [
      {
        question: 'Which is the best place for buying used two wheeler in Jaipur?',
        answer: 'Among all the top places to buy and sell second hand scooty in Jaipur, CredR is the best among all, offering end-to-end solutions. You can now buy a used two-wheeler, sell used bikes, exchange your bike to CredR and enjoy innumerable benefits.',
        id: 'collapseOne'
      },
      {
        question: 'Where will I get second hand scooters in Jaipur ?',
        answer: 'Browse through the assorted collection of used second hand scooters in Jaipur to book your favorite bike. Brands like Yamaha, Bajaj, Suzuki, TVS, Honda, Piaggo and many more. Book your favorite second hand scooter in Jaipur and explore an amazing riding experience.',
        id: 'collapseTwo'
      },
      {
        question: 'How useful is it to buy refurbished used Scooters ?',
        answer: `Buying a normal second hand scooter and a refurbished old scooty in Jaipur has many differences. CredR's refurbished scooters will give a polished outlook like a new scooter. You don't need to spend money to fix the outlook of the two-wheeler. Purchasing an old scooty in Jaipur from CredR will benefit you in a variety of ways.`,
        id: 'collapseThree'
      },
      {
        question: 'What is the price range to buy used scooters in jaipur?',
        answer: 'The price range for the 2nd hand scooty in Jaipur starts from Rs. 20,000 onwards. Check on the brand price, model, year, colour, and specifications before booking a two-wheeler from CredR. Explore the additional host of benefits and contactless service only at CredR. ',
        id: 'collapseFour'
      },
      {
        question: 'What are the second hand motorcycles in Jaipur ? ',
        answer: 'There is a varied assorted collection of top-notch brands available in Jaipur at the CredR showroom. Brands like Yamaha, Bajaj, Suzuki, TVS, Honda, Piaggo, Mahindra, KTM, Royal Enfield, Hero Honda, and many more. You now can buy/sell used bikes from CredR and explore the handful of benefits in one go.',
        id: 'collapseFive'
      },
      {
        question: 'Can I Buy Used Scooters Online at CredR in Jaipur?',
        answer: 'Yes, CredR can help you buy a used scooty in Jaipur. The process is swift and contactless. You can choose from a wide variety of used bike collections, including Yamaha, Bajaj, Suzuki, TVS, Honda, Piaggo, Mahindra, KTM, Royal Enfield, Hero Honda, and many more. The second hand motorcycles are all refurbished to offer you a premium quality bike. You may now book your favorite bike from the convenience of your own home.',
        id: 'collapseSix'
      }
    ]

    case 'USED_BIKES_JAIPUR_HERO': return [
      {
        question: 'Can I buy used Hero online at CredR in Jaipur?',
        answer: 'Yes, you can buy a used Hero bike online at CredR in Jaipur. The second hand Hero bikes in Jaipur are accessible at an affordable price. All the used bikes are refurbished for quality purposes. ',
        id: 'collapseOne'
      },
      {
        question: 'how many second hand Hero Bikes are available in Jaipur?',
        answer: 'Browse through the assorted collection of second hand Hero bikes in Jaipur accessible at CredR. Visit the website and book your favourite Hero bike today! ',
        id: 'collapseTwo'
      },
      {
        question: 'What will be the starting price of an old Hero Bike in Jaipur?',
        answer: 'The price of the second hand Hero bikes in Jaipur at CredR starts from Rs.30,000 onwards. Check out the bikes based on style, model, colour, variation and specifications. Get Hero bikes at an affordable price with additional benefits to explore. ',
        id: 'collapseThree'
      },
      {
        question: 'How can I check a second hand Hero Bike in Jaipur?',
        answer: 'Checking for quality second hand Hero bikes in Jaipur is easy. If you browse through the CredR website, you will find top-class Hero bikes priced reasonably and showcased with their specifications, making it an excellent buy for the bikers. Explore the collection and book a used Hero bike to enjoy added benefits. ',
        id: 'collapseFour'
      },
      {
        question: 'Where can I buy used Hero Bike in Jaipur? ',
        answer: 'CredR is the best option to buy second hand Hero bikes in Jaipur among all the top platforms. You will get innumerable benefits for purchasing a quality refurbished bike from CredR. ',
        id: 'collapseFive'
      }
    ]
    case 'USED-BIKES-IN-MUMBAI': return [
      {
        question: 'How many are second hand bikes available in Mumbai?',
        answer: 'There are 100+ second hand bikes that are available in Mumbai.',
        id: 'collapseOne'
      },
      {
        question: 'What are the most popular brands available for second hand bikes in Mumbai?',
        answer: 'The popular brands are Hero MotoCorp, TVS, Bajaj, KTM, Royal Enfield, Harley Davidson, Piaggio, Triumph, UM Motorcycles, Honda, Jawa, Kawasaki, Husqvarna, Aprilia, Hyosung, Kinetic, Mahindra, and Moto Morini. ',
        id: 'collapseTwo'
      },
      {
        question: 'Is it better to buy a used bike in Mumbai?',
        answer: 'Yes, daily commuting is an ideal way to combat traffic congestion.',
        id: 'collapseThree'
      },
      {
        question: 'How to buy a second hand bike in Mumbai?',
        answer: 'Buying a second hand bike in Mumbai is made easy and quick. By visiting the online website or downloading the CredR app, you can explore the extensive range of second hand bikes listed in order. By choosing the location and price, you’ll be able to narrow down the buying options for the used wheelers. You can check out the list of the available bikes and get your choice of bike. It is necessary to fill in the details accurately. Take a test ride from home at Rs.399 only. Further, you can decide on the bike.',
        id: 'collapseFour'
      },
      {
        question: 'Should I buy a new or used bike in Mumbai?',
        answer: 'It is always a great idea to invest in a used bike in Mumbai if you are a beginner, want a bike for daily commuting or are new to the city. ',
        id: 'collapseFive'
      },
      {
        question: 'Can I trust CredR as a place to buy a second hand bike?',
        answer: 'CredR- Your Trusted Two-Wheeler Brand is the one-stop solution for buying, selling and servicing bikes. You can place your trust in CredR, seeing their dedication to make the customers life more comfortable with the best offerings in terms of two-wheeler related demands.',
        id: 'collapseSix'
      }
    ]
   
    default: return null
  }
}

export function getFaq(location) {
  return getFaqHTMLString(location)
}