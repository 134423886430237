import React, { Component, Fragment } from 'react'
import Header from '../../components/header'
import Footer from '../../components/Footer'
import Loader from '../../components/Partials/FullPageLoader'
import TriggeringOnclick from '../../components/TriggeringApp/index'
import { withRouter } from 'react-router-dom'
import { getStore } from '../../app/store'
import { setShow } from '../../redux/actions/showAppLinkAction'
import { connect } from 'react-redux'

class Layout extends Component {

  state = {
    show: true,
    a: true,
    showHeaderLayot:true
  }

  componentDidMount() {
    const { location } = this.props
    // if (location.search === '?care=yes') {
    //   this.setAppRetriction()
    // }
    this.props.dispatch(setShow(true))
    setTimeout(() => {
      this.setState({ show: false })
      this.props.dispatch(setShow(false))
    }, 60000)
    window.scrollTo(0, 0)
    this.checkLink()

  }

  // setAppRetriction = () => {
  //   this.setState({ a: false })
  // }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (this.props.location) {
      if (prevProps.location.search !== '?care=yes') {
        if ((this.props.location.pathname === '/door-step-service' ||
          this.props.location.pathname === '/enhanced-sell-user-details' ||
          this.props.location.pathname === '/sellbikes/thank-you' ||
          this.props.location.pathname === '/service-userDetails' ||
          this.props.location.pathname === '/door-step-service-landing' ||
          this.props.location.pathname === '/newcontactless-service/thank-you' ||
          this.props.location.pathname === '/enhanced-sellbikes/thank-you' ||
          this.props.location.pathname === '/sellbikes' ||
          this.props.location.pathname === '/new-bikes' ||
          this.props.location.pathname === '/ad-sell-page' ||
          this.props.location.pathname === '/ad-demand-leads' ||
          this.props.location.pathname === '/student-ambassador' ||
          this.props.location.pathname === '/v' ||
          this.props.location.pathname === '/ins' ||
          this.props.location.pathname === '/ad-sell-page/thank-you' ||
          this.props.location.pathname === '/s') &&
          this.props.location.pathname !== prevProps.location.pathname) {

          if (this.props.location.pathname === '/student-ambassador' || this.props.location.pathname === '/v' || this.props.location.pathname === '/ad-sell-page') {
            this.setState({ show: false, showHeaderLayot: false })
            this.props.dispatch(setShow(false))
          } else {
            this.setState({ show: false, showHeaderLayot: true })
            this.props.dispatch(setShow(false))
          }
        }
        else if (this.props.location.pathname !== prevProps.location.pathname) {
          this.setState({ show: true, showHeaderLayot: true })
          this.props.dispatch(setShow(true))
          setTimeout(() => {
            this.setState({ show: false })
            this.props.dispatch(setShow(false))
          }, 60000)
        }
        //window.scrollTo(0, 0)
      }
    }

  }

  closeTrigger = () => {
    this.setState({ show: false })
    const store = getStore()
    const promises = []
    this.props.dispatch(setShow(false))
  }
  checkLink = () => {
    const { location } = this.props
    if (location.pathname === '/sell_bike_fb_bangalore' ||
      location.pathname === '/meet-our-ceo' ||
      location.pathname === '/sell_bike_fb_pune' ||
      location.pathname === '/sell_bike_fb_mumbai' ||
      location.pathname === '/sell_bike_fb_nashik' ||
      location.pathname === '/sell_bike_fb_delhi' ||
      location.pathname === '/sell_bike_fb_ahmedabad' ||
      location.pathname === '/sell_bike_fb_jaipur' ||
      location.pathname === '/sell-landing-page-for-ads-delhi' ||
      location.pathname === '/sell-landing-page-for-ads-bangalore' ||
      location.pathname === '/sell-landing-page-for-ads-nashik' ||
      location.pathname === '/sell-landing-page-for-ads-pune' ||
      location.pathname === '/sell-landing-page-for-ads-mumbai' ||
      location.pathname === '/sell-landing-page-for-ads-ahmedabad' ||
      location.pathname !== '/v1/sell_bike_fb_pune' ||
      location.pathname !== '/v1/sell_bike_fb_mumbai' ||
      location.pathname !== '/v1/sell_bike_fb_nashik' ||
      location.pathname !== '/v1/sell_bike_fb_delhi' ||
      location.pathname !== '/v1/sell_bike_fb_ahmedabad' ||
      location.pathname !== '/v1/sell_bike_fb_jaipur' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-delhi' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-bangalore' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-nashik' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-pune' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-mumbai' ||
      location.pathname !== '/v1/sell-landing-page-for-ads-ahmedabad' ||
      location.pathname === '/door-step-service' ||
      location.pathname === '/service-userDetails' ||
      location.pathname === '/newcontactless-service/thank-you' ||
      location.pathname === '/enhanced-sell-user-details' ||
      location.pathname === '/sellbikes/thank-you' ||
      location.pathname === '/door-step-service-landing' ||
      location.pathname === '/enhanced-sellbikes/thank-you' ||
      location.pathname === '/sellbikes' ||
      location.pathname === '/new-bikes' ||
      location.pathname === '/ad-sell-page' ||
      location.pathname === '/ad-sell-page/thank-you' ||
      location.pathname === '/ad-demand-leads' ||
      location.pathname === '/student-ambassador' ||
      location.pathname === '/v' ||
      location.pathname === '/ins' ||
      location.pathname === '/s'
    ) {
      this.setState({ show: false })
      this.props.dispatch(setShow(false))
    }
  }
  getUrl = () => {
    const { location } = this.props
    if (location.pathname === '/buy-landing-page-for-zest-bikes') {
      return false
    }
    if (location.search === '?care=yes') {
      return false
    }
    if (location.search === '/meet-our-ceo') {
      return false
    }
    if (location.search === '/door-step-service') {
      return false
    }
    if (location.search === '/service-userDetails') {
      return false
    }
    if (location.search === '/newcontactless-service/thank-you') {
      return false
    }
    if (location.search === '/enhanced-sell-user-details') {
      return false
    }
    if (location.search === '/sellbikes/thank-you') {
      return false
    }
    if (location.pathname === '/door-step-service-landing') {
      return false
    }
    if (location.search === '/enhanced-sellbikes/thank-you') {
      return false
    }
    if (location.search === '/sellbikes') {
      return false
    }
    if (location.pathname === '/new-bikes') {
      return false
    }
    if (location.pathname === '/ad-sell-page') {
      return false
    }
    if (location.pathname === '/ad-demand-leads') {
      return false
    }
    if (location.pathname === '/student-ambassador') {
      // this.setState({ showHeaderLayot: false })
      return false
    }
    if (location.pathname === '/v') {
      return false
    }
    if (location.pathname === '/ins') {
      return false
    }
    if (location.pathname === '/s') {
      return false
    }
    else return true
  }

  render() {
    const { show, a, showHeaderLayot } = this.state
    const { device, location } = this.props
    return (
      <Fragment>
        <Loader />
        {/* {(
          location.pathname !== '/sell_bike_fb_bangalore' ||
          location.pathname !== '/meet-our-ceo' ||
          location.pathname !== '/sell_bike_fb_pune' ||
          location.pathname !== '/sell_bike_fb_mumbai' ||
          location.pathname !== '/sell_bike_fb_nashik' ||
          location.pathname !== '/sell_bike_fb_delhi' ||
          location.pathname !== '/sell_bike_fb_ahmedabad' ||
          location.pathname !== '/sell_bike_fb_jaipur' ||
          location.pathname !== '/sell-landing-page-for-ads-delhi' ||
          location.pathname !== '/sell-landing-page-for-ads-bangalore' ||
          location.pathname !== '/sell-landing-page-for-ads-nashik' ||
          location.pathname !== '/sell-landing-page-for-ads-pune' ||
          location.pathname !== '/sell-landing-page-for-ads-mumbai' ||
          location.pathname !== '/sell-landing-page-for-ads-ahmedabad' ||
          location.pathname !== '/v1/sell_bike_fb_bangalore' ||
          location.pathname !== '/v1/sell_bike_fb_pune' ||
          location.pathname !== '/v1/sell_bike_fb_mumbai' ||
          location.pathname !== '/v1/sell_bike_fb_nashik' ||
          location.pathname !== '/v1/sell_bike_fb_delhi' ||
          location.pathname !== '/v1/sell_bike_fb_ahmedabad' ||
          location.pathname !== '/v1/sell_bike_fb_jaipur' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-delhi' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-bangalore' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-nashik' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-pune' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-mumbai' ||
          location.pathname !== '/v1/sell-landing-page-for-ads-ahmedabad' ||
          location.pathname !== '/door-step-service' ||
          location.pathname !== '/service-userDetails' ||
          location.pathname !== '/newcontactless-service/thank-you' ||
          location.pathname !== '/enhanced-sell-user-details' ||
          location.pathname !== '/sellbikes/thank-you' ||
          location.pathname !== '/door-step-service-landing' ||
          location.pathname !== '/enhanced-sellbikes/thank-you' ||
          location.pathname !== '/sellbikes' ||
          location.pathname !== '/new-bikes' ||
          location.pathname !== '/ad-sell-page' ||
          location.pathname !== '/ad-sell-page/thank-you' ||
          location.pathname !== '/ad-demand-leads' ||
          location.pathname !== '/student-ambassador' ||
          location.pathname !== '/v' ||
          location.pathname !== '/ins' ||
          location.pathname !== '/s') &&
          a && this.getUrl() &&
          show && device === 'phone' ?
          <TriggeringOnclick
            onClose={this.closeTrigger}
          /> : <></>

        } */}
        <Header show={show} showHeaderLayot={showHeaderLayot} />
        <div className={this.getUrl() ? device === 'phone' && show === true ? "wrapper selected" : 'wrapper' : 'wrapper selected'}>
          {this.props.children}
        </div>
        <Footer />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  device: state.deviceDetail.device
})

export default withRouter(connect(mapStateToProps)(Layout));