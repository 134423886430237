import http from 'axios'
import { handleError, handleResponse } from '../utility'
import { API_ENDPOINTS } from '../constants/apiEndpoints'

const API_ENDPOINT_BUDGET_BIKES = API_ENDPOINTS.BIKES.GET_BUDGET_BIKE_LIST
const API_ENDPOINT_POPULAR_BIKES = API_ENDPOINTS.BIKES.GET_POPULAR_BIKE_LIST
const API_ENDPOINT_ALL_MAKES = API_ENDPOINTS.BIKES.GET_ALL_MAKES
const API_ENDPOINT_GET_BIKE = API_ENDPOINTS.BIKES.GET_BIKE
const API_ENDPOINT_GET_INSPECTION_DETAILS = API_ENDPOINTS.BIKES.GET_INSPECTION_DETAILS
const API_ENDPOINT_GET_SIMILAR_BIKES = API_ENDPOINTS.BIKES.GET_SIMILAR_BIKES
const API_ENDPOINT_FILTERED_BIKES = API_ENDPOINTS.BIKES.GET_FILTERED_BIKES
const API_ENDPOINT_ALL_MANUFACTURERS = API_ENDPOINTS.BIKES.GET_MANUFACTURERS
const API_ENDPOINT_ALL_MODELS = API_ENDPOINTS.BIKES.GET_MODELS
const API_ENDPOINT_ALL_VARIANTS = API_ENDPOINTS.BIKES.GET_VARIANTS
const API_ENDPOINT_SEARCH_BIKES = API_ENDPOINTS.BIKES.SEARCH_BIKES_EL
const API_ENDPOINT_SELL_BIKE_SEARCH = API_ENDPOINTS.BIKES.SELL_BIKE_SEARCH
const API_ENDPOINT_TOP_SELLING_BIKES = API_ENDPOINTS.BIKES.GET_POPULAR_BIKE_LIST
const API_ENDPOINT_BIKES_BY_CITY = API_ENDPOINTS.BIKES.SEARCH_BIKE_BY_CITY
const API_ENDPOINT_BRAND_LIST_ID = API_ENDPOINTS.BIKES.GET_EXCHANGE_BIKE_BRAND_LIST
const API_ENDPOINT_ALL_CITYLIST = API_ENDPOINTS.BIKES.GET_CITY_LIST
const API_ENDPOINT_ALL_MODEL_LIST = API_ENDPOINTS.BIKES.GET_EXCHANGE_BIKE_MODEL_LIST
const API_ENDPOINT_BIKE_INFO = API_ENDPOINTS.BIKES.GET_BIKE_INFO
const API_ENDPOINT_STORE_LIST = API_ENDPOINTS.BIKES.GET_STORE_LIST_EXCHANGE
const API_ENDPOINT_UPDATE_STORE = API_ENDPOINTS.BIKES.UPDATE_STORE_LIST
const API_ENDPOINT_EXCHANGE_TO_SELL = API_ENDPOINTS.BIKES.GET_EXCHANGE_TO_SELL
const API_ENDPOINT_GET_MFG_YEARS = API_ENDPOINTS.BIKES.GET_MFG_YEARS
const API_ENDPOINT_GET_RTO = API_ENDPOINTS.BIKES.GET_RTO
const API_ENDPOINT_GET_LEAD_INSPECTION_SLOTS = API_ENDPOINTS.BIKES.GET_LEAD_INSPECTION_SLOTS
const API_ENDPOINT_GET_RTO_PROCURABLE = API_ENDPOINTS.BIKES.GET_RTO_PROCURABLE
const API_ENDPOINT_SUBMIT_RTO_CODE = API_ENDPOINTS.BIKES.SUBMIT_RTO_CODE
const API_ENDPOINT_GENERATE_OTP = API_ENDPOINTS.BIKES.GENERATE_BUY_OTP
const API_ENDPOINT_VERIFY_OTP = API_ENDPOINTS.BIKES.VERIFY_BUY_OTP

const API_ENDPOINT_ACTIVE_CITIES_EXCHANGE = API_ENDPOINTS.BIKES.GET_ACTIVE_CITIES_EXCHANGE
const API_ENDPOINT_ACTIVE_CITIES_BUY = API_ENDPOINTS.BIKES.GET_ACTIVE_CITIES_BUY
const API_ENDPOINT_MODEL_VARIANTS_LIST_EXCHANGE = API_ENDPOINTS.BIKES.GET_MODEL_VARIANTS_LIST_EXCHANGE


function getBudgetBike(budget = {}) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_BUDGET_BIKES, budget,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function updateStoreList(payload = {}) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_UPDATE_STORE, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function exchangeToSell(payload = {}) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_EXCHANGE_TO_SELL, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getExchangeBikeInfo(payload = {}) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_BIKE_INFO, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}


function getPopularBikes(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_POPULAR_BIKES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllMake() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_ALL_MAKES, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getBike(bikeId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_BIKE.replace('<BIKE_ID>', bikeId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getStoreList(leadId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_STORE_LIST.replace('<LEAD_ID>', leadId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getBikeInspectionDetails(bikeId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_INSPECTION_DETAILS.replace('<BIKE_ID>', bikeId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getSimilarBikes(similarData = {}) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_GET_SIMILAR_BIKES, similarData, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getBikesByFilter(filter = { pageNumber: 1 }) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_FILTERED_BIKES, filter, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllManufactures() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_ALL_MANUFACTURERS, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllModel(bmId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_ALL_MODELS.replace('<BM_ID>', bmId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllVariant(bikeModelId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_ALL_VARIANTS.replace('<MODEL_ID>', bikeModelId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function searchBikes(searchKeys, cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  let url = API_ENDPOINT_SEARCH_BIKES.replace('<SEARCH>', searchKeys)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function sellBikeSearch(searchKeys) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_SELL_BIKE_SEARCH.replace('<SEARCH>', searchKeys)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function sellBikeSearchByCity(cityId, mmv) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  let url = API_ENDPOINT_BIKES_BY_CITY.replace('<CITY_ID>', cityId)
  url = url.replace('<MMV>', mmv)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getTopSellingBikes(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_TOP_SELLING_BIKES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllBrandByCity(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_BRAND_LIST_ID.replace('<CITY_ID>', cityId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getAllModelsByCity(makeId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_ALL_MODEL_LIST.replace('<MAKE_ID>', makeId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getExchangeCityList() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_ALL_CITYLIST, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getMfgYears(variantId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_MFG_YEARS.replace('<VARIANT_ID>', variantId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getRTOCodeByCity(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_RTO.replace('<CITY_ID>', cityId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getLeadInspectionSlot(leadId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_LEAD_INSPECTION_SLOTS.replace('<LEAD_ID>', leadId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function getRTOCodeWithProcurable(cityId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_RTO_PROCURABLE.replace('<CITY_ID>', cityId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}

function submitRTOCode(payload={}) {
  const headers = {}
  if(typeof window!== 'undefined') {
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_SUBMIT_RTO_CODE, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function generateBuyOtp(payload={}) {
  const headers = {}
  if(typeof window!== 'undefined') {
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_GENERATE_OTP, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}


function verifyBuyOtp(payload={}) {
  const headers = {}
  if(typeof window!== 'undefined') {
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_VERIFY_OTP, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getActiveCitiesExchange() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_ACTIVE_CITIES_EXCHANGE, {headers} )
    .then(handleResponse)
    .catch(handleError)
}
function getActiveCitiesBuy() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_ACTIVE_CITIES_BUY, {headers} )
    .then(handleResponse)
    .catch(handleError)
}


function getModelsVairantsExchange(makeId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_MODEL_VARIANTS_LIST_EXCHANGE.replace('<MAKE_ID>', makeId)
  return http.get(url, {headers} )
    .then(handleResponse)
    .catch(handleError)
}


export {
  getBudgetBike,
  getPopularBikes,
  getAllMake,
  getBike,
  getBikeInspectionDetails,
  getSimilarBikes,
  getBikesByFilter,
  getAllManufactures,
  getAllModel,
  getAllVariant,
  searchBikes,
  sellBikeSearch,
  getTopSellingBikes,
  sellBikeSearchByCity,
  getAllBrandByCity,
  getExchangeCityList,
  getAllModelsByCity,
  getExchangeBikeInfo,
  getStoreList,
  updateStoreList,
  exchangeToSell,
  getMfgYears,
  getRTOCodeByCity,
  submitRTOCode,
  generateBuyOtp,
  verifyBuyOtp,
  getRTOCodeWithProcurable,

  getActiveCitiesExchange,
  getModelsVairantsExchange,
  getActiveCitiesBuy,
  getLeadInspectionSlot,
}

