import { getRecentlyBoughtBikes } from '../../core/services/storePageServices'
import { RECENTLY_BOUGHT_BIKES_ACTION } from '../../core/constants/actions'

export function getRecentlyBikes(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getRecentlyBoughtBikes(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const bikeList = apiResponse.recentlyPurchasedVehicles
          dispatch(success({
            bikeList
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: RECENTLY_BOUGHT_BIKES_ACTION.REQUEST } }
  function success(bikeList) { return { type: RECENTLY_BOUGHT_BIKES_ACTION.SUCCESS, payload: { ...bikeList } } }
  function failure(error) { return { type: RECENTLY_BOUGHT_BIKES_ACTION.FAILURE, payload: { ...error } } }
}