import React from 'react'
import PageLoader from 'react-loader-spinner'

const Loader = ({ style }) => (
  <div className="pageLoader" style={style}>
    <PageLoader
      type="CradleLoader"
      height="1000"
      width="1000"
    />
  </div>
)

export default Loader