import { getBikeListPayload, getCity, setBikeListPayload } from '../services/storageService'
import { parseString, getParsedUrlParameter } from './'
import query from 'query-string'

export function getPayload(params, search = '') {
  let payload = {}
  if (getBikeListPayload()) {
    payload = { ...payload, ...getBikeListPayload() }
  }
  if ('registrationNumber' in payload) {
    const { registrationNumber, ...rest } = getBikeListPayload()
    setBikeListPayload({ ...rest })
  }
  if (params && Object.keys(params).length) {
    if ('vehicle' in params) {
      payload = { ...payload, vehicleType: params.vehicle.slice(0, -1) }
    }
    if ('make' in params) {
      payload = { ...payload, makeList: [parseString(params.make)] }
    }
    if ('area' in params) {
      payload = { ...payload, areaName: parseString(params.area) }
    }
    if ('city' in params) {
      payload = { ...payload, cityName: parseString(params.city) }
    }
    if ('model' in params) {
      payload = { ...payload, modelName: parseString(params.model) }
    }
    if ('variant' in params) {
      payload = { ...payload, variantName: parseString(params.variant) }
    }
  }

  if (search) {
    const queryParams = query.parse(search)
    if ('f' in queryParams) {
      const filter = getParsedUrlParameter(queryParams.f)
      if (filter) {
        payload = { ...payload, ...filter }
      }
    }
    if ('sort' in queryParams) {
      if (queryParams.sort.split('-').length > 1) {
        const [type, sortType] = queryParams.sort.split('-')
        payload = { ...payload, type, sortType }
      }
    }
    if ('page' in queryParams) {
      const getPageNumber = Number(queryParams.page)
      if (!isNaN(getPageNumber)) {
        payload = { ...payload, pageNumber: Math.abs(getPageNumber) }
      }
    }
    if('buyathome' in queryParams) {
      payload = { ...payload, storeIdList: ["20", "5063", "3760"] }
    }
    // if('storeId' in queryParams) {
    //   payload = { ...payload, storeIdList: [queryParams.storeId] }
    // }
  }

  if (getCity()) {
    const { cityName, ...rest } = payload
    payload = {
      ...rest,
      cityId: getCity().cityId
    }
  }

  const { pageNumber } = payload
  if (!pageNumber) {
    payload = { ...payload, pageNumber: 1 }
  }

  return payload
}