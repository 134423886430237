import { combineReducers } from "redux"
import banner from './bannerReducer'
import inventory from './bikeListReducer'
import cities from './citiesReducer'
import brand from './brandReducer'
import budgetInventory from './budgetBikeReducer'
import popularInventory from './popularBikeListReducer'
import vehicle from './bikeReducer'
import similarInventory from './similarBikeReducer'
import stores from './storeListReducer'
import activePage from './currentPageReducer'
import bikeSearch from './searchBikeReducer'
import currentCity from './selectedCityReducer'
import bikeFilters from './bikeFilterReducer'
import inspection from './bikeInspectionReducer'
import search from './searchReducer'
import drawer from './drawerReducer'
import thankYou from './thankYouReducer'
import sellBikeSearch from './sellBikeSearchReducer'
import deviceDetail from './deviceReducer'
import pageTitle from './titleReducer'
import allStoreList from './allStoreReducer'
import selectedStores from './selectedStoreReducer'
import sidebar from './sidebarReducer'
import jevList from './allJevListReducer'
import topSellingBikes from './topSellingBikesReducer'
import areas from './getAreasReducer'
import subAreas from './getSubAreasReducer'
import store from './storePageReducer'
import testimonial from './testimonialsReducer'
import popularSearch from './popularSearchesReducer'
import popularBikes from './credrPopularBikesReducer'
import recent from './recentlyBoughtReducer'
import loader from './loaderReducer'
import searchedBikes from './bikeSearchByCityReducer'
import brandSelectorReducer from './brandSelectorReducer'
import citySelectorReducer from './citySelectorReducer'
import showAppReducer from './showAppLinkReducer'
import { reducer as form } from 'redux-form'
import drawerSuperBike from "./drawerReducer_superBike"

export default combineReducers({
  banner,
  inventory,
  cities,
  brand,
  budgetInventory,
  popularInventory,
  vehicle,
  similarInventory,
  stores,
  activePage,
  bikeSearch,
  currentCity,
  bikeFilters,
  inspection,
  search,
  form,
  drawer,
  thankYou,
  sellBikeSearch,
  deviceDetail,
  pageTitle,
  allStoreList,
  selectedStores,
  sidebar,
  jevList,
  topSellingBikes,
  store,
  popularBikes,
  popularSearch,
  testimonial,
  recent,
  loader,
  areas,
  subAreas,
  searchedBikes,
  brandSelectorReducer,
  citySelectorReducer,
  showAppReducer,
  drawerSuperBike
})