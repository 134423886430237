import { SEARCH_BIKE_ACTION } from '../../core/constants/actions'
import { searchBikes } from '../../core/services/bikeServices'
import { initializeGA } from '../../core/utility'

export function searchBike(searchKey, cityId = 0) {
  return (dispatch, getState) => {
    dispatch(request())
    if (searchKey) {
      return searchBikes(searchKey, cityId)
        .then(apiResponse => {
          if (apiResponse.isValid) {
            let city = getState().currentCity.currentCity
            city = city ? city : 'All'
            initializeGA('Results_Found', searchKey, city)
            const searchBikeList = apiResponse.mmvResponse
            dispatch(success({
              searchBikeList
            }))
          } else {
            let city = getState().currentCity.currentCity
            city = city ? city : 'All'
            initializeGA('No_Results_Found', searchKey, city)
            dispatch(failure({ error: apiResponse.message }))
          }
        })
    } else {
      dispatch(success({
        searchBikeList: []
      }))
    }
  }

  function request() { return { type: SEARCH_BIKE_ACTION.REQUEST } }
  function success(searchBikeList) { return { type: SEARCH_BIKE_ACTION.SUCCESS, payload: { ...searchBikeList } } }
  function failure(error) { return { type: SEARCH_BIKE_ACTION.FAILURE, payload: { ...error } } }
}