import React, { useState, useEffect, Fragment } from 'react'
import UpperFooter from './Upper-Footer'
import Lowerfooter from './Lower-Footer'
import BuyLandingFooter from './NewBuyLandingUpperFooter'
import SeoFooter from './SEO-Footer'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import query from 'query-string'

const Footer = () => {

  const [disable, setDisable] = useState(false)
  const location = useLocation()
  const currentCity = useSelector(state => state.currentCity.currentCity)
  const cities = useSelector(state => state.cities.cityList)
  const [disableHeader, setDisableHeader] = useState(true)

  useEffect(() => {
    if (location.search) {
      const queryParams = query.parse(location.search)
      if ('utm_campaign' in queryParams) {
        if (queryParams.utm_campaign === "Leads-Francise_bangalore") {
          setDisable(true)
        }
        if (queryParams.utm_campaign === "Leads-Francise_jaipur") {
          setDisable(true)
        }
      }
    }

    if (location.pathname === '/sell-landing-page-for-ads-bangalore') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-nashik') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-delhi') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-mumbai') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads/') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_bangalore') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_mumbai') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_nashik') {
      setDisableHeader(false)
    }
    if (location.pathname === '/buy-landing-page') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-google-ads') {
      setDisableHeader(false)
    }
    if (location.pathname === '/struggling-to-sell-your-Honda-Activa-or-Honda-CD-Deluxe') {
      setDisableHeader(false)
    }
    if (location.pathname === '/buy-landing-page-for-zest-bikes') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_delhi') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_jaipur') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sellbikes/banglore/yamaha/') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/banglore/yamaha') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/delhi/yamaha') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/jaipur') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/ahmedabad') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/bhilwara') {
      setDisableHeader(false)
    }
    if (location.search === '?care=yes') {
      setDisableHeader(false)
    }
    if (location.state === '?care=yes') {
      setDisableHeader(false)
    }
    if (location.pathname === '/door-step-service-landing') {
      setDisableHeader(false)
    }
    if (location.pathname === '/s') {
      setDisableHeader(false)
    }
  }, [])

  return (
    <div id="footPage">
      {
        !disable &&
        <Fragment>
          {
            (location.pathname === '/buy-landing-page-for-zest-bikes' ) &&
              // <UpperFooter currentCity={currentCity} /> :
              <BuyLandingFooter currentCity={currentCity} />
          }
          {
            location.pathname !== '/door-step-service-landing' && location.pathname !== '/buy-landing-page-for-zest-bikes' ?
            <UpperFooter currentCity={currentCity} /> :
            <></>
          }
          {/* <UpperFooter currentCity={currentCity} /> */}
          {
            disableHeader &&
            <>
              <SeoFooter currentCity={currentCity} cities={cities} />
              <Lowerfooter />
            </>
          }
        </Fragment>
      }
    </div>
  )
}

export default Footer