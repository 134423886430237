import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../Loader'

const FullPageLoader = () => {

  const showLoader = useSelector(state => state.loader.show)

  useEffect(() => {
    const root = document.getElementById('root')
    if (showLoader) {
      root.classList.add('loader-show')
    } else {
      root.classList.remove('loader-show')
    }
  })

  return (
    <div className={`loader-backdrop${showLoader ? ` show` : ''}`}>
      <Loader />
    </div>
  )
}

export default FullPageLoader