import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getUrlSanatizedString } from '../../../core/utility'
import query from 'query-string'
import { useLocation } from 'react-router-dom'
import './Upperfooter.scss'

import { API_ENDPOINTS } from '../../../core/constants/apiEndpoints'
let imageSrcPath = API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let FbIcon = imageSrcPath + '/fb-icon.svg'
let Twitter = imageSrcPath + '/twitter-icon.svg'
let LinkedIn = imageSrcPath + '/linkedin-icon.svg'
let Insta = imageSrcPath + '/insta-icon.svg'
let YouTube = imageSrcPath + '/youtube-icon.svg'

const getRouterLink = (city, bike) => {
  return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}/${getUrlSanatizedString(bike)}` : `/all-used-bikes/${getUrlSanatizedString(bike)}`
}

const UpperFooter = ({ currentCity }) => {
  const [disable, setDisable] = useState(true)
  const location = useLocation()
  const queryParams = query.parse(location.search)  

  useEffect(() => {
    if (location.pathname === '/sell-landing-page-for-ads-bangalore') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-nashik') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-delhi') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-pune') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-mumbai') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_bangalore') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_pune') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads/') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_bangalore') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_pune') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_mumbai') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_nashik') {
      setDisable(false)
    }
    if (location.pathname === '/buy-landing-page') {
      setDisable(false)
    }
    if (location.pathname === '/sell-landing-page-for-google-ads') {
      setDisable(false)
    }
    if (location.pathname === '/buy-landing-page-for-zest-bikes') {
      setDisable(false)
    }
    if (location.pathname === '/struggling-to-sell-your-Honda-Activa-or-Honda-CD-Deluxe') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_delhi') {
      setDisable(false)
    }
    if (location.pathname === '/sell_bike_fb_jaipur') {
      setDisable(false)
    }
    if (location.pathname === '/sellbikes/banglore/yamaha/') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/banglore/yamaha') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/delhi/yamaha') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/pune') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/jaipur') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/ahmedabad') {
      setDisable(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/bhilwara') {
      setDisable(false)
    }
      if (location.state === '?care=yes' || location.search === '?care=yes') {
        setDisable(false)
      }
    if(location.pathname === '/door-step-service-landing') {
      setDisable(false)
    }
    if(location.pathname === '/s') {
      setDisable(false)
    }
  }, [])

  return (
    <div className="footer">
      <div className="innerBlock" >
        {
          disable &&
          <div className="d-inline-block">
            <h5 className="font-semibold font16" >Brands</h5>
            <ul className="list-group brands">
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'Suzuki')}>Suzuki </Link></li>
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'Bajaj')}>Bajaj</Link></li>
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'Honda')}>Honda</Link></li>
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'Hero')}>Hero</Link></li>
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'Royal Enfield')}>Royal Enfield</Link></li>
              <li className="list-group-item brandLinks" > <Link to={getRouterLink(currentCity, 'KTM')}>KTM</Link></li>
            </ul>
          </div>
        }
        {
        <div className="d-inline-block">
          <h5 className="font-semibold font16">Info</h5>
          <ul className="list-group otherPagesfooter ">
            {/* <li className="list-group-item"> <Link to="/how-it-works">How it Works</Link></li> */}
            {/* <li className="list-group-item"> <Link to="/faq">FAQ</Link></li> */}
            <li className="list-group-item"> <Link to="/Terms-of-use">Terms of Use</Link></li>
            <li className="list-group-item"> <Link to="/privacy-policy">Privacy Policy</Link></li>
            {/* <li className='list-group-item'><Link to ="/oem-t&c">Terms & Conditions</Link></li> */}
            {/* <li className="list-group-item"> <Link to="/careers">Careers</Link></li> */}
            {
              disable &&
              <li className="list-group-item"> <Link to="/contact-us">Contact Us</Link></li>
            }
          </ul>
        </div>
        }
        {
        <div className="d-inline-block">
          <h5 className="font-semibold font16" >Address</h5>
          <ul className="list-group addressfooter" >
            <li className="list-group-item">
              2nd Floor, A, 212, 1st Main Rd, Stage 2, Domlur, Bengaluru, Karnataka 560071
          </li>
            <li className="list-group-item">
              CIN - U50404KA2015PTC148110<br />
              {/* <a href="tel:+91 90755 55557">Call: +91 90755 55557</a><br /> */}
              <a href="mailto:cs@credr.com">Email: cs@credr.com</a>
            </li>
            <li className="list-group-item">
              <div className="whatsApp">
                <a href="https://wa.me/918884885455?text=Hello%20CredR" aria-label="Support Whatsapp" target="_blank" rel="noopener noreferrer">
                  <i className="Icon float-left"></i>
                </a>
                <a href="https://wa.me/918884885455?text=Hello%20CredR" target="_blank" rel="noopener noreferrer" className="franchiseEmails">888 488 5455</a>
              </div>
            </li>
          </ul>
        </div>
        }
        {
          disable &&
          <div className="socialIcon float-right" >
            <a href="https://www.youtube.com/channel/UCVIlwyD4kgCJqLYIgOL92mw" target="_blank" className="icons" rel="noopener noreferrer" >
              <img src={YouTube} alt="YouTube" />
            </a>
            <a href="https://www.facebook.com/credr/" target="_blank" className="icons" rel="noopener noreferrer" >
              <img src={FbIcon} alt="Facebook" />
            </a>
            <a href="https://twitter.com/CredrAuto" target="_blank" className="icons" rel="noopener noreferrer" >
              <img src={Twitter} alt="Twitter" />
            </a>
            <a href="https://in.linkedin.com/company/credr" target="_blank" className="icons" rel="noopener noreferrer" >
              <img src={LinkedIn} alt="Linked In" />
            </a>
            <a href="https://www.instagram.com/credr_auto/" target="_blank" className="icons mr-0" rel="noopener noreferrer" >
              <img src={Insta} alt="Instagram" />
            </a>
          </div>
        }
      </div>
    </div>
  )
}

export default UpperFooter