//  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-474952713"></script> 
export function gtag(event, conversion, args){
    if(typeof window !== 'undefined'){
        let dataLayer = window.dataLayer || []; 
    dataLayer.push({
        event: event,
        conversion: conversion,
        send_to: args
    });
} 
}