import { SET_SIDEBAR } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  isOpen: false
}

export default function sidebarReducer(state = intialState, action) {

  switch (action.type) {
    case SET_SIDEBAR:
      const { isOpen } = action.payload
      return newState(state, { isOpen })

    default:
      return state;
  }
}