import { GET_POPULAR_BIKES_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  bikeNames: [],
  cityID:0,
  isLoading: false
}

export default function credrPopularBikesReducer(state = intialState, action) {

  switch (action.type) {
    case GET_POPULAR_BIKES_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case GET_POPULAR_BIKES_ACTION.SUCCESS:
      const { cityID,bikeNames } = action.payload
      return newState(state, { cityID, bikeNames,isLoading: false })

    case GET_POPULAR_BIKES_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state;
  }
}