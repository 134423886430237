import { BIKE_ACTION } from '../../core/constants/actions'
import { getBike } from '../../core/services/bikeServices'

export function getBikeById(bikeId) {
  return dispatch => {
    dispatch(request())
    return getBike(bikeId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const vehicle = apiResponse.inventoryDetail
          dispatch(success({
            vehicle
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: BIKE_ACTION.REQUEST } }
  function success(vehicle) { return { type: BIKE_ACTION.SUCCESS, payload: { ...vehicle } } }
  function failure(error) { return { type: BIKE_ACTION.FAILURE, payload: { ...error } } }
}