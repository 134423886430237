import React from 'react'
import { Link } from 'react-router-dom'

export const removeHyphen=(name)=>{
  let newName=name.split("-").join(" ")
  return newName
}
export function getTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

function getContentHTMLString(makeModel, vehicle) {

  const getH1Title= (make, vehicle) =>{
    
    return `Buy Second Hand ${getTitleCase(removeHyphen(make))} Bikes & Scooters from CredR${vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}`
  }
  switch (makeModel) {

    case 'KTM': return (
      <>
        <h1 className="mb-3"><b>Premium Quality Used KTM Bikes in India: Best Price & Hassle Free Documentations</b></h1>
        <p>Wondering which KTM bike you should plan out to buy? Sit back and relax. Here at CredR, you'll get the genuine list of two-wheeler KTM bikes at an affordable price. We are here to help you purchase the most innovative and loved KTM bikes from the authentic owners at the latest price, updated specifications, high-resolution videos, and even the best price for second-hand KTM bikes online. Of all the top KTM models in India, here we have the collection of used KTM bikes that includes: KTM 200 Duke, KTM 250 Duke, KTM 125 Duke, KTM 390 Duke, KTM RC 390, KTM RC 200 are the leading ones.</p>
        <br />
        <p>In the year 1953, the business,am Ernst Knonreif, became the most sizable shareholder Kraftfahrzeug Trunkenpolz Mattighofen who were renamed and registered as the Kronreif and Trunkenpolz Mattighofen. The bike series of KTM started at R100 in 1954 with just 20 employees; later, it started getting more attraction and was built at three per day.</p>
        <br />
        <p>Shortly after, the company's foremost title was secured with the 1954 Austrian 125 national championship. The brand KTM made its first appearance and a mind-blowing impression in the International Six days trials in 1956, securing a gold medal. These were the events where KTM started getting a lot of attention and became that one brand that has effectively increased over time down 40 and more years with 42 and more diverse models accessible across the globe. KTM also owns Husqvarna, the Vitpilen 401 and Svartpilen models are made in India.</p>
        <br />
        <h4><b>Benefits of Buying Used KTM bikes in India</b></h4>
        <p>Here're ample reasons why you should buy a used KTM bike in India. The reasons are listed below;</p>
        <ul>
          <li><b>Outstanding design:</b> The design of the KTM bikes are beautiful with their enthralling curves and elements. It attracts prospective buyers. From the fairing to the sharp and sporty rear end, the second hand KTM bike looks impeccable. </li>
          <li><b>Platform:</b> Both the KTM bikes 200 and 390 Duke play a significant role in the formation of the machine. The series of Duke bikes are fantastic in tweaking to shape the Rc 200 and Rc 390. The tabular spaced frame of the motorcycle with steel tubes and all the machines are crafted well to perform like a warrior. The used KTM RC series get a much uplifter sportbike like the rear, making it utterly rack track suitable for the riders. </li>
          <li><b>Smart Engine:</b> KTM motorcycles are well featured and powered by a mono-cylinder, 4 strokes, water-cooled engine with a displacement of 199.5cc and 373.2cc, respectively. The machines are primarily mighty, falling under the subcategory of the 500cc category. The bike produces a maximum power of 25 bhp with Rc390 dishes out with an astonishing 45 bhp power. </li>
          <li><b>Transmission:</b> All the KTM bikes are 6-speed claw shifted transmission that works perfectly with a mechanically operated clutch in the oil bath. The bike's gear ratio is 22:72 and 14:42 for primary and secondary gear, respectively. </li>
          <li><b>Wheels and Tyres are robust:</b> Second hand KTM bikes in India are the same as the first ones. KTM has used diverse tyres to run its models like RC 200 and Rc390. However, the size of the tyre is pretty big and has an excellent grip with improved maneuverability.</li>
          <li><b>Braking:</b> KTM has offered both machines a disc brake of 300mm with four-pot brake calipers at the front and a 230mm disc brake at the rear. The company has also kept an anti-locking brake system with a standard option only available for the RC390. </li>
          <li><b>SuperSport Features:</b> KTM has kept the price of the two machines most affordable, but it has undoubtedly accelerated the worth of the product by bringing the perfect bodywork. The bikes' supersport look with twin headlights has a sharp cowl with a rearview mirror and integrated blinkers. The exciting positioning of the pillion set is well designed for a comfortable ride for both the riders and a great view too.</li>
        </ul>
        <p>The collection of the used KTM bikes in CredR is vast and of top quality, and the bikes are refurbished to retain the high-quality outlook and performance. If you have already made up your mind to buy second hand KTM bikes, then you explore the KTM bike list on the CredR website to book your favorite KTM bike, follow the process and get the test ride experience as well without any hassle.</p>

      </>
    )
    case 'KTM-DUKE': return (
      <>
       <h1 className="mb-3"><b>Buy Second Hand KTM Duke Bikes from CredR</b></h1>
       <p>The austrian bike company KTM Duke model bikes has been loved not only by the youngsters but bikers of all age groups. We at CredR, provide the best second hand KTM Duke bikes at an eminent quality and price. The KTM Duke model which has been everyone’s favourite as it has incorporated many important features that focused on its bodywork and ergonomics that allowed it to reach its maximum potential. Their engine’s put up a drastic performance which actually makes KTM Duke to set benchmarks. We at CredR offer second hand KTM Duke bikes at an affordable price. These second hand ktm duke models are now available in our showrooms with new interesting buying options.These second hand ktm duke bikes are also available in an attractive colour combination for a customer friendly buy. All the ktm dude second hand bikes found in our multiple showrooms  have gone through rigorous quality checks which enables the user to make the right choice while choosing their bikes.All the second hand KTM Duke bikes available with CredR comes with a 6 months warranty period and a 7 days buy protect plan. Why wait just upgrade.</p>
     </>
    )

    case 'ROYAL-ENFIELD': return (
      <>
        <h1><b>Buy Most Popular Second Hand Royal Enfield bikes in India.</b></h1>
        <p>Royal Enfield bikes have a huge demand across the globe. The most preferred bikes are for Royal Enfield Classic and Royal Enfield Bullet. CredR has an assorted collection of <b>used Royal Enfield in Bangalore, used Royal Enfield in Pune, used Royal Enfield in Delhi, and used Royal Enfield in Jaipur</b>. There are numerous <b>second hand Royal Enfield bike showrooms</b> that have opened up and have a good collection of bikes. All are refurbished for retaining premium quality. The service of CredR is phenomenal in every respect. </p>
        <br />
        <h3><b>Top Second Hand Royal Enfield for sale </b></h3>
        <p>Check out the latest collection of <b>Used hand Royal Enfield bikes</b>- one of the oldest running bike manufacturers in the world. The assorted collection of used Royal Enfield bikes starts at a price range of Rs. 50,000 onwards.</p>
        <br />
        <p>There are six new Royal Enfield Models in India that are highly popular among bikers. <b>Used hand Royal Enfield classic 350</b>, Royal Enfield Meteor 350, Royal Enfield Bullet 350, Royal Enfield Interceptor 650, Royal Enfield Continental 650, Royal Enfield Himalayan all are priced based on the market. </p>
        <br /><p><b>Second hand Royal Enfield for sale</b> from CredR has been selling almost all the models at a much affordable price.  Royal Enfield second hand prices are reasonable and as per market standards.</p>
        <br />
        <h4><b>Best Selling pre-owned Royal Enfield Bike</b></h4>
        <p>The iconic bikes of <b>second hand Royal Enfield bullet</b>. have survived for ages, with the timeless specifications, details, color, upgraded technology marking a memory of its legacy. With the launch of twin-cylinder, this brand has entered into a new chapter in India and across the world.</p>
        <br />
        <p>The collection of <b>pre-owned used bikes at CredR</b> comes with a full trust total score and many benefits. Browse through the collection of Royal Enfield bikes at CredR. You can <Link to="/sellbikes" className="font-semibold">sell your old bike,</Link> buy a used bike and exchange the bike with ease.</p>
        <br />
        <h5><b>Most Searched on CredR</b></h5>
        <p><a href="https://www.credr.com/all-used-bikes-in-Bangalore/Royal-Enfield"><b>Used Royal Enfield bikes in bangalore </b></a>| <a href="https://www.credr.com/all-used-bikes-in-Pune/Royal-Enfield"><b>Used Royal Enfield bikes in Pune</b></a> | <a href="https://www.credr.com/all-used-bikes-in-Delhi-NCR/Royal-Enfield"><b>Used Royal Enfield bikes in Delhi</b> </a>| <a href="https://www.credr.com/all-used-bikes-in-Jaipur/Royal-Enfield"><b>Used Royal Enfield bikes in Jaipur</b> </a>| <a href="https://www.credr.com/all-used-bikes-in-Bangalore/Bajaj-Pulsar"><b>used Bajaj pulsar in bangalore </b></a>|<a href="https://www.credr.com/used-bikes-in-Bangalore"><b>used bikes in bangalore</b> </a>|<a href="https://www.credr.com/all-used-bikes-in-Pune"><b>used bikes in pune</b></a>|<a href="https://www.credr.com/used-bikes-in-Delhi-NCR"><b>used bikes in delhi</b> </a>| <a href="https://www.credr.com/sellbikes"><b>sell bike instantly</b></a></p>
      </>
    )
    case 'ROYAL-ENFIELD-BULLET': return (
      <>
       <h1 className="mb-3"><b>Get A Used Royal Enfield Today At An Affordable Price From CredR</b></h1>
       <p>Discover an array of Royal Enfield bikes at CredR that help you stand out from their crowd with the help of their everlasting iconic designs. Boasting a powerful single-cylinder engine, Royal Enfield is the best bet for riders who appreciate a raw riding experience. With its distinct thumping exhaust note, the Enfield allures riders who are fond of vintage bikes.</p>
       <br />
       <p>The engines of these motorcycles are capable of generating a power of 47 Bhp and a torque of 52 Nm. Supporting an upright riding position, these bikes allow riders to drive for extended hours without feeling tired. From the perspective of stability, riders can enjoy more comfort with its handlebar’s clip and enhanced aerodynamics.</p>
       <br />
       <p>Get ready to cruise on highways, navigate, urban sprawls, and explore off-road trails with Enfield. Visit CredR’s website to discover resilient and well-maintained used Royal Enfield bikes that start at a price of Rs. 75,000. Buy a Bullet 350 or Bullet 500 that meet CredR’s high quality standards.</p>
       <br />
     </>
    )
    case 'ROYAL-ENFIELD-BULLET-350': return (
      <>
       <h1 className="mb-3"><b>Buy Second Hand Royal Enfield Bullet 350 Bikes From CredR</b></h1>
       <p>The Royal Enfield Bullet 350 is a cruiser bike available in 3 variants in India. The Royal Enfield Bullet 350 is powered with a 346cc single cylinder engine with 5 speed transmission gearbox. The Royal Enfield Bullet 350 is equipped with a front disc and rear drum brakes which also has an anti-locking braking system. Weighing more than otherl bikes the Royal Enfield Bullet 350 is available in 7 unique colours. The standard model features the original Royal Enfield kick starter. The Royal Enfield Bullet 350 maintains a retro theme in its hardwares which seals the deals for many customers.</p>
     </>
    )
    case 'ROYAL-ENFIELD-CLASSIC': return (
       <>
        <h1 className="mb-3"><b>Buy Second Hand Royal Enfield Classic Bikes From CredR</b></h1>
        <p>The timeless machine, Royal Enfield Classic 350 is available in two variants such as Classic 350 single channel and Classic 350 double channel. The Royal Enfield Classic 350 comes with a 346cc single cylinder air cooled 4 stroke engine with electric and kick engine start. The  bike is equipped with a single channel braking system powered with dual ABS technology the bike offers a comfortable travel. The bike provides a mileage of 37 km and a 13.5 ltr fuel tank capacity. Royal Enfield Classic 350 is available in 11 colours with popular colours being chestnut, black, and redditch red.</p>
      </>
    )
    case 'ROYAL-ENFIELD-THUNDERBIRD': return (
      <>
       <h1 className="mb-3"><b>Buy Second Hand Royal Enfield thunderbird Bikes From CredR</b></h1>
       <p>The Royal Enfield 500 bike is a cruiser model bike designed with a comfortable frame for a smooth drive which comes in handy when travelling long distances. The Royal Enfield Thunderbird bike is fitted with a single-cylinder 4 stroke air-cooled 346cc engine. The bike also has both electric and kick start with a dual-channel ABS braking channel. With high on-road performance, the average mileage of the bike is said to be around 35 kmpl. Available with a BS6 update, the thunderbird 500 version comes in three colours schemes and also has a fuel capacity of 20 litres.</p>
     </>
    )

    case 'USED-BIKES-BNGLR-ENFIELD':return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Royal Enfield bike in Bangalore</b></h1>
        <p>Royal Enfield is a top brand that has revived itself from extinction and has become hugely popular from that point on. The most preferred bikes are Royal Enfield Classic and Royal Enfield Bullet. Owning a high-powered used Royal Enfield classic 350 is a dream of many enthusiastic riders. The presence of the <b> <a href='https://www.credr.com/all-used-bikes/Royal-Enfield-Bullet'>Second hand bullet</a></b> in Bangalore, the cosmopolitan city, has a diversified market for the Royal Enfield. In Bangalore, the continuous demand of second hand Royal Enfield Himalayan bikes is on a higher side. The affordability of the bike is much cheaper as compared to the new showroom bikes. </p>
        <br/>
        <h3 className="mt-2"><b>Best Selling Used Royal Enfield Motorcycle Price in Bangalore</b></h3>
        <p>There are numerous ranges of 1000+ second hand Royal Enfield for sale that can be explored for the best experiences. The second hand Royal Enfield bike showroom in Bangalore has the following range of bikes:</p>
        <ul>
          <li>Royal Enfield Classic 350-₹1.52 lakhs </li>
          <li>Royal Enfield Meteor 350-₹1.99 lakhs</li>
          <li>Royal Enfield Bullet 350-₹ 1.24 lakhs</li>
          <li>Royal Enfield Interceptor 650-₹ 2.7 lakhs</li>
          <li>Royal Enfield Continental 650-₹ 2.87 lakhs</li>
          <li>Royal Enfield Himalayan- ₹1.66 lakhs</li>
        </ul>
        <p className="mt-2"><b>Second hand Royal Enfield bullet or Second hand bullet price in Bangalore is now accessible at an affordable price.</b> The bikes must be in good condition, and the paperwork is all original. Any change of color in the <b><a href='https://www.credr.com/all-used-bikes-in-Bangalore/Royal-Enfield-Classic'>Used Royal Enfield classic 350 in Bangalore</a></b> is all refurbed to maintain top quality. Before buying a second hand Royal Enfield in Bangalore, you need to check the engine condition. Any noise coming out from the motor is a sign of repair. For sorting this condition, CredR is offering an Rs.399 test ride so that you get hands-on experience. </p>
        <p className="mt-2"><b>Second hand Royal Enfield bike showroom in Bangalore</b>- Indiranagar, Peenya, Koramangala, HSR Layout, Bellandur, and many more.</p>
        <br/>
        <h4 className="mt-2"><b>Used Royal Enfield in Bangalore</b></h4>
        <p>Royal Enfield is the top-rated bikes in Bangalore. There are many reasons why you should select a <b>Second hand Royal Enfield for sale in Bangalore</b>, the reasons are listed below:</p>
        <p className="mt-2"><b>1. Great on road: </b> You can <b>buy second hand Royal Enfield in Bangalore</b> because of its outstanding road presence. The <b> Used Royal Enfield in Bangalore</b> has attracted numerous buyers. The bike design is retro-style and timeless.</p>
        <p><b>2. Reliability: </b> The pre-owned Royal Enfield bikes in Bangalore are in good condition at CredR. The second hand Royal Enfield is reliable in the Indian market. With proper care, it is going to be a trouble-less experience for you.</p>
        <p><b>3. Fuel-Efficient bikes: </b> The <b>2nd hand Royal Enfield classic 350 in Bangalore</b> offers good mileage with the heavy-built and big engine. You will get power in performance, and you do not have to spend much on fuel.</p>
        <p><b>4. Comfortable to Ride: <a href='https://www.credr.com/all-used-bikes/Royal-Enfield'>Second hand Royal Enfield bikes </a></b> in Bangalore are all good for long rides. The seating position is great and does not create a lot of stress during the long rides.</p>
        <p>Choose a second hand Royal Enfield motorcycle in Bangalore, go through the technical specifications and features before buying one for yourself. The used bikes available at CredR are 100% certified, and test ride is easily applicable. The top motorcycle models of Royal Enfield bikes all are accessible in refurbished condition. On a limited budget, you can effortlessly buy top-class second hand Royal Enfield bikes from the Bangalore location.</p>
        <br/>
        <h5 className="mt-2"><b>Most Searched on CredR</b></h5>
        <p><a href='https://www.credr.com/all-used-bikes/Royal-Enfield'>Second hand Royal Enfield bikes</a> | <a href='https://www.credr.com/all-used-bikes-in-Pune/Royal-Enfield'>Used Royal Enfield bikes in Pune</a> | <a href='https://www.credr.com/all-used-bikes-in-Delhi-NCR/Royal-Enfield'>Used Royal Enfield bikes in Delhi</a> | <a href='https://www.credr.com/all-used-bikes-in-Jaipur/Royal-Enfield'>Used Royal Enfield bikes in Jaipur</a> | <a href='https://www.credr.com/all-used-bikes-in-Bangalore/Bajaj-Pulsar'> Used Bajaj pulsar in Bangalore</a> | <a href='https://www.credr.com/used-bikes-in-Bangalore'> Used bikes in Bangalore</a> | <a href='https://www.credr.com/all-used-bikes-in-Pune'> Used bikes in Pune </a>| <a href='https://www.credr.com/used-bikes-in-Delhi-NCR'>Used bikes in Delhi</a> | <a href='https://www.credr.com/sellbikes'>Sell bike Online</a> | <a href='https://www.credr.com/all-used-bikes'>Second hand Bikes </a></p>
      </>
    )
    case 'USED-BIKES-BNGLR-ENFIELD-CLASSIC': return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Royal Enfield Classic Bikes in Bangalore from CredR</b></h1>
        <p> The timeless machine, Royal Enfield Classic 350 is available in two variants such as Classic 350 single channel and Classic 350 double channel. The Royal Enfield Classic 350 comes with a 346cc single cylinder air cooled 4 stroke engine with electric and kick engine start. The  bike is equipped with a single channel braking system powered with dual ABS technology the bike offers a comfortable travel. The bike provides a mileage of 37 km and a 13.5 ltr fuel tank capacity. Royal Enfield Classic 350 is available in 11 colours with popular colours being chestnut, black, and redditch red.</p>
       </>
    )
    case 'USED-BIKES-DELHI-ENFIELD-CLASSIC': return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Royal Enfield Classic Bikes in Delhi-NCR from CredR.</b></h1>
        <p> The timeless machine, Royal Enfield Classic 350 is available in two variants such as Classic 350 single channel and Classic 350 double channel. The Royal Enfield Classic 350 comes with a 346cc single cylinder air cooled 4 stroke engine with electric and kick engine start. The  bike is equipped with a single channel braking system powered with dual ABS technology the bike offers a comfortable travel. The bike provides a mileage of 37 km and a 13.5 ltr fuel tank capacity. Royal Enfield Classic 350 is available in 11 colours with popular colours being chestnut, black, and redditch red.</p>
       </>
    )
    case 'USED-BIKES-DELHI-ENFIELD': return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Royal Enfield bike Online in Delhi.</b></h1>
        <p>Royal Enfield is a well-known motorcycle brand that has extraordinary popularity. The Royal Enfield Classic and Royal Enfield Bullet are the most popular variants of Royal Enfield. Many eager riders dream about owning a powerful <b> Second hand Royal Enfield classic 350</b>. The prevalence of <b><a href='https://www.credr.com/all-used-bikes/Royal-Enfield-Bullet'> Second hand bullets</a> </b> in Delhi  has given Royal Enfield a diverse market. The demand for <b>second hand Royal Enfield bikes in Delhi</b> is consistently high. When compared to new showroom bikes, the motorcycles are significantly less expensive.</p>
        <br/>
        <h3 className='mt-2'><b>Selling Used Royal Enfield Motorcycle Price in Delhi</b></h3>
        <p>There are <b>1000+second hand Royal Enfield for sale at CredR</b>, which may be examined for the ideal experiences. The following <b>second hand Royal Enfield motorcycle</b> is available at the <b>second hand Royal Enfield bike showroom in Delhi:</b></p>
        <br />
        <ul>
          <li>Royal Enfield Classic 350-₹1.52 lakhs</li>
          <li>Royal Enfield Meteor 350-₹1.99 lakhs</li>
          <li>Royal Enfield Bullet 350-₹ 1.24 lakhs</li>
          <li>Royal Enfield Interceptor 650-₹ 2.7 lakhs</li>
          <li>Royal Enfield Continental 650-₹ 2.87 lakhs</li>
          <li>Royal Enfield Himalayan- ₹1.66 lakhs</li>
        </ul>
        <p>The motorcycles must be in good working order, and all paperwork must be original. <b>Second hand Royal Enfield bullet</b> or <b>Second hand bullet price in Delhi</b> is now available at a low cost. If there is any colour change in the <b>used Royal Enfield Himalayan in Delhi</b> then believe the fact that it is completely refurbished to ensure top quality. The <b>Royal Enfield second hand price</b> is variable based on the variant and market trends.</p>
        <p>It would be best to inspect the engine condition before purchasing a  <b>second hand Royal Enfield in Delhi</b>. Any noise originating from the motor indicates that it needs to be repaired. CredR is offering an Rs.399 test ride to help you figure out what's wrong. <b>Second hand Royal Enfield bike showroom in Delhi</b> is located at Rani Bagh, and new showrooms are opening up shortly.</p>
        <br/>
        <h4 className='mt-2'><b>Reasons to Choose Used Royal Enfield in Delhi</b></h4>
        <p className='mt-2'>In Delhi, Royal Enfield motorcycles are the most popular among all. You'll get a <b>second hand Royal Enfield under 5000</b> available in top quality- check specifications, features and variants before buying your favourite Royal Enfield bike. There are several reasons why you should choose a <b>second hand Royal Enfield for sale in Delhi</b>, as noted below: </p>
        <ol className='mt-2'>
          <li><b>Great Performance:</b> You can acquire a <b>used Royal Enfield in Delhi</b> due to its strong presence on the road. Many people are interested in buying <b>Second hand Royal Enfield in Delhi</b> for its classic and retro features.</li>
          <li> <b>Value for Money:</b> CredR's used <b>Royal Enfield motorcycles in Delhi</b> are in good condition. In the Indian market, used Royal Enfield motorcycles are dependable. It will be a trouble-free experience for you if you take adequate care of it.</li>
          <li> <b>Save you money on Fuel:</b> The heavy-built and powerful engine of the  <b><a href='https://www.credr.com/all-used-bikes-in-Delhi-NCR/Royal-Enfield-Classic'>2nd hand Royal Enfield classic 350 in Delhi</a></b> provides good mileage. You will have more power in performance and won't have to spend much money on fuel. </li>
          <li><b>Reliable for Lengthy Trips:</b> The bike seat is excellent and does not cause undue stress during extended journeys. <b>Second hand Royal Enfield bikes in Delhi</b> are all reliable for long rides.
          </li>
        </ol>
        <p className='mt-2'>Before purchasing a <b>used Royal Enfield in Delhi</b>, make sure to read the technical specifications and characteristics. CredR's <b>second hand Royal Enfield bikes in Delhi</b> are 100% certified bikes. A test ride is simple to arrange. The top Royal Enfield motorcycle models are all available in restored condition. You may quickly get top-notch <b>second hand Royal Enfield bikes</b> online from the Delhi location if you have a constrained budget.</p>
        <p className='mt-2'><b>Most Searched on CredR</b></p>
        <p><a href="https://www.credr.com/all-used-bikes-in-Bangalore/Royal-Enfield">Used Royal Enfield bikes in bangalore</a> | <a href="https://www.credr.com/all-used-bikes-in-Pune/Royal-Enfield">Used Royal Enfield bikes in Pune </a>| <a href="https://www.credr.com/all-used-bikes/Royal-Enfield">Used Royal Enfield bikes </a>|<a href="https://www.credr.com/all-used-bikes-in-Jaipur/Royal-Enfield"> Used Royal Enfield bikes in Jaipur</a> | <a href="https://www.credr.com/all-used-bikes-in-Bangalore/Bajaj-Pulsar">used Bajaj pulsar in bangalore </a> | <a href="https://www.credr.com/used-bikes-in-Bangalore">used bikes in bangalore </a>| <a href="https://www.credr.com/all-used-bikes-in-Pune">used bikes in pune</a>| <a href="https://www.credr.com/used-bikes-in-Delhi-NCR">used bikes in delhi </a>| <a href="https://www.credr.com/sellbikes">sell bike Online </a>| <a href="https://www.credr.com/all-used-bikes">Second hand Bikes for sale</a>|</p>
      </>
    )

    case 'BAJAJ': return (
      <>
       <h1 className="mb-3"><b>Buy Second Hand Bajaj Bikes & Scooters from CredR</b></h1>
        <p>Browse the wide variety of assorted Bajaj bikes/ used Bajaj bikes available online for sale in India at CredR. The used bikes of Bajaj start at a price of Rs. 25,000 onwards based on specifications, colour, year, and model details. There are 18 bike models offered by Bajaj. Bajaj Pulsar 150, Bajaj Pulsar NS200, Bajaj Pulsar 220F, Bajaj CT100, Bajaj Pulsar 125 Neon, Bajaj Pulsar RS200, Bajaj Platina 100, Bajaj Chetak, Bajaj Pulsar NS 125, Bajaj Pulsar 180, Bajaj Dominar 400, Bajaj Pulsar NS160, Bajaj Avenger Cruise 220, Bajaj Avenger Street 160, Bajaj CT110, Bajaj Dominar 250, Bajaj Platina 110 HGear, Bajaj Platina 110. The upcoming models of Bajaj are Bajaj NS250, Bajaj Pulsar 250, Bajaj Avenger 400, Bajaj 2021 Pulsar NS160, and Bajaj Pulsar RS400.</p>
        <br/>
        <p>Bajaj has travelled a long way in offering great geared scooters to delivering a swift performance that makes it a much loved two-wheeler brand among the customers. Bajaj is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>
      </>
    )
    case 'BAJAJ-AVENGER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj Avenger Bikes from CredR</b></h1>
        <p>Explore the comprehensive collection of used bikes/ Bajaj Avengers Bikes from CredR, offering top quality bikes, great deals and refurbished bikes at a reasonable price. All the used bikes/ used Bajaj Avenger bikes are starting at a price range of Rs. 35,000 onwards. There are numerous variants of Bajaj Avenger- Bajaj Avenger Cruise 220, Bajaj Avenger Street 160, Bajaj Avenger Street 150, Bajaj Avenger Street 180, Bajaj Avenger 200.</p>
        <br />
        <p>Each variant of the Bajaj Avenger is top reviewed by the customers. It is ideal for visual appeal, performance, comfort, service experience, low maintenance cost, extra unique features and value for money. The bike is predominantly one of the top chosen bikes of the decade. Compared to other Bajaj Bikes, the used bikes/ used Bajaj Avenger bikes are excellent in the fuel economy combination system. At CredR, the assorted collection of the used Bajaj Avenger Bikes are dynamic in design, refurbished and have the powerful engine as minuted checked by the auto experts under 120+ levels of checkpoints. All the second hand Bajaj Avenger bikes are available with a host of benefits. Now you can sell your bike, buy a used bike, or exchange bikes that are made more accessible with CredR.</p>
      </>
    )
    case 'BAJAJ-PULSAR': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj Pulsar Bikes In India from CredR</b></h1>
        <p>Browse through the varied array of Bajaj Pulsar bikes available at a reasonable price starting from Rs. 25,000 onwards. All the used Bajaj Pulsar bikes are refurbished for finest quality and total trust score are verified minutely. Bajaj Pulsar is one of the most chosen bikes among the bikers in this decade. The brand Bajaj has gained trust and genuinity among the customers with their impressive Bajaj Pulsar collection. This budget bike, Bajaj Pulsar, is available in 4 variants and six colours. Bajaj Pulsar 125 Drum BS VI, Bajaj Pulsar 125 Drum- Split Seat, Bajaj Pulsar 125 Disc BS VI, Bajaj Pulsar 125 Disc- Split Seat.</p>
       <br/>
        <p>Bajaj Pulsar is easy to drive on the city roads. It has sporty nature, classy performance, powerful engine setup, and excellent fuel economy combination system. The pre-owned second hand motorcycle Bajaj Pulsar models are checked rigorously to meet the quality standards. Exchange your bike, Sell my bike and <Link to="/all-used-bikes" className="font-semibold">buy used bike from CredR</Link> is easy and convenient, along with a host of benefits. Book your Bajaj Pulsar today! </p>
      </>
    )
    case 'BAJAJ-PLATINA': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj Platina Bikes from CredR</b></h1>
        <p>Check out the collection of second hand bikes at CredR- Bajaj Platina for sale online in India. All the used bikes/used Bajaj Platina collection starts at Rs. 24,000 onwards, all variants are lined up. Bajaj Platina is an entry-level stylish commuter bike manufactured by the Bajaj brand. It is powered by a 102 cc single-cylinder engine generating a power of 7.79 bhp and excellent fuel efficiency. There are four top variants of the Bajaj Platina bike. Bajaj Platina 100 KS Drum, Bajaj Platina 100 ES Drum, Bajaj Platina 100 ES Drum, and Bajaj Platina 100 ES Disc.</p>
       <br/>
        <p>With a super-performing engine, this bike is more conventional yet comfortable for riders. It is ideal for daily movement in city traffic. There are two colours of it- black and red. Enjoy used bikes/ refurbished bikes- Bajaj Platina from CredR accessible at an affordable price. All the pre-owned Bajaj Platina bikes at CredR are good-quality refurbished bikes with a great deal and many added benefits. Check the specifications, year, colour, and model before you book a Bajaj Platina bike. Sell my bike, exchange bike or <Link to="/all-used-bikes" className="font-semibold">buy a used Bajaj Platina bike from CredR </Link>  today!</p>
      </>
    )
    case 'BAJAJ-DOMINAR-250': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj Dominar 250 from CredR</b></h1>
        <p>The Bajaj Dominar 250 bike is known for its muscular look and high torque engine. Also, the bike is said to impress youngsters with its attractive headlamp design and weight balance. The Bajaj Dominar 250 comprises a 248 cc, single-cylinder, liquid-cooled engine with a 6-speed manual gearbox. The Bajaj Dominar 250 bike has a special anti-locking brake system which makes the engine provide better performance. The all-new Bajaj Dominar 250 has hit the market in two different colour variants. The Bajaj Dominar 250 has a fuel economy range of around 30 kmpl and a fuel tank capacity of 13 L.</p>
      </>
    )
    case 'BAJAJ-CT100': return(
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj CT100 from CredR</b></h1>
        <p>The Bajaj CT 100 and CT 110 is one among the bikes with the highest fuel efficiency. Also known for simple comfortable seat styling, the Bajaj CT 110 bike is powered with a 115cc single-cylinder engine which generates around 8.4 bhp. The bike is available in 3 different colours and one of the best selling bikes in the market. The Bajaj CT 110 bike has a large fan following for its performance and because it is a good fuel efficient bike. The Bajaj CT 110 bike has a mileage of 70 kmpl and a fuel tank capacity of 10.5 litres.</p>
      </>
    )
    case 'BAJAJ-CT110': return(
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj CT110 from CredR</b></h1>
        <p>The Bajaj CT 110 is one among the bikes with the highest fuel efficiency. Also known for simple comfortable seat styling, the Bajaj CT 110 bike is powered with a 115cc single-cylinder engine which generates around 8.4 bhp. The bike is available in 3 different colours and one of the best selling bikes in the market. The Bajaj CT 110 bike has a large fan following for its performance and because it is a good fuel efficient bike. The Bajaj CT 110 bike has a mileage of 70 kmpl and a fuel tank capacity of 10.5 litres.</p>
      </>
    )
    case 'BAJAJ-DISCOVER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bajaj Discover Bikes from CredR</b></h1>
        <p>Discover the most desirable, longest-running, and successful motorcycle from Bajaj Auto. It is the first bike that introduced the 125cc segment that is now expanded into the 150cc category and offers an economical choice to the consumers. The used Bajaj Discover essentially has commuter styling that primarily focuses on the ease of riding, low maintenance cost, and fuel efficiency. The motorcycle looks simple yet has a dash of style. The used Bajaj Discover at CredR will benefit you in many ways. </p>
        <br />
        <p>All the used Bajaj Discover bikes are refurbished, collected from genuine sellers with no legal hassle, documentation is done swiftly, 6 months of warranty, free 7-day buy protection, and more. We have a wide selection of second hand bikes for sale at a low price. You should take a test ride for RS.399 from your home comfort and enjoy buying a used discover motorcycle of your choice.</p>
      </>
    )
    case 'BAJAJ-DOMINAR': return (
      <>
        <h1 className="mb-3"><b>Buy Used Bajaj Dominar From CredR</b></h1>
        <p>Explore a collection of premium used Bajaj Dominar bikes at CredR at an affordable price. Starting at a price of Rs.1,00,000, the robust build of these bikes promises a seamless touring experience for riders. Gaining rapid traction among riders fond of long-distance tours, the Dominar is available in two variants: the Bajaj Dominar 250 and the Bajaj Dominar 400. Positioned as a reliable companion by riders across the globe, the Dominar is known for conquering the most challenging routes across 5 continents. Pre-loaded with power, the Dominar features powerful acceleration that starts immediately and increases gradually.</p>
        <br />
        <p>Buy a second-hand Dominar that comes with an upright riding position and guarantees you strain-free rides. CredR’s auto experts conduct 120+ point inspections to ensure each bike is in prime condition. Buy a used Bajaj Dominar from CredR today. Browse from an array of bikes that flaunt a Leo-inspired and Sports Tourer Design. Add an extra layer of comfort and safety to your daily rides with the Dominar.</p>
      </>
    )
    case 'BAJAJ-V': return (
      <>
        <h1 className="mb-3"><b>Buy Used Bajaj V Bikes From CredR</b></h1>
        <p>Browse through a wide selection of used Bajaj V bikes at CredR, where you can discover bikes with a blend of heritage appeal and high performance. The latest addition to Bajaj’s portfolio of bikes, the price of second-hand Bajaj V starts from Rs. 30,000. The Bajaj V is inspired by the renowned aircraft carrier and warship INS Vikrant which took part in several battles fought by India. A part of V’s metal resembles that of INS Vikrant. While the V15 variant houses a 150cc DTS-i unit engine capable of generating a power of 12PS and torque of 13NM, the V12 comes with a 125cc engine that develops 10.9Nm and 10.7cc torque and power respectively.</p>
        <br />
        <p>Find out a range of Bajaj V bikes at CredRthat appear brawny and unique. CredR has an assorted collection of used V bikes that host a comfortable and spacious seat for two people. Solo rider can benefit from its rear seat cover.</p>
      </>
    )
    case 'USED-BIKE-BNG-BAJAJ-AVENGER': return (
      <>
        <h1><b>Refurbished Second Hand Avenger bikes at an Affordable Price</b></h1>
        <p>The Bajaj Avenger bike is the most desirable cruiser bike that one can buy in India. The assorted collection of the used avenger bikes is available in huge numbers at CredR showrooms in Bangalore. There are around 27 used avenger bikes at an affordable price. Explore the comprehensive collection of used bikes/ Bajaj Avengers Bikes from CredR, offering top-quality bikes, great deals, and refurbished bikes at a reasonable price. All the used Bajaj Avenger bikes are starting at a price range of Rs. 30,000 onwards. There are numerous variants of Bajaj Avenger- Bajaj Avenger Cruise 220, Bajaj Avenger Street 160, Bajaj Avenger Street 150, Bajaj Avenger Street 180, Bajaj Avenger 200.</p>
        <br />
        <h4><b>Second Hand Avenger in Bangalore for Sale</b></h4>
        <p>Each variant of the Bajaj Avenger is top reviewed by the customers. It is ideal for visual appeal, performance, comfort, service experience, low maintenance cost, unique features, and value for money. The bike is predominantly one of the top chosen bikes of the decade. Compared to other Bajaj Bikes, the used bikes are excellent in the fuel economy combination system.</p>
        <br />
        <p>At CredR, the assorted collection of the used Bajaj Avenger Bikes are dynamic in design, refurbished, and have the powerful engine as minutely checked by the auto experts under 120+ checkpoints. All the second hand Bajaj Avenger bikes are available with a host of benefits. Now you can sell your bike, buy a used bike, or exchange bikes with CredR.</p>
        <br />
        <p>CredR has a wide selection of second hand bikes for sale at a low price. Take a test ride for RS.399 from the comfort of your own home and enjoy buying a used Avenger motorcycle of your choice. The used Bajaj Avenger at CredR will benefit you in many ways. All the used Avenger bikes are refurbished, collected from genuine sellers, no legal hassle, documentation is done swiftly, 6 months of warranty, free 7-day buy protection, and more benefits to explore. </p>
      </>
    )
    case 'USED-BIKE-BNG-BAJAJ-CT100': return (
      <>
        <h1><b>Top Quality Second Hand Bajaj CT 100 Bike in Bangalore </b></h1>
        <p>Browse through the assorted collection of the Second hand Bajaj ct 100 in Bangalore. There are 10+ bajaj ct 100 in Bangalore. The used bikes are collected from genuine sellers. Second hand bajaj ct100 bikes are pretty popular among the youth and adults. Buy second hand bajaj at the best price in Bangalore based on the specifications, features, and locations. The brand Bajaj has maintained a never-failing market for the leading scooter segment of the two-wheeler market in India for ages. The popularly used Bajaj CT100 variants available here include: Bajaj CT100 Std and Bajaj CT100 125. The used CT100 price in Bangalore is based on the model, type, and year. It starts from Rs.25,000 onwards and is highly compatible with your budget. </p>
        <br />
        <h4><b>Second hand Bajaj CT100 in Bangalore at an Affordable Price</b></h4>
        <p>The best part of buying a used Bajaj CT100 or a second hand Bajaj bike from CredR is that you'll get some old model of Bajaj whose manufacturing is stopped now. The old models of Bajaj are still in demand for the following factors;</p>
        <br />
        <ul>
          <li>Reliability</li>
          <li>Comfortability</li>
          <li>Value of money</li>
          <li>Visual attractive features</li>
          <li>Great mileage</li>
        </ul>
        <p>At CredR, you'll get the best second hand Bajaj CT100 bikes at an affordable price. CredR is the one-stop for providing you countless benefits with buying, selling, and exchanging used bajaj bikes. CredR's website will assist you in obtaining a smooth and straightforward method for selecting second hand bajaj CT100 bikes within your set budget. There is an increased demand for certified bikes; reputable sellers have purchased and refurbished high-quality bikes. For quality assurance, the bikes are put through 120+ levels of inspection by auto professionals. Take a test ride for RS.399 from the comfort of your own home and enjoy buying a used Bajaj CT100 motorcycle of your choice.</p>
      </>
    )
    
    case 'ALL-USED-BIKES-IN-BANGALORE-BAJAJ-PULSAR':
      return (
        <>
          <h1><b>Second Hand Pulsar Bikes For Sale at the Best Price in Bangalore</b></h1>
          <p>Choose from the assorted collection of second hand Pulsar bikes in Bangalore. There are around 83 used Bajaj Pulsar bikes that are refurbished to retain the top quality. The bikes are ready for sale at a reasonable price. The collection of used bikes includes the Bajaj Pulsar 150 DTS-i, Bajaj Pulsar 200RS, Bajaj Pulsar 200NS, Bajaj Pulsar 150AS, Bajaj Pulsar 150, Bajaj Pulsar 220, Bajaj Pulsar 180 DTS-i, Bajaj Pulsar 220 F, Bajaj Pulsar 160NS, Bajaj Pulsar 135 Ls, Bajaj Pulsar 125 and more. </p>
          <br />
          <p>Browse through the varied array of second hand Bajaj Pulsar bikes available at a reasonable price starting from Rs. 25,000 onwards. All the used Bajaj Pulsar bikes are refurbished for the finest quality, and total trust scores are verified minutely. Bajaj Pulsar is one of the most chosen bikes among the bikers in this decade. The brand Bajaj has gained trust and genuinity among the customers with their impressive Bajaj Pulsar collection. This budget bike, Bajaj Pulsar, is available in 4 variants and six colours. Bajaj Pulsar 125 Drum BS VI, Bajaj Pulsar 125 Drum- Split Seat, Bajaj Pulsar 125 Disc BS VI, Bajaj Pulsar 125 Disc- Split Seat.</p>
          <br />
          <h3><b>Second Hand Pulsar Bikes in Bangalore - Reliable & Comfortable</b></h3>
          <p>Bajaj Pulsar is easy to drive on the city roads. It has sporty nature, classy performance, powerful engine setup, and excellent fuel economy combination system. The pre-owned second hand motorcycle Bajaj Pulsar models are checked rigorously to meet the quality standards. The bikes are incredibly comfortable for daily commuting. The range of used Bajaj Pulsar at CredR with its variants cater to the desired riders to meet their demands. Getting a high-performing and well-fashioned bike with a low budget makes the experience more enjoyable. People who want full flared motorcycles get a choice in the Pulsar RS200 model for travelling long distances. The brand Bajaj has made significant updates that make the bikers choose Bajaj Pulsar from time to time. </p>
          <br />
          <h4><b>Advantages of buying a Second Hand Bajaj Pulsar in Bangalore </b></h4>
          <p>Second hand Pulsar in Bangalore is all collected from trusted sellers. The bikes have a fresh look. Bajaj keeps tweaking the products with the new features and variants that make the bikes more interesting. The mechanical and new upgrades of the company retain the demand for the pulsar bikes among the riders.</p>
          <br />
          <p><b>Impressive Design:</b> The used pulsar bike series motorcycle has inherent masculine appeal. The bike has borrowed design cues from its other siblings that look pretty sporty. At the front, the sharp halogen headlamp with twin pilot lamps makes it aggressive with muscular tank shrouds and an engine cowl. The LED tail lamp of the bike gets an underbelly exhaust as well. The bike variant has three colors: metallic pearl, pewter grey, and burnt red. </p>
          <br />
          <p><b>Super Performance:</b> Used pulsar Bikes are powered with a BS6 compliant single-cylinder, oil-cooled engine, and a motor that pumps out a lot of power. The engine is mated to a 5-speed manual gearbox. The new technology-infused bikes with the fuel injection system make it a perfect bike for all.</p>
          <br />
          <p><b>Fully packed:</b> Bajaj Pulsar bikes range has quite a long list of features. The model comes with the front disc brakes, tubeless alloy wheels, engine kill switch, etc. There are notable features cancelling fuel indicators, oil coolers, and headlamps. The range of pulsar bikes attracts young enthusiasts who love updated technology in daily commuting bikes. All the models of pulsar bikes are very convenient and user-friendly.</p>
          <br />
          <p><b>Cheap to Maintain:</b> Investing in used pulsar bikes is very useful. The maintenance cost is less as all the Bajaj spare parts are readily available, and the parts aren't costly. With the young target customer base, the Bajaj Pulsar brand makes sure that the maintenance is not heavy on the pocket. It is easily accessible for a college student that makes sure that the pulsar remains a hot selling product in the market.</p>
          <br />
          <h4><b>Premium Quality Used Bajaj Pulsar Bikes: Book a Second hand Pulsar for Daily Commuting.</b></h4>
          <i>Are you looking for a Second hand pulsar bike at an affordable price?</i>
          <br />
          <br />
          <p>At the CredR showroom close to your location, you'll get an impressive bike collection and choose your favourite one at an affordable price. The Pulsar 150 digital meter second hand price under 70,000 will offer you comfort in riding and user-friendliness to enjoy the bike ride in city traffic. The price of a  Second hand Pulsar bike is variable based on the market range. There are no hidden charges, so be assured when buying a used motorcycle from CredR.</p>
          <br />
          <p>CredR has a wide selection of second hand bikes for sale at a low price. Take a test ride for RS.399 from the comfort of your own home and enjoy buying a used pulsar motorcycle of your choice.</p>
        </>
    )
    case 'USED-BIKE-BNG-BAJAJ-DISCOVER': return (
      <>
        <h1><b>Second Hand Discover in Bangalore at an Affordable Price</b></h1>
        <p>Buying a used Second hand Discover in Bangalore is now easy with CredR. Browse through the collection of used discover bikes at an affordable price. The second hand bikes in Bangalore are pretty popular amongst people in the city. The bikes are sold in a price range starting from Rs.25,000, which is much lower than the price of a brand new model after the complete bike inspection points. The used discover in Bangalore are available to the eager customers in good condition and at the best prices from various CredR showrooms located in Bangalore, including the CredR showroom in Indiranagar, CredR showroom in HSR, CredR showroom in Koramangala, CredR showroom in Bellandur, CredR showroom in Peenya and more. </p>
        <br />
        <p>The collection of Second hand Discover in Bangalore is collected from genuine sellers, and the bikes are collected and refurbished to retain good quality. The used Discover price in Bangalore is variable from Rs.25,000 to Rs 45,000 based on the bike conditions. The range of bikes includes Bajaj Discover 125M, Bajaj Discover 180Dts-i, Bajaj Discover 100M, Bajaj Discover 110, Bajaj Discover 125, and Bajaj Discover 100Std.</p>
        <br />
        <h4><b>Buy Second Hand Bajaj Discover Bikes from CredR</b></h4>
        <p>Discover the most desirable, longest-running, and successful motorcycle from Bajaj Auto. It is the first bike that introduced the 125cc segment that is now expanded into the 150cc category and offers an economical choice to the consumers. The used Bajaj Discover essentially has commuter styling that primarily focuses on the ease of riding, low maintenance cost, and fuel efficiency. The motorcycle looks simple yet has a dash of style. The used Bajaj Discover at CredR will benefit you in many ways. </p>
        <br />
        <p>All the used Bajaj Discover bikes are refurbished, collected from genuine sellers with no legal hassle, documentation is done swiftly, 6 months of warranty, free 7-day buy protection, and more. We have a wide selection of second hand bikes for sale at a low price. You should take a test ride for RS.399 from your home comfort and enjoy buying a used discover motorcycle of your choice.</p>
      </>
    )
   

    case 'ALL-USED-SCOOTERS-IN-BANGALORE-HONDA-ACTIVA': return (
      <>
        <h1><b>Top Quality Second Hand Activa in Bangalore {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>There are 137 used Activa present in Bangalore locations at CredR. The used bikes are collected from genuine sellers. Second hand activa bikes are quite popular among the youth and adults. Buy second hand activa at the best price in Bangalore based on the model, specifications, features, and locations. Honda Activa has maintained a never-failing market for the leading scooter segment of the two-wheeler market in India for ages. The popular used Activa in Bangalore variants includes Activa 3G, Activa 4G, Activa 5G, and Activa 6G. It has now turned out to be the household name. Both men and women enjoy the comfortable riding experience that the Honda Activa offers. It is considered to be the best mileage scooter in India.</p>
        <br />
        <h3><b>100% Certified 2ND Honda Activa Bikes in Bangalore </b></h3>
        <p>At a fair price, Honda Activa seconds are bought by many users enjoying the bike ride in the best possible ways. The demand for this bike is due to its lower price, which is listed below;</p>
        <br />
        <ul>
          <li>Honda Activa Ss110- ₹58,000</li>
          <li>Honda Activa 3G- ₹46,500</li>
          <li>Honda Activa 5G- ₹66,000</li>
          <li>Honda Activa 180 ES- ₹64,600</li>
          <li>Honda Activa 4G- ₹40,500</li>
        </ul>
        <p>Second hand Activa price in Bangalore at affordable price runs through verifications and step-by-step scrutiny by auto experts based on 120+ checkpoints. There are no hidden charges once you procure the used Activa from CredR.</p>
        <br />
        <h4><b>6 months warranty on purchasing Second Hand Activa bike </b></h4>
        <p>Second hand Honda Activa 125 in Bangalore can be easily purchased from CredR, and you will get a 6 months warranty on your purchase. The collection of 2nd hand Activa in Bangalore is refurbished for sustaining quality, and you won’t have to spend extra money once you buy a used bike.</p>
        <br />
        <h4><b>Buy a 2nd hand Activa Scooter. Get easy Home Delivery.</b></h4>
        <p>The used Honda Activa for sale in Bangalore from the second hand showrooms located at Indiranagar, Koramangala, HSR Layout, Bellandur, and Peenya. Once you have booked a Honda Activa second hand in Bangalore, you can do it from your home comfort. It would help if you chose the test ride at Rs. 399 only to check the parameters of the bike and comfortability before you purchase it. Do not hesitate to take the test ride and choose your bike. After purchase, the home delivery will be done to your satisfaction at your convenient time.</p>
        <br />
        <p>The used Honda Activa is a must buy as it is the best mileage scooter available in the market. If you are in two minds, do not overthink; in fact, but it is from CredR now.</p>

      </>
    )

    case 'HERO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Bikes from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Find the best deals on the used Hero bikes available at the CredR website. The price of a Used Hero bike starts from Rs. 25,000 onwards. The brand Hero offers around 14 models of Hero bikes in India, highly user-friendly budget bikes. Hero Splendor Plus, Hero HF Deluxe, Hero Passion Pro, Hero Glamour, Hero XPulse 200, Hero Xtreme 160R, Hero maestro Edge 125, Hero Splendor iSmart, Hero Destini 125, Hero Xtreme 200s, Hero XPulse 200T, Hero Maestro Edge 110. Hero Xtreme 200R, Hero Xtreme 160S, Hero Maestro Electric, and Hero 450 ADV are the upcoming range of Hero Bikes.</p>
        <p>The pre-owned collection of Hero bikes are wide-ranged, with swift performance, good fuel efficiency and a stylish overall outlook. The brand Hero spent years developing, manufacturing and designing industry updated bike models that suit consumers' tastes and preferences. Check out the top collection of used bikes/ Used Hero bikes at CredR based on specifications, colour, price, and features. You can <Link to="/sellbikes" className="font-semibold">sell your hero bike to CredR</Link> or Exchange bikes or buy used bikes from CredR with ease. </p>
      </>
    )
    case 'HERO-GLAMOUR': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Glamor Bikes from CredR</b></h1>
        <p>The Hero Glamour bike was launched in two different variants in India by the Hero MotoCorp. The Hero Glamour bike is powered with a 125 cc engine which has an air-cooled, fuel injection based technology engine which also has a set of 5-speed transmission. Recently receiving its BS6 update, the Hero Glamour bike has one of the best suspension and the best mileage of 57 kmpl. This Hero Glamour bike is popular for its minimalistic maintenance with irresistibly flawless design. The Hero Glamour is available in five different colours and provides customers with the best ability to support.</p>
      </>
    )
    case 'HERO-HF-DELUXE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero HF Delux Bikes from CredR</b></h1>
        <p>The Hero HF Deluxe bike launched in the Indian market was initially made available in 3 different bike variants. The Hero HF Deluxe bike is powered with a 100cc engine with fuel injection system. The Hero HF Deluxe bike is powered with the XSens Technology launched by HeroMotoCorp. The Hero HF Deluxe bike is specifically designed in a lightweight model which is suitable for everyday commute and was also launched as an entry-level bike in India. The Hero HF Deluxe bike is available in 5 colour variants. The Hero HF Deluxe bike received its BS6 update earlier this year.</p>
      </>
    )
    case 'HERO-MAESTRO-EDGE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Maestro Edge scooter from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Hero Maestro Edge scooter in India was initially launched as a fine representation for scooters by HeroMotoCorp. Made available in 4 different variants, the Hero Maestro Edge scooter has a BS6 compliant 124.6 cc engine with a fuel injection based fuel system. The Hero Maestro Edge scooter is powered with a cutting edge technology named XSense. The Hero Maestro Edge scooter also provides the rider with a comfortable seat, stylish design, side stand indicator, Digital and Analog combo meter and much more. Also, the Hero Maestro Edge is available in 8 colours and a fuel economy of 45kmpl.</p>
      </>
    )
    case 'HERO-PASSION': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Passion Bikes from CredR</b></h1>
        <p>The Hero Passion Pro bike is one among the best selling models from HeroMotoCorp. The Hero Passion Pro bike is powered with a 110cc engine with a 4 set gearbox transmission and fuel injection based fuel system. The Hero Passion Pro bike was originally designed to make the everyday commute easier. The Hero Passion Pro bike is available in 4 colours and sharper designs. The Hero Passion Pro has a more comfortable seating style arrangement, excellent suspension in both the rear and front tyres. The Hero Passion Pro bike is said to have a fuel economy level of 60 kmpl.</p>
      </>
    ) 
    case 'HERO-PLEASURE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Pleasure Bikes from CredR</b></h1>
        <p>Hero Pleasure scooter is one of the most iconic scooter models in the Indian market. With the features resembling its companion Hero Maestro Edge, the Hero Pleasure scooter has some special features on its own. The Hero Pleasure scooter is a 102cc single engine scooter which gives customers a fuel economy range of 50 kmpl. The Hero Pleasure scooter had recently received the IBS breaking system and excellent front and rear suspensions. The Hero Pleasure scooter is made available in 10 colours. The Honda Pleasure scooter was specially designed for comfortable riding and extra under the seat space capacity.</p>
      </>
    ) 
    case 'HERO-ACHIEVER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Achiever Bikes from CredR</b></h1>
        <p>The Hero Achiever is yet another bike designed specifically for the comfort of everyday commuters. The Hero Achiever model bike is designed with a minimalist design and style features. The Hero Achiever bike is powered with a 149cc air-cooled engine with a 5 set gearbox. The Hero Achiever bike is specially designed every day, the Hero Achiever bikes have a fuel economy range of 50 kmpl. The Hero Achiever bike also has special features like the i3s smart technology system and excellent suspension. The Hero Achiever bike is also known for its lightweight model and is available in 5 colours.</p>
      </>
    ) 
    case 'HERO-SPLENDOR-PLUS': return (
      <>
        <h1 className="mb-3"><b>Get Premium Quality Second Hand Splendor Plus Bike in india</b></h1>
        <p>Browse wide varieties of Hero Splendor second hand bikes available at the online platform of CredR. All the used Hero Splendor Plus bikes are accessible at an affordable price starting from Rs. 30,000 onwards on this desirable model of Hero. The pre-owned collection of used bikes of Hero Splendor Plus are available in five variants: Splendor Plus Self Alloy BS-VI, Splendor Plus Kick Alloy BS-VI, Splendor Plus Self Alloy i3S BS-VI, Splendor Plus Black and Accent Edition, and Splendor Plus 100 Million Edition.</p>
        <br />
        <h3><b>Explore Affordable Second Hand Splendor Bike Price</b></h3>
        <p>Hero MotoCorp, the largest two-wheeler manufacturer, has successfully manufactured the most desirable Hero Splendor Bike. The collection of the second hand splendor bikes' starting price is Rs.30,000 to Rs, and 55,000 based on the bike's condition. The improved version of the BS6 engine offers 9% more fuel savings and 6% more on pickup. It is the most successful model in the country.</p>
        <br />
        <p>Hero Splendor Plus is one of the highest selling bikes among all Hero Motocorp. It is accessible in unique i3S technology, 100cc carbureted engine, swift performance, and great mileage. Check out the specifications, year, color, and all the details before you buy a used Hero Splendor Plus bike from CredR. Buy a used bike, Sell my motorcycle, and Exchange the bike to CredR on an excellent deal.</p>
        <br />
        <p>Hero splendor second hand bike mileage is 67 kmpl. It is better than its predecessors on various levels. The model has well ditched the classic carburetor and has replaced the efficient and advanced level of Fuel Injection Technology. It is better BS6 compliant to make a practical, user-friendly buying experience.</p>
        <br />
        <p>The Second hand bike splendor plus bike available at CredR has the top quality as the bikes are refurbished. The used bikes undergo checks by auto experts around 120+ levels to meet the quality. You can enjoy the used Splendor bike sale collection online in India at CredR. </p>
        <br />
        <p>The pre-owned Hero Splendor Plus Motorcycle is readily available with all the documents. There is no hassle of documentation when it comes to CredR. The Hero Splendor under 20000 is accessible. Buy a second hand splendor plus bike now by downloading the CredR app. There are numerous The Second Hand Splendor Bike models available as well. The variants of Hero Splendor Bike are:</p>
        <ul>
          <li>Splendor Plus Kick Alloy</li>
          <li>Splendor Plus Self Alloy BS-VI</li>
          <li>Splendor Plus Self Alloy i3S- BS-VI</li>
          <li>Splendor Plus Black and Accent Edition</li>
          <li>Splendor Plus 100 Million Edition</li>
        </ul>
        <p>Colour variants of the Second Hand Splendor Bikes are available too. You can book any of the top used bikes from CredR sitting from your home comfort and enjoy the test ride at Rs. 399 only. The Second Hand Splendor Bike is great in fuel efficiency, easy to handle, and has a wide range of network servicing. </p>
      </>
    )
    case 'HERO-SPLENDOR': return (
      <>
        <h1 className="mb-3"><b>Get Premium Quality Second Hand Hero Splendor Bike in india</b></h1>
        <p>Browse wide varieties of Hero Splendor second hand bikes available at the online platform of CredR. All the used Hero Splendor Plus bikes are accessible at an affordable price starting from Rs. 30,000 onwards on this desirable model of Hero. The pre-owned collection of used bikes of Hero Splendor Plus are available in five variants: Splendor Plus Self Alloy BS-VI, Splendor Plus Kick Alloy BS-VI, Splendor Plus Self Alloy i3S BS-VI, Splendor Plus Black and Accent Edition, and Splendor Plus 100 Million Edition.</p>
        <br />
        <h3><b>Explore Affordable Second Hand Splendor Bike Price</b></h3>
        <p>Hero MotoCorp, the largest two-wheeler manufacturer, has successfully manufactured the most desirable Hero Splendor Bike. The collection of the second hand splendor bikes' starting price is Rs.30,000 to Rs, and 55,000 based on the bike's condition. The improved version of the BS6 engine offers 9% more fuel savings and 6% more on pickup. It is the most successful model in the country.</p>
        <br />
        <p>Hero Splendor Plus is one of the highest selling bikes among all Hero Motocorp. It is accessible in unique i3S technology, 100cc carbureted engine, swift performance, and great mileage. Check out the specifications, year, color, and all the details before you buy a used Hero Splendor Plus bike from CredR. Buy a used bike, Sell my motorcycle, and Exchange the bike to CredR on an excellent deal.</p>
        <br />
        <p>Hero splendor second hand bike mileage is 67 kmpl. It is better than its predecessors on various levels. The model has well ditched the classic carburetor and has replaced the efficient and advanced level of Fuel Injection Technology. It is better BS6 compliant to make a practical, user-friendly buying experience.</p>
        <br />
        <p>The Second hand bike splendor plus bike available at CredR has the top quality as the bikes are refurbished. The used bikes undergo checks by auto experts around 120+ levels to meet the quality. You can enjoy the used Splendor bike sale collection online in India at CredR. </p>
        <br />
        <p>The pre-owned Hero Splendor Plus Motorcycle is readily available with all the documents. There is no hassle of documentation when it comes to CredR. The Hero Splendor under 20000 is accessible. Buy a second hand splendor plus bike now by downloading the CredR app. There are numerous The Second Hand Splendor Bike models available as well. The variants of Hero Splendor Bike are:</p>
        <ul>
          <li>Splendor Plus Kick Alloy</li>
          <li>Splendor Plus Self Alloy BS-VI</li>
          <li>Splendor Plus Self Alloy i3S- BS-VI</li>
          <li>Splendor Plus Black and Accent Edition</li>
          <li>Splendor Plus 100 Million Edition</li>
        </ul>
        <p>Colour variants of the Second Hand Splendor Bikes are available too. You can book any of the top used bikes from CredR sitting from your home comfort and enjoy the test ride at Rs. 399 only. The Second Hand Splendor Bike is great in fuel efficiency, easy to handle, and has a wide range of network servicing. </p>
      </>
    )
    case 'HERO-XTREME': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Xtreme Bikes from CredR</b></h1>
        <p>The Honda Xtreme 200 S bike is powered with a 200 cc air-cooled, single-cylinder engine. This lightweight model was designed for comfortable driving. The Hero Xtreme 200 S bike has a sporty look with a telescopic fork suspension on the front. The bike also comes with a single ABS unit which helps the bike’s brake system when used for long drives. The Hero Xtreme 200 S has a sleekish design, BlueTooth connectivity and other latest technology in the design. The Hero Xtreme 200 S bike has a mileage of 39 kmpl and is available in 3 colours.</p>
      </>
    )
    case 'HERO-DUET': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hero Duet Bikes from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Hero Duet is one among the scooter models launched by HeroMotoCorp. The Hero Duet is powered with a 11cc single-cylinder air-cooled engine. The Hero Duet bike is designed with a metallic body and a broad grab rail for easier support. The bike also has a telescopic suspension on the front and hydraulic damper system on the rear end. The Hero Duet scooter is available in 6 different colours namely red, silver, white, grey, black, matte grey and green. The Hero Duet scooter is admired by customers for its effortless riding style and under the seat storage capacity.</p>
      </>
    )

    case 'HONDA': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Bikes & Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Browse through the assorted collection of Honda bikes that are easily accessible at an affordable price at CredR. All the used Honda bikes start at the cost of Rs. 25,000 onwards. Honda Motorcycle and Scooter India is a subsidiary of the Honda Motor Company in Japan. It started operations in India from 2001 onwards. There are around 18 new models of Honda in India. Honda Activa 6G, Honda Dio, Honda Shine, Honda SP 125, Honda Unicorn, Honda Hornet 2.0, Honda Hness CB350, Honda Activa 125, Honda Livo, Honda Xblade, Honda Grazia, Honda CD 110 Dream, Honda CBR650R, Honda CB350RS, Honda CB650R, Honda CRF1100L Africa Twin, Honda CB500X, and Honda Gold wing.</p>
        <p>Apart from an ideal regular commuter, Honda bikes are known for their innovation, reliability and swift performance. It is the second largest two-wheeler company in India. The brand Honda is a pioneer, offering the largest segment of scooters in India. The brand wishes to make a difference among the customers to build a unique experience while riding a Honda bike daily. At CredR, explore the collection of refurbished bikes. Now, buying a used bike, selling a bike or exchanging a bike is easy and convenient. Explore today!</p>
      </>
    )
    case 'HONDA-AVIATOR': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Hona Aviator Bikes from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Honda Aviator is the next best scooter model from Honda after Honda Dio and Honda Activa. The Honda Aviator scooter is powered with a 109cc air-cooled single-cylinder which runs smoothly with the Honda Eco Technology. The Honda Aviator comes in 3 different variants. The Honda Aviator scooter also has with it the CBS braking system. The Honda Aviator is known for its smooth rides and easy maintenance. The Honda Aviator is available in 4 colours and at different price ranges. Also, the Honda Aviator scooter comes with a comfortable seating style, excellent mileage and good extra spacing system.</p>
      </>
    )
    case 'HONDA-UNICORN': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Unicorn Bikes from CredR</b></h1>
        <p>The Honda Unicorn is a rockstar in the Indian bike market due to its fuel economy range in its category. With no updates in its design, the Honda Unicorn bike is powered with a 162.7cc single-cylinder engine with a 5-speed manual transmission gearbox. The Honda Unicorn bike is available in 3 colours. The Honda Unicorn is expected to have a fuel range of 52 kmpl. Available in 3 different colours the Honda Unicorn bike has been a popular hit from Honda factory. The Honda Unicorn is also said to have a fuel tank capacity of 13 litres.</p>
      </>
    )
    case 'HONDA-DIO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Dio Bikes from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Honda Dio is one of the most trusted and premium scooter models in India. Made available in two variants, the Honda Dio scooter generates a power of 7.65 bhp with a 109.51 cc engine. The Honda Dio scooter has an automatic gearbox, drum brakes on the front and rear end. The Honda Dio scooter is available in 7 colours and has also added the premium version of the scooter variant. The Honda Dio comes with a digitally equipped display and high fuel efficiency. The Honda Dio has received the BS6 update and also has excellent braking support.</p>
      </>
    )
    case 'HONDA-DREAM': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Dream Bikes from CredR</b></h1>
        <p>The Honda Dream bike is one of the most affordable motorcycles by Honda Motors. Honda Dream is powered with a 110cc single-cylinder engine with HET technology. The Honda Dream bike is specially designed with an extra-long seat, electric self-start and tubeless tyres. The Honda Dream bike has a four-set transmission gearbox and a fuel efficiency level of 74kmpl. The Honda Dream bike is available in different four colours Athletic Blue Metallic, Black with Cabin Gold, GenY Grey Metallic and Imperial Red Metallic. The Honda Dream bike has telescopic forks upfront and dual shock absorber at the back.</p>
      </>
    )
    case 'HONDA-ACTIVA': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Activa Scooters from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Honda Activa scooter is the forefather of all the upgraded Honda Activa scooters available in the present-day Indian two-wheeler market. With 4 different models in the market, The Honda Activa scooter is the most comfortable in the market. The Honda Activa scooter has extra-long seats compared to any other scooter versions in India. With a 110cc engine, The Honda Activa scooter does not require heavy maintenance. All the variants from the Honda Activa scooter models have a mileage of 50 kmpl and simply LED headlamps, extra footer space, fuel tank capacity and under storage facility.</p>
      </>
    )
    case 'HONDA-LIVO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Livo Scooter from CredR</b></h1>
        <p>The Honda Livo is an efficient bike from Honda. The Honda Livo bike generates a power of 8.87 bhp with the combination of 109cc single-cylinder air-cooled engine. The Honda Livo also has a 4 step gearbox with a CBS system fixed to both the wheels. The Honda Livo bike comes in 5 different vibrant colours namely blue, black, brown, grey and red. The bike also has a telescopic suspension on the front and a hydraulic suspension system on the rear. The Honda Livo bike is also known for its easy maintenance, fuel tank capacity and mileage efficiency.</p>
      </>
    )
    case 'HONDA-NAVI': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Navi Bike from CredR</b></h1>
        <p>The Honda Navi is a new age two-wheeler which is a mixed combination of both scooter and bike. Being a new age performance model from Honda, Honda Navi fits its style and design. The Honda Navi is specifically designed for college going students and young working people. The Honda Navi is powered with a 110cc engine. The drum braking system in the bike is equipped with a combi-brake system which improves the braking system of the bike. The Honda Navi bike has a fuel tank capacity of 3.8 litres and is available in 6 trendy new colours.</p>
      </>
    )
    case 'HONDA-CLIQ': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda CLIQ Scooter from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Honda CLIQ is the one among the most affordable automatic scooters available in the Indian market. The Honda CLIQ scooter gets a faux carbon finish and a motorcycle handlebar from Honda's motorcycle range. The Honda CLIQ also gets a wide footboard space, 14 litres under-seat storage capacity and a USB charging port all in one. The Honda CLIQ also gets a special tyre pattern which makes riding on roads easier. The Honda CLIQ is powered with a 109.19 cc air-cooled engine and is lesser in weight than Honda Activa 4g and most scooters in the market.</p>
      </>
    )
    case 'HONDA-ACTIVA-5G': return  (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Honda Activa 5G Scooter from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Evolved over the decades, Honda Activa 5G is the fastest-selling model scooter in the Indian market. Honda has designed the new 5G version with improvements in many aspects. The Honda Activa 5G has 109 cc engine which generates a power of 8 bhp. The Honda Activa 5G scooter is designed to give the rider lightness while riding the bike. The Honda Activa 5G scooter also received a smart look seat style and exclusive updates like chrome exhaust and black-out engine. The Honda Activa 5G engine has a CVT transmission, drum brakes and is available in 8 different paint colours.</p>
      </>
    )

    case 'YAMAHA': return (
      <>
         <h1 className="mb-3"><b>Buy & Exchange Second Hand Yamaha Bikes & Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Browse through the assorted collection of the used bikes/ used Yamaha Scooters at CredR and book your favourite one today! Yamaha motorcycles are a famous Japan-based company that began its operation around 1985 as a combined venture and became a complete subsidiary in 2001. All the used bikes of Yamaha start from Rs. 25,000 onwards. There are nine new bike models and 12 new upcoming models offered by Yamaha. The most popular bikes are Yamaha FS-X, Yamaha YZF R15 V3, Yamaha MT-15, Yamaha FZS- FI V3, Yamaha FZ- FI Version 3.0, Yamaha Fascino 125, Yamaha RayZR 125, Yamaha FZ 25, Yamaha FZS 25. The upcoming Yamaha bikes are Yamaha 2021 MT-09, Yamaha MT- 07, YamahaNmax 155, Yamaha XSR 250 and Yamaha 2020 MT 03. </p>
        <br />
        <p>The futuristic manufacturing plans of Yamaha to upgrade the bikes offer a magnificent bike riding experience to the riders that is highly appreciated. The bikes are popular due to reliability, comfortability, visual attractiveness and swift performance to ride for long distances. At a reasonable price, a sporty and well-featured bike is presented by Yamaha. Check out the pre-owned collection of Yamaha bikes based on specifications, year, colour, model and other details before you purchase it for yourself. Buying a used bike, selling your Yamaha bike to CredR and even <Link to="/sell-your-bike-buy-new-bike" className="font-semibold">exchanging Yamaha bikes</Link> are made easy and effective in India. CredR is the one-stop solution for all.</p>
      </>
    )
    case 'YAMAHA-RAY': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Yamaha Ray Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Yamaha Ray ZR 125 is a scooter available in 3 variants in India. The Yamaha Ray ZR 125 is powered with a 125cc single-cylinder engine which generates 8.04 bhp. The Yamaha Ray ZR 125 scooter has an automatic gear transmission with drum brakes support. The Yamaha Ray ZR 125 scooter weighs around 99 kg giving a right balance for the rider. The Yamaha Ray ZR 125 scooter is available in 7 colours. The Yamaha Ray ZR 125 scooter gets an exemplary comfortable seat, 21 litres under-seat storage and a BS6 compliant engine in it's the latest update.</p>
      </>
    )
    case 'YAMAHA-FAZER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Yamaha FAZER Bike from CredR</b></h1>
        <p>The Yamaha Fazer is one of the high-end bikes launched at a very reasonable price ever launched by Yamaha. The Yamaha Fazer bike was launched in the year 2009. The Yamaha Fazer is powered with a 153cc air-cooled engine which generates a power of 13.8 bhp. The Yamaha Fazer bike has a specially designed seat to support long rides. The Yamaha Fazer bike has a muscular look with twin headlamp style making it look apart from the other Yamaha bikes launched. The Yamaha Fazer bike was made available in three colours Ravine Red, Wilderness Black and Terrain White.</p>
      </>
    )
    case 'YAMAHA-FZ': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Yamaha FZ Bike from CredR</b></h1>
        <p>The Yamaha brand launched a new version of sporting bike range named Yamaha FZ in 2008. The model’s goal was to design a regular bike with added sporty features. This Yamaha FZ bike is powered by a 149 cc air-cooled engine with a five-speed transmission gearbox. The front gets brakes, a conventional telescopic suspension and a mono-shock suspension at the rear side. Stopping power is courtesy a disc brake in the front and drum brake at the rear. The Yamaha FZ has a mileage of about 40 kmpl. The new Yamaha FZ promised better performance and fuel efficiency.</p>
      </>
    )
    case 'YAMAHA-ALPHA': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Yamaha Alpha Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Yamaha Alpha scooter is one of the finest scooters launched by the Yamaha Motors in India. The Yamaha Alpha is powered by a four-stroke air-cooled 113cc engine with automatic gear transmission. The Yamaha Alpha scooter is said to have produced a mileage of 60 kmpl making it one of the most fuel economy scooters. The Yamaha Alpha has a 21 litre under the seat storage along with a simply styled seat. The Yamaha Alpha scooter is available in two different variants: the drum and the disc variant. The Yamaha Alpha is available in the market at 11 colours.</p>
      </>
    )
    case 'YAMAHA-FASCINO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Yamaha Fascino Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Yamaha Fascino scooter is powered with an air-cooled, fuel-injected 125cc blue engine technology which comes which is BS-VI compliant. The Yamaha Fascino is also said to use a special “smart motor-generator” which helps the engine with a smooth start and on-road performance. With yet another light version, the scooter weighs about 99 kgs making the scooter, even more, smoother on the streets. Also, the scooter has telescopic suspension and 21-litre under-seat storage. The Yamaha Fascino scooter is available in 7 different colours and has a mileage of 58 kmpl.</p>
      </>
    )
    case 'YAMAHA-YZF': return (
      <>
        <h1 className="mb-3"><b>Buy a used Yamaha YZF bike from CredR</b></h1>
        <p>Browse through a range of splendid used Yamaha YZF bikes that come at a starting price of Rs. 45,000. The Yamaha YZF bikes offer a claimed mileage of 40 kmpl-55.2 kmpl. Among other variants of Yamaha YZF, the Yamaha R15 V4 stands out for its fuel efficiency offering a 55.2 kmpl mileage. With its aggressive design, cutting-edge technology, and exceptional performance, the YZF remains a top contender in the realm of sports bikes. Some of the YZF bikes like YZF R1 are equipped with an advanced fuel injection system and Yamaha’s special Crossplane Crankshaft technology. Adhering to Yamaha’s racing DNA, the Yamaha YZF lineup offers incredible riding experience to riders who wish to break performance and speed barriers.</p>
        <br/>
        <p>Riders who wish to experience the adrenaline rushing through their veins can check Yamaha YZF’s models on CredR’s website. Available at a price of Rs. 45,000 onwards, these YZF comes in popular variants like Yamaha R15 V4 and Yamaha R15S. Buy a Yamaha YZF sportbike today at a reasonable from CredR’s website. Enjoy hassle-free buying and 6 months warranty.</p>
      </>
    )
    case 'YAMAHA-R15': return (
      <>
      <h1 className="mb-3"><b>Buy Second Hand Yamaha R15 Bikes from CredR</b></h1>
        <p>Incorporated with the advantages of Yamaha's bike racing technology, this model of Yamaha R15 has caught everyone's attention. It is a refined version of all the Yamaha bikes, and this Yamaha R15 has added benefits that give a racing track feel to every ride. While Yamaha never fails at delivering the best experience to its customers, We at CredR provide the best second hand Yamaha R15 bikes at outstanding quality and price.</p>
        <br />
        <p>The Yamaha R15 model, which has been youth's favorite, has added many important features like a liquid-cooled engine, attractive body, and an advanced gear function. We at CredR offer second hand Yamaha R15 bikes at an affordable price. These second hand Yamaha R15 models are now available in our showrooms with new attractive variant options. These second hand bikes are also available in diverse color options for a customer-friendly buy. </p>
        <br />
        <p>All the Yamaha R15 second hand bikes found in our multiple showrooms have gone through rigorous quality checks, enabling the user to make the right choice while choosing their bikes. All the second hand Yamaha bikes available with CredR come with a 6 months warranty period and a 7 days buy protect plan.</p>
        <br />
        <h4><b>Why should you Buy Used Yamaha R15 Sportbike?</b></h4>
        <p>There are ample reasons to offer when you choose to buy a used Yamaha bike. A few of the top reasons are listed below;</p>
        <br />
        <ul>
          <li><b>Genuine worth of your money: </b></li>
        </ul>
        <p>The bike Yamaha R15 is priced based on its performance. It is a perfect bike for college-goers; it is priced higher for a 150cc motorcycle, and performance can render that argument moot. When the bike is compared with the other 200cc bikes, the technology update is way too impressive and effective on the road. The features of the R15 are well-equipped with the ABS dual-channel for additional rider safety. It is known for its excellent build quality, and it ensures the material of the bike is of high quality in all possible ways. An additional 2.8HP power is being provided for a significant increase in engine displacement and engine in terms of updated technology. The bike performs well and leaves the riders in awe at a low speed.</p>
        <br />
        <ul>
          <li><b>Powerful engine </b></li>
        </ul>
        <p>The bike's engine wasn't much developed for the last two generations. The story has been drastically changed over the years. The introduction of the Variable valve Actuation will help the engine to breathe better at much higher rpm. It has ensured that the performance is not lost with the higher revs. The inclusion of the VVA system kicks in at a 7400 rpm mark that provides the loss in torque which is not that high. The injection feeds fuel into the pent roof design combustion chamber. The crankcase has been modified to reduce the pumping losses. The slipper clutch ensures the rear wheel hops around when the rider downshifts aggressively.</p>
        <br />
        <ul>
          <li><b>Excellent Handling </b></li>
        </ul>
        <p>The seasoned riders would prefer the V1 over the other generation of Yamaha bikes; this Yamaha R15 offers a special connection with the riders and is almost as if reading the rider's mind. This bike has made it convenient for riders to glide through the traffic with innovative handling features. The wheelbase and size of the tires are increased with the traded lightness of V1 for more power and confidence shown in V2. With the perfect bike experience on these second hand Yamaha bikes, you'll have a different level of confidence on the street. ABS features have come up with these R15 bike updated models. </p>
        <br />
        <ul>
          <li><b>Suitable for commuting inside city and highways</b></li>
        </ul>
        <p>The riders' overall experience on the used Yamaha R15 bike is on the higher side. It can pull from idle crisply without any gambling. The power to deliver the higher revs is smooth and linear as well. It is easy to shift to the 5th gear and go 30 kmph where the bike won't object to it. Lower rpm levels offer higher responsiveness. For the shorter equipment, the engine is smooth at a triple-digit speed. The riding position is very aggressive and sporty. This bike is perfect for riding on road trips. </p>
        <br />
        <ul>
          <li><b>Premium Features & Race Spec Accessories </b></li>
        </ul>
        <br />
        <p>The used bike, Yamaha R15, is well featured with an aerodynamic LED taillight and headlight. It has a thoughtful design, and it assists the bike with better and overall airflow and efficient lighting. The hazard lamp is now available on the bike. The inclusion of the instrument console on the bike is entirely a digital unit, and it has a gear shift timing light that ensures the rider a reminder to shift the gear for optimal performance. For tracking purposes, the inclusion of official accessories like frame slender, skid plate, Metzeler rear type, and Daytona Muffler is now available for everyday usage. The use of a USB charging dock is included as well.</p>
        <br />
        <p>Buying a used Yamaha R15 will benefit you in many ways, as mentioned above. With CredR, the buying experience of used bikes or second hand Yamaha bikes is diverse. Get the additional benefits of buying used bikes at an affordable price.</p>
      </>
    )

    case 'SUZUKI': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Bikes & Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Suzuki Motor Corporation is yet another two wheeler brand emerging from Japan. Once started as a merged venture between TVS, Suzuki Motor Corporation now is a big player among the Indian motorcycle brands. Led by the Company Head Mr. Koichiro Hirao, Suzuki Motor Corporation has now come a long way of having about 3000+ employees dedicated to create the best for our customers.Suzuki Motor Corporation in India was initially started with cars along with Maruti where the superstar of cars Maruti Suzuki was born. Now, Suzuki have started their own manufacturing in India at their new plant in Gurgaon. Also Suzuki’s empire is now active in 20+ countries across the globe.Some of the popular motorbike and scooter brands in India include Suzuki’s Gixxer, Access and Gixxer SF in India.</p>
      </>
    )
    case 'SUZUKI-ACCESS': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Access 125 Scooters from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The awesome Suzuki Access 125 scooter is powered by a 124cc air-cooled single-cylinder engine with a fully functioning automatic gearbox. The Suzuki Access 125 scooter is one of the lightweight scooter models. The Suzuki Access 125 scooter weighs around 104 kg. The scooter has drum brakes both in the front and back positions and other features like a longer seat, fuel filler cap on the outside, and a USB charging point. This model is also known for its extravagant storage capacity and a powerful LED headlight. The Suzuki Access 125 scooter model is available in the market in 5 colours.</p>
      </>
    )
    case 'SUZUKI-ACCESS-125': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Access 125 Scooters from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The awesome Suzuki Access 125 scooter is powered by a 124cc air-cooled single-cylinder engine with a fully functioning automatic gearbox. The Suzuki Access 125 scooter is one of the lightweight scooter models. The Suzuki Access 125 scooter weighs around 104 kg. The scooter has drum brakes both in the front and back positions and other features like a longer seat, fuel filler cap on the outside, and a USB charging point. This model is also known for its extravagant storage capacity and a powerful LED headlight. The Suzuki Access 125 scooter model is available in the market in 5 colours.</p>
      </>
    )
    case 'SUZUKI-HAYATE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Hayate Bikes from CredR</b></h1>
        <p>The Suzuki Hayate was launched basically to appeal to the daily commuters. The Suzuki Hayate is powered by a 112cc air-cooled single-cylinder engine which produces a power of about 8.3 bhp. The Suzuki Hayate bike is also said to feature a fuel economy range of 50 kmpl along with comfortable seating. Also, the Suzuki Hayate is available in 5 colours variants. The Suzuki Hayate has a tubular frame chassis with a telescopic suspension at the front and a coil springs at the rear. It gets a fuel tank capacity of 8.5 litres and weighs around 114kg.</p>
      </>
    )
    case 'SUZUKI-INTRUDER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Intruder Bikes from CredR</b></h1>
        <p>The Suzuki Intruder is a 155cc modern cruiser which shares a similar engine with the Suzuki Gixxer. The Suzuki Intruder is powered by a 155cc single-cylinder air-cooled engine. The Suzuki Intruder recently got its BS6 update. The Suzuki Intruder generates a power of around 13.4 bhp and has an excellent on-road performance. The Suzuki Intruder has a wide and lower seat which makes it have a cruiser feel. The Suzuki Intruder bike has a special sporty look with extra LED lights. Also, the Suzuki Intruder has three colour schemes silver, black - red and black - silver combination.</p>
      </>
    )
    case 'SUZUKI-GIXXER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Suzuki Gixxer Bikes from CredR</b></h1>
        <p>The Gixxer is a premium motorcycle model from Suzuki. The Suzuki Gixxer is powered by a 155cc fuel-injected single-cylinder engine with a BS6 compliant version. The Suzuki Gixxer bike had a 5 set transmission which makes the bike to produce a power of 13.4bhp which makes the bike to have a better on-road performance. The Suzuki Gixxer bike has telescopic forks upfront and a mono-shock fork at the rear end. The Suzuki Gixxer comes in three different colour schemes. The mileage of the bike is around 47 kmpl and it has a tank capacity of 12L.</p>
      </>
    )
    case 'SUZUKI-V-STORM': return (
      <>
        <h1 className="mb-3"><b>Explore the Suzuki V-Strom variants at CredR</b></h1>
        <p>Navigate through CredR’s wide collection of used Suzuki V-Strom bikes, perfect for adventure-seekers and daily commuters. Known for their unique adventure-inspired appearance and comfortable ergonomics, the Suzuki V-Strom bikes offer a hassle-free riding experience. The V-Strom lineup is also known for complying with emission standards. With a seat that is positioned just above the ground, the motorcycle allows riders to easily get up or down. The Suzuki V-Strom is worth your money as it comes with a plethora of features that alloy you to go on an off-road ride.</p>
        <br />
        <p>Buy one of the Suzuki V-Strom variants from CredR that comes with sporty aesthetics suitable to handle curves across paved roads.  With its closely placed handlebars, the V-Strom series can handle unpaved roads perfectly. CredR simplifies the selling and exchange process while ensuring your payments are safe and transparent. Browse through different V-Strom models like V-Strom 800 DE, SX 250, and 650XT to find out their specs and price.</p>
      </>
    )
   
    case 'TVS': return (
      <>
        <h1 className="mb-3"><b>Buy & Exchange Second Hand TVS Bikes & Scooters From CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>TVS is a Indian origin company which was a dream made into reality by the founding father TV Sundaram Iyengar. Hailing from a small town in Tamil Nadu India, Sundaram Iyengar was responsible for creating the roots of the present day’s motorcycle giant. Currently operating manufacturing plants in Tamil Nadu, Karnataka and Himachal Pradesh. Iconic scooter models from TVS include TVS Ntorq, TVS Jupiter and TVS Scooty pep. TVS Apache RTR, TVS Star city and TVS Sport are some of the most loved motorcycle brands in India. Also TVS brand in India is mainly loved by its customers for its exceptional moped TVS XL Super and TVS XL Heavy Duty which has been backed by customers in all corners of the Nation. TVS is also involved in motorsport races and is among one of the best teams to participate internationally.</p>
      </>
    )
    case 'TVS-JUPITER': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Jupiter Scooters From CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The TVS Jupiter 109cc BS-VI version is also a 4 stroke engine with the latest CVTi technology and a fuel injection system. This new fuel injection enables the stability of the engine with great mileage and on-road performance. The total mileage of TVS Jupiter is around 50 kmpl. The scooter having a comfortable riding style and the wide seat is also said to have a fuel tank capacity level of around 5.3 litres. The TVS Jupiter BS-VI version scooter comes in colours including Titanium Grey, Walnut Brown, Matte Silver, Matte Blue, Volcano Red, Midnight Black, Mystic Gold, and Pristine White.</p>
      </>
    )
    case 'TVS-SPORT': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Sports bikes From CredR</b></h1>
        <p>The TVS model bikes are best known for their fuel efficiency. The TVS Sport model bike is also one among them. Launched in 2 different variants, the TVS Sport bike is powered with a 109 cc single-cylinder air-cooled engine with both kick and self start option. The TVS Sport bike is available in the market in 6 different colours. The TVS Sport bike has a mileage of 75 kmpl. Also, the bike has a fuel tank storage of 10 litres. Compared to previous models from TVS the TVS Sport bike is highly loved for its fuel economy range.</p>
      </>
    )
    case 'TVS-SCOOTY': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Scooty From CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>TVS Scooty Pep Plus is among the famous scooters ever featured in the Indian market. Launched under 2 variants models the TVS Scooty Pep Plus is equipped with a single-cylinder 87.8cc engine with an automatic transmission gearbox. The TVS Scooter Pep Plus has drum brakes on the wheels. The TVS Scooty Pep Plus scooter weighs about 93 kg and also comes in 7 colour combinations. The TVS Scooty Pep Plus is suspended by a telescopic fork and mono-shock absorber with Synchronized Braking Technology. The scooter has a mileage of 65 kmpl and a fuel tank capacity of 5L.</p>
      </>
    )
    case 'TVS-STAR-CITY': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Star City Bikes From CredR</b></h1>
        <p>The TVS Star City Plus is one of the best-rated bikes launched in India. The TVS Star City Plus bike is available in two variants and comes in 5 colours. The TVS Star City Plus is equipped with a 109.7cc single-cylinder engine and a 4-speed transmission manual gearbox. The bike has a minimalist yet efficient design. The TVS Star City Plus bike has a combined braking system, LED headlamp, a USB charger, adjustable shock absorber and a sporty body design which makes riding easier. The bike is also said to have a mileage of 80 kmpl.</p>
      </>
    )
    case 'TVS-WEGO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Wego Scooters From CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The TVS Wego is a 110cc scooter with a simple style and a unique yet comfortable design. The TVS Wego scooter is powered with a 109cc single-cylinder scooter which generates a power of 8bhp. The TVS Wego scooter has drum brakes for both the wheels and a larger under-seat storage capacity. The TVS Wego scooter weighs about 108 kg and has a mileage of about 70 kmpl. The TVS Wego scooter is available in 8 different colour variants - midnight black, sporty white dual blue, sporty dual white deep sky blue, mercury grey, orange black, metallic orange and volcano red.</p>
      </>
    )
    case 'TVS-NTORQ': return (
      <>
        <h1 className="mb-3"><b>Buy a Used TVS Ntorq Scooter From CredR Today {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Explore an array of TVS Ntorq scooters that are known for their sporty designs and innovative features. While the angular and aggressive design helps it stand out from the traditionally styled two-wheelers, the vibrant graphics and colors appeal to young riders. The scooter continues to allure riders with its digital instrument cluster and SmartXonnect system. Riders looking for a fun yet practical ride can rely on its 124.8cc engine paired with a CVTi-REVV gearbox. The price of used Ntorq bikes starts from Rs.40,000 onwards.</p>
        <br/>
        <p>CredR allows you to browse through used Ntorq variants loaded with excellent braking capabilities, race-inspired graphics, sharp LED headlights, and other high-end features. Choose from TVS Ntorq variants like TVS Ntorq 125 Drum, TVS Ntorqu 125 Disc, TVS Ntorq 125 Race Edition, TVS Ntorq 125 EVO, TVS Ntorq 125 Race XP, and TVS Ntorq 125 Super Squad Edition.</p>
      </>
    )
    case 'TVS-APACHE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Apache Bikes From CredR</b></h1>
        <p>TVS Apache RTR 160 bike is available in 2 variants in India. The TVS Apache RTR 160 is powered with a 159.7cc single-cylinder engine which generates a power of 15.1 bhp. The TVS Apache RTR 160 also has a 5-speed manual gearbox and comes with a unique anti-locking braking system. Recently the TVS Apache RTR 160 got its BS6 update and is now made available in 6 different colour options gloss red, matte red, black, white, grey and blue. The TVS Apache RTR 160 weighs about 139 kg and has a mileage of 50 kmpl.</p>
      </>
    )
    case 'TVS-XL': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS XL Bikes From CredR</b></h1>
        <p>The TVS XL 100 Heavy Duty is among the most reliable vehicles of India. The TVS XL 100 Heavy Duty is powered with a 99.7cc single-engine and an automatic gearbox. The TVS XL 100 Heavy Duty generates a power of 4.2 bhp and weighs about 89 kg. Available in 2 variants and colours, the TVS XL 100 Heavy Duty comes with drum brakes on both the front and the rear end. The TVS XL 100 Heavy Duty is known to carry a payload of about 130 kg making it one among the most efficient bikes in India.</p>
      </>
    )
    case 'TVS-VICTOR': return (
      <>
        <h1 className="mb-3"><b>Explore second-hand TVS Victor bikes at CredR</b></h1>
        <p>The name ‘TVS Victor’ is synonymous with high-quality and top-notch performance. Introduced in 2001, TVS Victor is revered for its athletic and aggressive design aesthetics even today. The popularity of TVS Victor isn’t limited to India, it is also appreciated in Europe and the United States. Flaunting modern aesthetics, the bike comes with useful features like rear and front lighting and an integrated speedometer. An LCD allows riders to keep an eye on their mileage and speed.</p>
        <br />
        <p>Explore CredR’s collection of second-hand TVS Victor bikes and find something that is economical yet suitable for your daily commute. The price of TVS Victor bikes starts from Rs.30,000, which makes them a must-have for riders looking for a budget-friendly option and better mileage. At CredR, buyers can experience hassle-free buying and reap the benefits of easy financing options and buyback policies. Consider models like Victor Drum, Victor Premium Matte, Victor Disc, and Victor Premium available on CredR’s website.</p>
      </>
    )

    case 'MAHINDRA': return (
      <>
        <h1 className="mb-3"><b>Buy & Exchange Second Hand Mahindra Bikes & Scooters From CredR</b></h1>
        <p>The Mahindra Two Wheelers Limited (MTWL) is a billion dollar company belonging to the renowned Mahindra Group. The company offers two-wheelers like bikes and scooters with high quality build, sleekish style and an elevated mileage level. Founded in the year 2008, the company has established its own development center in Maharashtra and Madhya Pradesh. Mahindra has expanded its services almost in 17 countries now across Asia, Africa, Middle East and Central America. The company has continued to design bikes and scooters by incorporating the needs of the customers. Some of the models from Mahindra include Gusto 125, Gusto 110, Centuro NXT, Centuro Rockstar etc.</p>
      </>
    )
    case 'MAHINDRA-CENTURO': return  (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Mahindra Centuro Bikes From CredR</b></h1>
        <p>The Mahindra Centuro scooter is powered by a 106.7cc single-cylinder engine which generates an 8.4bhp engine. The Centuro scooter weighs about 111kg and has a fuel tank capacity of 12.7l. The Mahindra Centuro comes in 5 different versions and is also featured as one of the most comfortable scooters available in the market. The Mahindra Centuro scooter has a simple design style with a twin bar system design and a comfortable seat. Apart from giving a mileage of 80 kmpl the Mahindra scooter is also proven to have a smooth on-road performance with a reasonable budget.</p>
      </>
    )
      
    case 'PIAGGIO': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Piaggio Scooters from CredR {vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>The Piaggio Group in India is an establishment of an Italian Motorcycle manufacturing giant which produces and markets scooters like Aprilia and Vespa in India. With the corporate office in Italy, the bikes are sold across the country by the franchise model. Piaggio is a motorcycle brand which is spread across 54 countries. Founded by Rinaldo Piaggio who came from a family of locomotive manufacturers, the brand is quite popular across the globe. Piaggio, in the year 2016 launched its star performers in India at an auto expo. Initially marketed along with Bajaj the company now sells bikes under its own name. Vespa is an iconic hit from Piaggio, as it was one among the pioneers in the field of scooter production. Aprilia on the other hand was acquired by Piaggio and then launched across the globe. Vespa is more of a simple elegant look but Aprilia is simply sporty. Paggio now manufactures both Aprilia, Vespa and Moto Guzzi from its own plant in Maharashtra.</p>
      </>
    )
    case 'APRILIA': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Aprilla Bikes & Scooters from CredR{vehicle && vehicle === "scooters" ? ' | Scooters CredR' : ''}</b></h1>
        <p>Browse the wide variety of assorted Aprilia bikes/ used Aprilia bikes available online for sale in India at CredR. There are 9 bike models offered by Aprilia. Aprilia RS 457, Aprilia SR 125, Aprilia RS 660, Aprilia SXR 125, Aprilia Storm 125, Aprilia SR 160, Aprilia SXR 160, Aprilia RSV4 1100 Factor, and Aprilia Tuono 660.</p>
        <br />
        <p>The upcoming models of Aprilia are Aprilia Tuareg 660 and Aprilia Tuono 457.</p>
        <br />
        <p>Aprilia has travelled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Aprilia is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>
        
      </>
    )
    case 'HERO-HONDA': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel, vehicle)}</b></h1>
        <p>Browse the wide variety of assorted Hero Honda bikes/ used Hero Honda bikes available online for sale in India at CredR. The used bikes of Hero Honda start at a price of Rs. 20,000 onwards based on specifications, colour, year, and model details. The popular models offered by Hero Honda are the Pleasure Std, Cd Delux, Hunk Limited, Splendor Plus, Karizma R, Splendor Pro, Hunk Std, Passion Plus, Cbz-xtreme Std, Karizma ZMR, Passion Pro, Splendor Super, Super Splendor Regular, Cd Delux, and Cbz-xtreme Dss.</p>
        <br />
        <p>Now touted as the Hero MotoCorp, Hero Honda has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Hero MotoCorp is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'ATHER': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel, vehicle)}</b></h1>
        <p>Browse the wide variety of assorted Ather / used Ather scooters available online for sale in India at CredR. The used scooters of Ather start at a price of Rs 1,15,000  onwards based on specifications, colour, year, and model details. There are 4 scooter models offered by Ather. Ather Rizta, Ather 450X, Ather 450S, and Ather 450 Apex are currently offered by Ather.</p>
        <br />
        <p>Ather has travelled a long way in offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Ather is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'LML': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted LML bikes/ used LML bikes available online for sale in India at CredR. There are 2 bike models offered by LML. LML Freedom DX and LML Freedom LS. The upcoming models of LML are LML Orion, LML Moonshot, and LML Star.</p>
        <br />
        <p>LML has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. LML is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'UM-MOTORCYCLES': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand UM Bikes & Scooters from CredR</b></h1>
        <p>Browse the wide variety of assorted UM bikes/ used UM bikes available online for sale in India at CredR. The used bikes of UM start at a price of Rs.80,000 onwards based on specifications, colour, year, and model details. There are 3 bike models offered by UM. UM Renegade Sports S, UM Renegade Commando, and UM Renegade Mojave.</p>
        <br />
        <p>The upcoming model of UM is the UM DSR Adventure 200.</p>
        <br />
        <p>UM has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. UM is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'KAWASAKI': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Kawasaki bikes/ used Kawasaki bikes available online for sale in India at CredR. The used bikes of Kawasaki start at a price of Rs. 1,25,000 onwards based on specifications, colour, year, and model details. There are 29 bike models offered by Kawasaki. Kawasaki Z650, Ninja ZX-4R, Versys 650, Ninja 500, Ninja 650, W175, Eliminator, Z H2, KX 85, KLX 300R, Z650RS, KLX110R L, KLX 140R F, KLX230RS, Ninja H2 SX SE, Z900RS, KX112, KX65, Vulcan S, KX250, KLX450R, KX450,and Kawasaki Ninja H2 SX.</p>
        <br />
        <p>The upcoming models of Kawasaki are Kawasaki Z500 and Kawasaki Z400.</p>
        <br />
        <p>Kawasaki has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Kawasaki is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'TRIUMPH': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Triumph bikes/ used Triumph bikes available online for sale in India at CredR. The used bikes of Triumph start at a price of Rs. 2,50,000 onwards based on specifications, colour, year, and model details. There are 19 bike models offered by Triumph. Triumph Speed 400, Scrambler 400 X, Trident 660, Street Triple R, Rocket 3, Tiger 900, Speed Twin 900, Tiger Sport 660, SpeedTwin, Bonneville T100, Speed Triple 1200 RS, Tiger850Sport, Scrambler 1200, Bonneville Speedmaster, Bonneville Bobber, Bonneville T120, Scrambler 900, and Triumph Tiger 1200.</p>
        <br />
        <p>The upcoming models of Triumph are Triumph Daytona 660, Triumph Thruxton 400, and Triumph TE-1.</p>
        <br />
        <p>Triumph has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Triumph is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'BMW': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand BMW Bikes & Scooters from CredR</b></h1>
        <p>Browse the wide variety of assorted BMW bikes/ used BMW bikes available online for sale in India at CredR. The used bikes of BMWj start at a price of Rs. 1,90,000 onwards based on specifications, colour, year, and model details. There are 18 bike models offered by BMW. Some of the most popular ones are the BMW G 310 GS, G 310R, S 1000 RR, R 1250 GS Adventure, F850 GS, R 1250 RT, S 1000 R, and BMW R18.</p>
        <br />
        <p>The upcoming models of BMW are the BMW R 1300 GS and BMW R 18 Transcontinental.</p>
        <br />
        <p>BMW has travelled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. BMW is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'HUSQVARNA': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Husqvarna bikes/ used Husqvarna bikes available online for sale in India at CredR. The used bikes Husqvarna start at a price of Rs. 1,75,000 onwards based on specifications, colour, year, and model details. There are 4 bike models offered by Husqvarna. Husqvarna Vitpilen 250, Vitpilen 250 [2024], Svartpilen 401, and Svartpilen 250.</p>
        <br />
        <p>The upcoming models of Husqvarna Vitpilen 401, Husqvarna Vektorr Concept, Husqvarna Svartpilen 201, Husqvarna Vitpilen 125, and Husqvarna Svartpilen 125.</p>
        <br />
        <p>Husqvarna has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Husqvarna is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'BENELLI': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Benelli bikes/ used Benelli bikes available online for sale in India at CredR. The used bikes of Benelli start at a price of Rs.1,00,000  onwards based on specifications, colour, year, and model details. There are 6 bike models offered by Benelli. Some of the most popular ones are the Benelli TRK 502, Benelli Imperiale 400, Benelli Leoncino 500, Benelli TRK 502X, Benelli TRK 251, and Benelli 502C.</p>
        <br />
        <p>The upcoming models of Benelli are the Benelli TNT 600, Benelli Leoncino 800, Benelli 752 S, Benelli TNT 300, Benelli 302R, and Benelli 302 S.</p>
        <br />
        <p>Benelli has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Benelli is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'HARLEY-DAVIDSON': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Harley Davidson/ used Harley Davidson bikes available online for sale in India at CredR. The used bikes of Harley Davidson start at a price of Rs. 2,10,000 onwards based on specifications, colour, year, and model details. There are 9 bike models offered by  Harley Davidson. Harley-Davidson X440, Sportster S, Nightster, Fat Bob, PAN America 1250, Fat Boy, Road Glide Special, Street Glide Special and Heritage Classic.</p>
        <br />
        <p>The upcoming models of Harley Davidson are Harley Davidson Nightster 440, Harley Davidson X 350, Harley Davidson LiveWire S2 Del Mar, Harley Davidson X 500, Harley Davidson Sportster 500, and Harley Davidson Low Rider S.</p>
        <br />
        <p>Harley Davidson has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Harley Davidson is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'HYOSUNG': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Hyosung bikes/ used Hyosung bikes available online for sale in India at CredR. The used bikes of Hyosung start at a price of Rs. 1,60,000 onwards based on specifications, colour, year, and model details. There are 7 bike models offered by Hyosung. Hyosung GT250R, Hyosung GT650R, Hyosung Aquila Pro, Hyosung Aquila 250, Hyosung Aquila GV300,	Hyosung GT300R, and Hyosung GD.</p>
        <br />
        <p>The upcoming models of Hyosung are Hyosung GD450 and Hyosung GD250N.</p>
        <br />
        <p>Hyosung has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Hyosung is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'YEZDI': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Yezdi bikes/ used Yezdi bikes available online for sale in India at CredR. The used bikes of Yezdi start at a price of Rs. 1,50,000 onwards based on specifications, colour, year, and model details. There are 3 bike models offered by Yezdi. Yezdi Roadster, Yezdi Adventure, and Yezdi Scrambler.</p>
        <br />
        <p>The upcoming model of Yezdi is Yezdi Roadking.</p>
        <br />
        <p>Yezdi has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Yezdi is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'TRIUMPH': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Triumph bikes/ used Triumph bikes available online for sale in India at CredR. The used bikes of Triumph start at a price of Rs. 2,50,000 onwards based on specifications, colour, year, and model details. There are 19 bike models offered by Triumph. Triumph Speed 400, Scrambler 400 X, Trident 660, Street Triple R, Rocket 3, Tiger 900, Speed Twin 900, Tiger Sport 660, SpeedTwin, Bonneville T100, Speed Triple 1200 RS, Tiger850Sport, Scrambler 1200, Bonneville Speedmaster, Bonneville Bobber, Bonneville T120, Scrambler 900, and Triumph Tiger 1200.</p>
        <br />
        <p>The upcoming models of Triumph are Triumph Daytona 660, Triumph Thruxton 400, and Triumph TE-1.</p>
        <br />
        <p>Triumph has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Triumph is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'DUCATI': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Ducati bikes/ used Ducati bikes available online for sale in India at CredR. The used bikes of Ducati start at a price of Rs. 4,65,000 onwards based on specifications, colour, year, and model details. There are 16 bike models offered by Ducati. Ducati SuperSport, Panigale V4 R, Panigale V2, Panigale V4, Scrambler Icon, Scrambler Nightshift, Diavel V4, Ducati Monster, Hypermotard 950, Streetfighter V4, Scrambler 1100, Multistrada V2, Streetfighter V2, Multistrada V4, DesertX, and Ducati Scrambler Full Throttle.</p>
        <br />
        <p>The upcoming model of Ducati is the Ducati Streetfighter V4.</p>
        <br />
        <p>Ducati has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Ducati is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'KINETIC': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Kinetic bikes/ used Kinetic bikes available online for sale in India at CredR. There are 4 bike models offered by Kinetic. Kinetic Green E-Luna, Kinetic Green Flex, Kinetic Green Zing, and Kinetic Green Zulu. </p>
        <br />
        <p>The upcoming models of Kawasaki are Kinetic e-Luna.</p>
        <br />
        <p>Kinetic has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Kinetic is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'MOTO-MORINI': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Moto Morini bikes/ used Moto Morini bikes available online for sale in India at CredR. There are 2 bike models offered by Moto Morini. Moto Morini Seiemmezzo and Moto Morini X-Cape.</p>
        <br />
        <p>The upcoming models of Moto Morini are the Moto Morini Seiemmezzo STR, Moto Morini Cruiser, and Moto Morini Seiemmezzo SCR.</p>
        <br />
        <p>Moto Morini has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Moto Morini is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'TVS-SUZUKI': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand TVS Suzuki Bikes & Scooters from CredR</b></h1>
        <p>Browse the wide variety of assorted TVS Suzuki bikes/ used TVS Suzuki bikes available online for sale in India at CredR. The Hosur-based manufacturing unit TVS and the Japan-based Suzuki Motor Corporation entered a joint venture in the year 1980. TVS Suzuki manufactured some of the popular models like Suzuki Fiero, Shogun, Samurai, and Supra SS. However, the joint venture was terminated in 2001.</p>
        <br />
        <p>TVS Suzuki has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. TVS Suzuki is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>     
      </>
    )
    case 'VIBGYOR': return (
      <>
        <h1 className="mb-3"><b>{getH1Title(makeModel)}</b></h1>
        <p>Browse the wide variety of assorted Vibgyor bikes/ used Vibgyor bikes available online for sale in India at CredR. There are 4 bike models offered by Vibgyor. Vibgyor Gallop, Vibgyor Hunter, Vibgyor Shark, and  Vibgyor Vida. Vibgyor has traveled a long way from offering great geared scooters to delivering a swift performance that makes it a much-loved two-wheeler brand among customers. Vibgyor is now ready with plans to provide a more exciting bike with electric features lined up. Get exciting offers with CredR; if you sell your bike, buy a used bike and exchange your old bike today! Explore it.</p>
        <br />
       </>
    )
   
    case 'USED-BIKES-IN-BANGALORE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bikes in Bangalore at An Affordable Price from CredR</b></h1>
        <p>We all look for a two-wheeler to ease our traveling experience. A two-wheeler can be taken to any place to combat traffic congestion. Especially in a cosmopolitan city like Bangalore, the traffic is very high. Having a two-wheeler is the best mode of transportation to reach any place on time. If you are new to the city, have a job in a distant place in Bangalore, or your college distance is far, then a two-wheeler is the best option for you to commute.</p>
        <br />
        <p>Choosing a second hand bike in Bangalore will serve your purpose. We all love to walk through showrooms for shopping, but have you ever walked into a two-wheeler showroom/online website? CredR Your Trusted Two-Wheeler Brand brings you the most extensive collection of used bikes. You can buy your favorite bike at an affordable price. </p>
        <br />
        <p>There is a range of 534+ used bikes in Bangalore at CredR. Second hand bikes are assorted based on specifications, model, variant, and year. Numerous showrooms have top-class certified used bikes across Bangalore- Indiranagar, Peenya, HSR Layout, Bellandur, Koramangala, and new showrooms are upcoming too! Second Hand Bikes in Bangalore are collected from the genuine seller and run through 120+ checkpoints by the auto experts before it is sold.</p>
        <br />
        <p>If you are confused about which model to buy from a used bike collection, you should browse the bike options offered by CredR! There are ranges of bikes available on a budget, and second hand bikes under 30,000 and onwards are easy to book and get delivered to the doorstep.</p>
        <br />
        <h3><b>List of the top Second Hand Bikes price in Bangalore </b></h3>
        <p>CredR offers second hand motorcycles from multiple brands, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, etc. The range of variants is; Royal Enfield Classic (₹1,34,000), Hero Glamor (₹55,903), Bajaj Avenger Street (₹77,981), TVS Apache 160 (₹77,781), Honda Unicorn 160 (₹75,306), Yamaha Fz (₹81,400), Suzuki Gixxer SF (₹81,406), TVS Sport (₹45,990), Bajaj Platina 100 (₹39,987), TVS Star City Plus (₹46,428), Honda Dream Yuga (₹60,258), Yamaha Saluto RX (₹52,671), Hero Splendor Plus (₹49,990) and TVS Radeon (₹64,287) and many more.</p>
        <br />
        <p>You can choose any second hand bike to fulfill your transportation needs. The used bike prices are based on the bike's make, model, variant, and year. You can choose any of the used bikes from the assorted collection offered by CredR for the best experience. </p>
        <br />
        <h4><b>Certified 2nd Hand Bikes in Bangalore</b></h4>
        <p>Buying a certified used bike from CredR will enable you to fetch innumerable benefits. It is a great way to enjoy a hassle-free buying experience exclusively brought to you by us. It includes;</p>
        <br />
        <ul>
          <li>Refurbished bikes</li>
          <li>Affordable price</li>
          <li>Great deal</li>
          <li>Test drive at only Rs.399 from doorstep/ Free from the showroom</li>
          <li>Six months of warranty</li>
          <li>Free 7 day buy protect</li>
        </ul>
        <p>Our collection of unbiased verified bikes is collected from genuine resources. We run a thorough 120+ level of checkpoints by an auto mechanic before procuring a used bike/used scooter. We refurbish the used bike for all the necessary parts before selling it. CredR ensures best-in-class used motorcycles at reasonable prices with free doorstep delivery.</p>
        <br />
        <h4><b>Buy Refurbished Bikes in Bangalore from CredR</b></h4>
        <p>When buying a used bike, we all prefer purchasing a two-wheeler that will help to serve the purpose. The used motorcycles at CredR are refurbished to retain the best quality. Making the best out of the old bikes is what we look for! The old motorcycles are collected from genuine sellers and refurbished to maintain quality. You do not have to spend extra money to fix anything for the used bike you'll purchase. The bikes are checked and refurbed for premium quality maintenance. You'll get a refurbished used bike at a budget-friendly price in Bangalore at CredR.</p>
        <br />
        <h4><b>Zero Hassle of Documentation while buying used bikes in Bangalore </b></h4>
        <p>When you plan to buy a used bike from CredR, there is no way to struggle with the documentation. The paperwork is done with ease. You'll get all the documents on time, including the Registration Certificate, Insurance, NOC- No Objection Certificate, and other paperwork. You'll get your documents quickly at CredR. </p>
        <br />
        <h4><b>Buy Used Bikes in Bangalore from your Home Comfort </b></h4>
        <p>At CredR, you'll get the best price and top model of your favorite bike. If you have downloaded the app, visited the CredR showroom, or checked out CredR's Website and liked any of the used bikes in the Bangalore location, then you can book a test ride at your doorstep. If you visit the showroom and take a test ride, it's your choice. Otherwise, we can arrange a test ride at your doorstep. It will cost you only Rs. 399/-. As you are satisfied with the bike, you can buy a used bike. Now at a zero investment, you can purchase second bikes in Bangalore from your home comfort.</p>
        <br />
        <h4><b>Why Choose CredR?</b></h4>
        <p>Refurbished bikes, top-class bike service, a host of benefits, free evaluation, instant payment, and doorstep delivery- CredR is the one stop for countless bike related needs. Suppose you're worried about what to buy and how to buy when it comes to used bikes. In the assorted collection that is openly displayed at CredR, you'll get your favorite bike at an affordable price. It's time for you to invest in a genuine platform and get a desirable second hand bike at a low cost. </p>
        <br />
        <p><b>Refurbished bikes:</b> The second hand bike collection is all refurbished to to retain good quality of the bike for a long time.</p>
        <br />
        <p><b>Host of benefits:</b> You'll get a host of benefits like six months of warranty on your purchased bike and 7 Day Buy protect policy. All these are added benefits that make your purchase satisfactory. </p>
        <br />
        <p><b>Test ride from home comfort:</b> You can choose any second hand bikes in Bangalore and book a test ride at home at 399 INR only. You can test the bike before purchasing it, and the option is open for making your buy perfect.</p>
        <br />
        <p><b>Easy Documentation:</b> Once you buy a used bike, documentation is the next important thing to check. All you need to do is get all the paperwork done quickly. Our executive takes all the initiatives to get it done smoothly for you. </p>
        <br />
        <p><b>Added benefits:</b> CredR is famous for its super easy checkouts, excellent customer service, and easy buying, selling, and servicing used bikes.</p>
        <br />
        <p>We hope that now you know why you should buy a used bike from CredR. We ensure that your experience with us will be easy & hassle-free. We are upgrading ourselves from time to time with added features and extraordinary benefits only to satisfy our valuable customers like you. </p>
      </>
    )
    case 'ALL-USED-BIKES-NASHIK': return (
      <>
        <h1 className="mb-3"><b>Buy a Second-Hand Bike in Nashik at The Best Price </b></h1>
        <p>Nashik's picturesque landscapes and diverse culture entice bike enthusiasts to plan their next ride. The bustling marketplace for <b>second-hand bikes in Nashik</b> offers numerous choices to both novices and experienced riders. Buying a second-hand bike is not only an environmentally friendly option, but it is also affordable and profitable at the same time.</p>
        <br />
        <p>Having a reliable two-wheeler in Nashik allows you to traverse every road that narrates a unique story. Whether you’re an adventure seeker or a daily commuter, CredR has something for you. Browse through an array of meticulously inspected and certified old bikes for sale in Nashik.</p>
        <br />
        <h3><b>Best Collection of 2nd hand bikes in Nashik</b></h3>
        <p>One of the top contenders in the second-hand bike marketplace, CredR’s has a widespread network of showrooms across Nashik. These showrooms feature an array of bikes that undergo rigorous quality checks and are certified for durability. Additional benefits like easy financing options and hassle-free documentation make CredR an incredible choice for newbies in the used bike segment.</p>
        <br />
        <p>Visit CredR’s <b>second-hand bike showroom in Nashik</b> to choose a refurbished bike that has undergone 120+ checks by an experienced mechanic. While an easy-to-navigate user interface guarantees a seamless buying experience, offline showrooms allow you to inspect the bike for any visible signs of rust and wear or tear. </p>
        <br />
        <p>Reap the benefits of below-mentioned top-notch bike services:;</p>
        <br />
        <ul>
          <li>secured payment</li>
          <li>doorstep delivery and free evaluation</li>
          <li>warranty of six months</li>
          <li>7-day Buy Protect policy </li>
          <li>Book your test ride at a reasonable cost of Rs. 399</li>
          <li>Get documents like Insurance, detailed inspection reports, NOC- No Objection Certificates, and Registration Certificates easily</li>
        </ul>
      </>
    )
    case 'USED-SCOOTERS-IN-BANGALORE': return (
      <>
      <h1 className="mb-3"><b>Buy Second Hand Scooters in Bangalore at An Affordable Price from CredR</b></h1>
     <p>There are around 800+ used scooters in Bangalore for sale. The demand for used scooters in Bangalore is growing with each passing day. Scooters are the best transport to enjoy hassle free riding experience. It is light-weighted and comfortable for riding in the city or outside.</p>
     <br/>
     <p>Especially with the growth in the two-wheeler industry, the technology and upgraded scooter models have shown significant demand among males and females. Be it daily commuting or buying a used scooty in Bangalore for fun, in all the possible needs; scooters are one of the best choices. These scooters are an excellent value for your money.</p>
     <br/>
     <p>At CredR, you’ll get refurbished great quality second hand scooty in Bangalore. The used scooters are refurbed to retain top quality. The popular brand of scooters is ready for sale. Buy the second hand Activa in Bangalore for sale at a reasonable price to find, and you’ll get a high-quality pre-owned scooter in your budget.</p>
     <br/>
     <h3><b>Best Quality Popular Used Scooty in Bangalore</b></h3>
     <p>The range of the used scooty in Bangalore falling under the popular list includes many scooter models sold in significant numbers in the used scooty market. The popular used scooty in Bangalore consists of the Honda Activa 109cc, used Yamaha Fascino, used TVS Wego 110, used Honda Dio 110, used Piaggio Vespa, used TVS Scooty Pep Plus, used Yamaha Ray, used Hero Electric Optima Plus, and many more.</p>
     <br/>
     <p>All these second hand scooty are in good condition and available for you anytime at any place. The top used scooty in Bangalore includes Honda, Hero, Yamaha, TVS Motors, Bajaj, Aprilia, Piaggio, Suzuki and more. You can find the most extensive collection of used scooters in Bangalore at CredR, and these models come with very reasonable price tags.</p>
     <br/>
     <h4><b>Why choose CredR for buying 2nd hand scooty in Bangalore?</b></h4>
     <p>CredR is one of the Most Preferred Omnichannel Consumer brands for buying, selling and servicing two-wheelers. 2nd hand scooty in Bangalore are star players in the motorcycle industry regarding mileage and comfort. Across our showrooms in multiple locations in Bangalore, second hand scooty prices in Bangalore are estimated based on the make, model, variant and year.</p>
      <br/>
      <p>Buying a second hand scooter gives you the advantage of traveling on your scooter rather than using crowded public transport. All the second hand scooters available at CredR are inspected multiple times by auto experts before every sale.</p>
      <br/>
      <p>With the new launch of the doorstep delivery scheme, customers can now have a trial run and buy the scooter from the comfort of home. You can visit the nearest CredR store to purchase second hand scooters. Some of the bestsellers in the second hand scooter in Bangalore include Honda Activa, Yamaha Ray, Honda Dio, Suzuki Access and TVS Jupiter.</p>
      <br/>
      <p><b>Refurbished scooters:</b> The second hand scooters are refurbished to retain good quality for a long time.</p>
      <br/>
      <p><b>Host of benefits:</b> Enjoy Six months of warranty and 7 Day Buy protect policy on your purchased scooter.</p>
      <br/>
      <p><b>Test ride from home comfort:</b> You can choose any used scooty in Bangalore and book a test ride at home at 399 INR only. Take a test ride before purchasing it.</p>
      <br/>
      <p><b>Easy Documentation:</b> As you buy a used scooter, documentation is the next important thing to check. All you need to do is get all the paperwork done quickly. Our executives will help you. </p>
      <br/>
      <p><b>Added benefits:</b> Get super easy checkouts, premium customer service, and easy buying, selling, & servicing of used scooters.</p>
      <br/>
      <p>CredR ensures an easy & hassle-free service. With our upgraded plans, added features and extraordinary benefits, you’ll be satisfied with your purchase of a used scooty in Bangalore. </p>
      
      </>
    )
    case 'USED-BIKES-IN-PUNE': return (
      <>
        <h1 className="mb-3"><b>Buy Certified Second hand bikes in Pune and Say Goodbye to Traffic</b></h1>
        <p>The two-wheeler market has seen significant growth over the years. Whenever we think of combating traffic or reaching a destination at lightning speed, the two-wheeler is the foremost and best option for all. Second hand bikes in Pune are in high demand.</p>
        <br />
        <p>Pune is a beautiful place, and it's growing day by day. Facing severe traffic congestion is part and parcel of the commoners residing here. Having a two-wheeler will help to make the travel experience more convenient and enjoyable. The wide range of used bikes in Pune offered by the top consumer brand CredR is phenomenal.</p>
        <br />
        <p>If you are new to the city of Pune, need a two-wheeler for daily commuting, or a student who needs a two-wheeler to go to college, Used bikes are the best option. The motorcycles fulfil the need as a mode of transportation, offering convenience and comfortability. The low-cost maintenance mileage bikes in India are assorted in the second hand bike list at the CredR site.</p>
        <br />
        <h3><b>Why choose a Second hand bike in Pune?</b></h3>
        <p>Because you get your favourite bike at a low price with the same level of comfortability, reliability, and convenience. It is no wonder that the second hand bikes in Pune are gaining more popularity among the masses. Be it travelling, trekking places, city rides, or daily commuting, the used bikes offer the same commitment, reliability, and mileage as new motorcycles. People are looking to buy quality bikes at an affordable price. </p>
        <br />
        <p>The used bikes are sold with affordable price tags at CredR. The bikes are refurbished to retain the top quality and extend the bike's longevity. Most people tend to choose a second hand bike over the new one to enjoy the bike ride at a pocket-friendly price. </p>
        <br />
        <p>There are around 564 used bikes in Pune that are ready for sale. If you are looking for good-quality budget bikes, then you should check out the assorted collection offered by CredR. There is a good collection of second hand motorcycles at the CredR showroom in Pune. The pre-owned bikes are collected from genuine sellers. These bikes run through 120+ checkpoints by the auto experts before selling to the customer. </p>
        <br />
        <p>Are you confused about which bike to buy within budget? To ease your buying experience, we have the best used bikes in Pune. These bikes can be sorted based on model, variant, and brand. Second hand bikes under 30,000 in Pune are easily accessible online.</p>
        <br />
        <h4><b>List of the Top Range of Used Bikes Price in Pune</b></h4>
        <p>There is an assorted collection of used bikes in Pune. CredR offers second hand motorcycles from multiple brands, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, etc. </p>
        <br />
        <p>We have a diverse range of used bikes in Pune from the top brands like; Bajaj Avenger Street 220 (₹60,000), TVS Scooty PepPlus (₹34,546), Hero Duet LX (₹48,910), Piaggio Vespa LX (₹75,797), TVS Wego Std (₹47,000), Bajaj Avenger Cruise 220 (₹72,500), Bajaj Avenger Street 150 (₹60,600), Bajaj Pulsar 150 DTS- i (₹58,100), Suzuki Access UZ (₹44,600), Bajaj Avenger Cruiser 220 (₹64,600), TVS Apache RTR 200 (₹82,000), Hero Pleasure Std (₹36,500), Bajaj Pulsar 200NS (₹58,600), Hero Maestro Edge (₹47,000), TVS Jupiter Std (₹50,000) and more.</p>
        <br />
        <p>You can choose any of your favourite used bikes to fulfil your transportation requirements. Second hand bike price is based on the bike's make, model, variant, and year. You can get any one of your choices and take a test ride at 399 INR only.</p>
        <br />
        <h4><b>Buy A Second Hand Bike in Pune Within Your Budget</b></h4>
        <p>Regarding public transportation, there is no waiting if you buy a motorcycle for commuting. There is no wait, whether it is like waiting to purchase bus tickets or finding an empty seat to travel long distances with your companion two-wheeler. Take your bike and go to your desired place without any hassle. Especially going to the office on time, combating the traffic is possible if you choose a second hand mileage bike in Pune for daily commuting.</p>
        <br />
        <p>Buying certified used bikes in Pune from CredR will get you added benefits. You'll enjoy a hassle-free buying experience exclusively brought to you by us. Purchasing a refurbished used bike will help you to serve the purpose. The used motorcycles are refurbished to retain the top quality.</p>
        <br />
        <p>Making the best out of the old bikes is what we look for! The old motorcycles are collected from genuine sellers and refurbished to maintain quality. You do not have to spend extra money to fix anything for the used bike you'll purchase. The bikes are checked and refurbed for premium quality maintenance. You'll get a refurbished used bike at a budget-friendly price in Pune only at CredR.</p>
        <br />
        <h4><b>Why Choose CredR to Buy Second hand bikes in Pune?</b></h4>
        <p>Get top-quality refurbished bikes, doorstep bike service, free evaluation by auto experts, proper documentation, and quick payment by CredR, Your Trusted Two-Wheeler Brand. There is no way you have to get bothered about buying, selling, and servicing your bike with CredR, as we handle it with care. You'll get your favourite brand bike at an affordable price in just a few clicks. </p>
        <br />
        <p><b>Refurbished bikes:</b> All the used bikes are collected from genuine sellers, checked by 120+ parameters by Auto Experts, and then refurbished to retain the top quality of the used bikes. </p>
        <br />
        <p><b>Host of benefits:</b> It includes six months of warranty and 7 Day buy protection policy. All these are added benefits that make your purchase satisfactory. </p>
        <br />
        <p><b>Test ride from the doorstep:</b> You can only book a doorstep test ride at 399 INR. You can take a test ride before purchasing it; this option is open to making your purchase perfect. </p>
        <br />
        <p><b>Easy Documentation:</b> Once you buy a used bike, you must complete the paperwork quickly. Our executive takes all the initiatives to get it done smoothly for you. </p>
        <br />
        <p><b>Added benefits:</b> We ensure super easy checkouts, premium customer service, and easy selling, servicing, and buying of used bikes. </p>
        <br />
        <p>In the end, it is all about the choices that we make. You need to own a used bike that gives you a sense of freedom and comfort. Buying a second hand scooty in Pune from CredR at a pocket-friendly price will provide you with the same satisfaction as the new bike. There is no need to depend on the other mode of transportation when you can own your two-wheeler within budget. Check out our second hand bikes collection at CredR.</p>

      </>
    )
    case 'USED-SCOOTERS-IN-DELHI': return (
      <>
        <h1 className="mb-3"><b>Looking to Buy a second hand scooty in low price in Delhi?</b></h1>
        <p>Buy 2341 <b>second hand scooty in Delhi</b> available for sale online. All the <b>used scooty in Delhi</b> are accessible at the best price starting from 25,000 to 66,000. <b>2nd hand scooty in Delhi</b> is available at a fixed price based on the model, features, and year. You can select <b>second hand scooty</b>  at a low price from the top list provided above. Select any model by specifications, transmission type & get a certified <b>used scooter in Delhi</b>.</p>
        <br />
        <p> ‘I want to buy a used scooty in Delhi?’ is a common question that we see among all the willing customers. To ease your life now, buying <b>used scooty</b> at an affordable price is accessible through CredR.</p>
        <br />
        <h3><b>Book Second Hand Scooty For Sale In Delhi </b></h3>
        <p>When we talk about choosing the most suitable <b>second hand scooty in Delhi under 20,000</b>- think CredR. It is the best place to get your used scooters at a reasonable price rate at your doorstep. To get all the details of the <b>second hand scooty price in Delhi</b>, all you need to do is download the CredR app or browse the website to get the step-by-step process.</p>
        <br />
        <p>Choosing the <b>Activa scooty price in Delhi</b> from CredR is based on the model and year. You can choose <b>Jupiter scooty in Delhi, Vespa Scooty in Delhi, Piaggio Scooter in Delhi, Hero Scooter in Delhi for women</b>. CredR offers a wide range of <b>second hand scooty in Delhi under 15,000</b>  that is completely budget-friendly and extremely affordable for the commoners to use as a daily commuter.</p>
        <br />
        <h4><b>Choose Used Scooty in Delhi for Daily Commuting</b></h4>
        <p>Looking for a scooter for daily use? Choosing a scooter in Delhi is a great way to combat traffic congestion and drive through the two-wheeler market effortlessly. Today, countless scooters are available from top brands like Piaggio, Honda, TVS, Suzuki, Yamaha, and Hero. The starting price is Rs.15,000 and onwards. The used scooters run through 120+ checkpoints by experienced auto experts to get you the premium quality used scooter.</p>
        <br />
        <p>CredR takes the responsibility to provide a hassle-free service. Check the page and figure out the top range of used scooters available at the Delhi NCR location. Explore the price, variants, images, and specifications of the scooters to find a suitable scooter at your convenience. The <b>second hand scooty in Delhi under 10,000</b> collection is impressive; you need to come and explore collections without any hesitation.</p>
        <br />
        <h4><b>Popular Second Hand Scooty Showroom in Delhi</b></h4>
        <p>So, without any further ado, let’s begin with the comprehensive list of the top <b>cheapest scooty in Delhi</b> to make available for the customers at CredR to purchase dependable scooters. If you are among the ones looking for vehicles, all are compiled in the best scooters in Delhi list for your consideration.</p>
        <br />
        <p><b>Popular Scooter in Delhi</b></p>
        <br />
        <p><b>Popular Hero scooters in Delhi:</b> Hero Pleasure Plus | Hero Maestro Edge 125 | Hero Destini 125</p>
        <br />
        <p><b>Popular Honda Scooter in Delhi:</b> Honda Activa 6G | Honda Dio | Honda Activa 125 | Honda Grazia</p>
        <br />
        <p><b>Popular Suzuki Scooter in Delhi:</b> Suzuki Access 125 | Suzuki Burgman Street</p>
        <br />
        <p><b>Popular Piaggio Scooter in Delhi:</b> Vespa VXL 150 | Vespa LX 125 | Vespa Notte 125 | Vespa SXL 125 | Vespa Elegante 150 | Vespa VXL 125 | Vespa LX 125 | Vespa Urban Club 125 | Vespa SXL 150 | Vespa Elettrica</p>
        <br />
        <p><b>Popular Yamaha Scooter in Delhi:</b> Yamaha Fascino 125 | Yamaha RayZR 125 | Yamaha Aerox 155 | Yamaha Nmax 155 </p>
        <br />
        <p><b>Popular TVS Scooter in Delhi:</b>TVS Jupiter | TVS iQube Electric | TVS Ntorq 125 | TVS XL 100 | TVS Scooty Zest | TVS Scooty Pep Plus </p>
        <br />
        <p><b>Popular Bajaj Scooter in Delhi:</b> Bajaj Chetak </p>
        <br />
        <p><b>Popular Mahindra Scooter in Delhi:</b> Mahindra Gusto 125 | Mahindra Gusto VX | Mahindra Kine | Mahindra Rodeo RZ | Mahindra Flyte | Mahindra Duro DZ </p>
        <br />
        <p><b>Second Hand Scooty Showroom in Delhi locations:</b> Rani Bagh & Mayur Vihar</p>
        <br />
        <h4><b>Second hand scooty price lists in Delhi</b></h4>
        <br />
        <ul>
          <li>Hero Pleasure Plus (₹38,000*)</li>
          <li>Hero Maestro Edge 125 (₹39,000*)</li>
          <li>Hero Destini (₹ 34,000*)</li>
          <li>Suzuki Access 125 (₹ 35,000*)</li>
          <li>Vespa  (₹45,800*)</li>
          <li>TVS Jupiter (₹ 43,741*)</li>
          <li>Yamaha Fascino (₹ 36,023*)</li>
          <li>Yamaha RayZR (₹ 32,000*)</li>
          <li>Mahindra Gusto(₹ 42,750*)</li>
          <li>Honda Activa 6G (₹ 28,000*)</li>
          <li>Honda Dio (₹31,821*)</li>
        </ul>
        <br />
        <h4><b>Why choose used scooty in Delhi from CredR?</b></h4>
        <p><b>Buy used scooty in Delhi</b> from CredR at a reasonable price to use it effortlessly without any intimation of traffic headaches. Scooters are used for swiftness, lightweight which makes them ideal for women to buy. Explore the <b>best scooty for ladies in Delhi</b>. There are numerous scooters for men in Delhi that ended up exploring too. All these new scooters or upcoming scooters are user-friendly and serve unisex.</p>
        <br />
        <ul>
          <li>Scooty is a great way to get around daily.</li>
          <li>It can help you save a lot of money when buying a <b>used scooty in Delhi</b>.</li>
          <li>There is no need to take extra care of the <b>used scooters</b>.</li>
          <li>The bikes are restored to ensure that they maintain their high quality.</li>
          <li>The <b>used scooty in Delhi</b> is purchased from reputable suppliers.</li>
          <li>CredR offers a risk-free service.</li>
          <li>On <b>used scooters in Delhi</b>, the interest rates are substantially lower.</li>
          <li>All of the <b>second hand scooters in Delhi</b> at CredR are 100 percent certified.</li>
        </ul>
        <p><b>Second hand scooty price lists in Delhi</b> are sorted based on the location. You can check above choosing your favorite and filter the price range, follow the steps and buy your scooty today! You can even check a <b>second hand scooty showroom near me</b> in the search bar to connect to the nearby <b>CredR showroom in Delhi</b>. Once you buy a <b>used scooter</b> and ride it, you will undoubtedly feel thankful for this best friend that CredR will help you to get connected with! Buy <b>second hand scooty in Delhi</b> now! Download the CredR app.</p>
      </>
    )
    case 'USED-BIKES-IN-JAIPUR': return (
      <>
        <h1><b>Choosing the finest two-wheeler to buy second hand bike in Jaipur</b></h1>
        <p>CredR is the first omnichannel consumer brand, having 1000+ used bikes that feature the top range of brands like Hero, Honda, Bajaj, Mahindra, Suzuki, Yamaha, TVS, KTM, Royal Enfield, Harley Davidson, Kawasaki, and many more. CredR eases the possibilities of buying, selling, and exchanging the two-wheelers from any location in India. <b>Used bikes at Jaipur</b> are accessible both online and offline from a <b>second hand bike showroom</b>.</p>
        <br />
        <h4><b>Buy used bikes for daily commuting. </b></h4>
        <p>Have you been wondering which bike you will choose for daily commuting? It is always better to choose fuel-efficient bikes for daily usage. The chances are that you may be picking used bikes that are good as sports bikes. You need to check the list of available bikes at CredR that are truly fuel-efficient, comfortable, and reliable. The list of bikes are mentioned below;</p>
        <br />
        <ul>
          <li>Honda Shine CB (₹39,200)</li>
          <li>Bajaj Pulsar (₹57,000)</li>
          <li>Bajaj Platina (₹55,200)</li>
          <li>Bajaj Avenger Street (₹39,200)</li>
          <li>TVS Apache (₹34,650)</li>
          <li>Hero Splendor (₹33,000)</li>
          <li>Yamaha FZS (₹54,000)</li>
          <li>TVS Star City Sport (₹23,000)</li>
          <li>Hero Honda Splendor Pro (₹27,000)</li>
          <li>Honda Stunner (₹28,100) and many more.</li>
        </ul>
        <p>These bikes are some of the <b>top mileage bikes</b>; you can try out to enjoy daily community experiences to be more comfortable and value for money.</p>
        <br />
        <h4><b>Buy used bikes from CredR- Your Trusted Two-Wheeler Brand.</b></h4>
        <p>CredR Your Trusted Two-Wheeler Brand is the one-stop solution for your countless two-wheeler related requirements. You can <b>buy used bikes</b> from your home by simply downloading the CredR app or glancing through the website. The <b>used bikes online in Jaipur</b> are ready to sell at a desirable price. All the bikes are collected from genuine sellers at an accurate price and sold according to industry standards.</p>
        <br />
        <p>CredR believes in offering transparent quality service. Check the range of bikes based on the availability, specifications, model, and year. You’ll be communicated on your purchase till you bought a <b>used bike from Jaipur</b> is delivered to your doorstep. </p>
        <br />
        <h4><b>Refurbished 2nd hand bike for sale</b></h4>
        <p>CredR’s motive is to ease the process of buying, selling, and exchanging <b>used two-wheelers in Jaipur</b>.  We offer refurbished bikes at an affordable price to meet the best-in-quality and high standards. The spare parts and other required changes are done as per the requirement for the bikes before we place them up for sale. There is no need to spend extra money on additional repairs, and we are here for you.</p>
        <br />
        <h4><b>Unparallel benefits of buying the best second hand bikes in Jaipur</b></h4>
        <p>At CredR, there are many benefits of buying used bikes from a <b>second hand bike showroom in Jaipur</b>.  All you need to do is select a good bike for yourself that will meet your comfortability, reliability, value for money, and good performance. The benefits of buying used bikes in Jaipur from CredR are listed below:
        </p><br />
        <ul>
          <li>Best deals </li>
          <li>100% certified bikes </li>
          <li>6 months warranty</li>
          <li>Free 7 days buy protect</li>
        </ul>
        <p>The collection of <b>Used bikes in Jaipur</b> are of good quality. Not every brand has similar benefits as it does with CredR. After thorough research and genuinity of the product sourcing, we handpick the best used bikes making you switch over to the <b>second hand bikes in Jaipur</b>. The second hand bike prices are based on year, model, and bike specifications.</p>
      </>
    )
    case 'USED-SCOOTER-BNGLR-PIAGGIO': return (
      <>
      <h1 className="mb-3"><b>Top Quality Second Hand Piaggio in Bangalore at an Affordable Price</b></h1>
      <p>Among all Scooters Piaggio Vespa is the most desirable. Explore the various second-hand used scooters in Bangalore available in an affordable range at CredR. Get easy EMI plans for buying an used scooter from a preferred location at a set budget. CredR makes sure that the pre-owned scooters are in good condition in terms of specification, variants, and mechanical parameters. All the used scooters have successfully run through the 120+ stages of scrutiny by auto experts, before making it available to you. Explore the collection at CredR and get one of your favorite Piaggio Vespa scooters in the preferred budget at a specific destination of your choice.</p>
      </>
    )
    case 'USED-BIKES-IN-DELHI': return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Bikes in low price in Delhi from CredR</b></h1>
        <p>Browse through the premium collection of around 892 <b>used bikes in Delhi</b> accessible at CredR. The range of used two-wheelers is best in class. CredR provides the facility to <b>buy used Motorcycles</b> from CredR's <b>second hand bike showroom</b> in Delhi- Rani Bagh and Mayur Vihar. CredR offers second hand bikes from multiple brands, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, etc. You can choose any of your favourite brand bikes and book from the CredR website.</p>
        <br />
        <p>Want to buy used bikes in Delhi? It is a great idea to buy a second hand motorcycle. These are collected based on the model, specifications and year of manufacture. Buying a used bike will save a lot of money and give you the same fun as you get it from a new one. Two- wheeler offers comfort to drive through the traffic in the city.</p>
        <br />
        <h3><b>List of Second Hand bikes in Delhi with Price- Brands</b></h3>
        <p>Upcoming stores of CredR in Delhi will be launched shortly. The range of variants are;</p>
        <br />
        <ul>
          <li>Hero Splendor Plus (₹49,990)</li>
          <li>TVS Apache 160 (₹77,781)</li>
          <li>TVS Sport (₹45,990)</li>
          <li>Bajaj Avenger Street (₹77,981)</li>
          <li>Honda Dream Yuga (₹53,985 – ₹60,258)</li>
          <li>Royal Enfield Classic (₹1,34,000)</li>
          <li>Bajaj Platina 100 (₹39,987)</li>
          <li>Yamaha Saluto RX (₹52,671)</li>
          <li>Honda Unicorn 160 (₹75,306)</li>
          <li>TVS Star City Plus (₹46,428)</li>
          <li>Hero Glamor (₹55,903)</li>
          <li>Yamaha Fz (₹81,400)</li>
          <li>Suzuki Gixxer SF (₹81,406)</li>
          <li>TVS Radeon (₹64,287)</li>
        </ul>
        <h4><b>Where will I buy Second hand Two Wheelers in Delhi?</b></h4>
        <p>CredR is the first omnichannel consumer brand that helps you find the best collection of top-rated two-wheelers available in the market. All the <b>used two-wheelers in Delhi</b> are purchased from a genuine seller. The <b>used bikes</b> have undergone 120+ levels of checkpoints by expert auto mechanics. <b>Second hand bikes in Delhi</b> for sale are 100% certified and affordable in price. Check the maximum selling price of any used bike if you are not sure of the price in the market. Trusting CredR will enable you to avail best deals on <b>used bikes in Delhi</b>. Book your test drive from home at Rs.399 today!</p>
        <br />
        <h4><b>Used Bikes in Affordable Price</b></h4>
        <p>The collection of the used bikes in CredR are premium and of a gold standard. The bikes are collected from a genuine seller. The prices are placed based on the model and year of manufacturing. Making it buyer-friendly is what CredR aims at! We make sure that the used bikes collected are checked under 120+ parameters by auto experts. The bikes are refurbished for retaining good quality. CredR promises good quality service and products.</p>
        <br />
        <h4><b>What Benefits will I get buying a Pre Owned bike in Delhi?</b></h4>
        <p>Among all the platforms available in the market, CredR has the full range of used bikes, refurbished for the best quality that is ready to sell. You can choose from the scope of brands. Filter all the brands, models, types, variants, prices to get the range of used bikes in Delhi. Choosing CredR is excellent for a few reasons;</p>
        <br />
        <ul>
          <li><b>Great price-</b> The budget is an important consideration. Every purchase we make is based on a budget. CredR offers amazing solutions to make purchasing a second hand motorcycle in Delhi more convenient and inexpensive.</li>
          <li><b>Hassle-free Service- </b>Only through trust and true service have we gone so far. For a hassle-free service, all used bikes are sold in a step-by-step process both online and offline.</li>
          <li><b>Refurbished bikes- </b> At CredR, you'll get access to a superior range of bikes. In Delhi, all used bikes are obtained from reputable sellers. They are put through 120+ aspects of checkpoints, which are covered by the auto experts. All of the motorcycles have been refurbished to ensure the highest level of quality. You will not have to pay any further money to service the second hand bike you purchased from CredR.</li>
          <li> <b>Six months of warranty-</b> Make sure your bike is in good working order for the future. For critical bike parts like the engine and gearbox, get the best guarantee in the market. It's impossible to put a price on anything like that!</li>
          <li><b>Free 7 day buy protection- </b>You're protected with our flagship product. Any unanticipated concerns that arise in the next seven days will be resolved at no cost to you. You may go out with confidence on your CredR second hand bike.</li>
          <li><b>The easy documentation-</b> When you buy second hand bikes from CredR, you won't have to deal with any paperwork. The service is far too open and convenient to use. You can count on us to complete your request quickly and provide timely updates.</li>
        </ul>
        <h4><b>Buy 2nd hand Bikes in Delhi from Home Comfort</b></h4>
        <p>Because of two significant factors, CredR is the ideal site to <b>buy second hand Two Wheeler</b>.</p>
        <ul>
          <li>To begin with, it offers <b>second hand motorcycles in Delhi</b> that have all been refurbished in the finest possible way to maintain their quality.</li>
          <li>Second, you'll be able to purchase used bikes from your favourite brand at a considerably lower cost.</li>
        </ul>
        <p>When you choose CredR, you won't have to worry about any legal issues. We take care of everything so that you can buy a <b>old bike in Delhi</b> with ease. Book your favourite two-wheeler today! Explore the most incredible range of <b>2nd hand bikes for sale</b> through CredR at a desirable price.</p>
      </>
    )
    case 'USED-BIKES_DELHI': return (
      <>
        <h1 className="mb-3"><b>Buy Second hand Bikes in low price in Delhi from CredR</b></h1>
        <p>Browse through the assorted collection of all used bikes in Delhi NCR. At CredR, we have more than 1000+ used bikes currently on sale at our various locations. The price starts from ₹20,000 onwards. The most popular brands include Hero, Honda, TVS, Bajaj, Royal Enfield, Yamaha, Suzuki, Mahindra, Piaggio, and many more. The popular models are Hero Splendor iSmart std (₹33,990); Honda Shine Cb (₹42,990), Honda Activa 4G ( ₹44,490), TVS Jupiter (₹33,000), Hero Passion (₹32,500), Suzuki Access 125Z (42,000), Yamaha R15 (₹64,000), Yamaha Fascino (₹32,000) and many more.</p>
        <p>Used bikes are available in Delhi NCR. The popular bikes are refurbished for quality purposes. Sell your bike, buy a used bike, and exchange bike with CredR. Enjoy the high-quality benchmark in the used two-wheelers.</p>
      </>
    )
    case 'ALL-USED-BIKES-BANGALORE': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bikes in Bangalore at An Affordable Price from CredR</b></h1>
        <p>There are 1000+ collections of used bikes/used scooters at CredR in Bangalore. You’ll get a fair deal on these used two-wheelers. The price starts with ₹20,000 onwards. The most assorted bikes are from notable brands like Hero, Honda, TVS, Bajaj, Royal Enfield, KTM, Yamaha, Suzuki, Mahindra, Piaggio, Kawasaki, Harley Davidson, and many more. The top-selling models are Yamaha Ray ZR (₹45,990), Royal Enfield Thunderbird 350 (₹ 52,990), Hero Maestro Std (₹44,990), Honda Dio (54,990), Yamaha Fzs Std (₹54,990), Suzuki Intruder 150 (₹ 75,990), Hero Duet VX (₹46,990), Honda Activa 5G (₹54,990) and many more.</p>
        <p>All used bikes in Bangalore are easily accessible online. To know more about the price, photos, mileage, variants, and reviews, check out the list above and book your two-wheeler today! Buy a used bike, sell your bike, and exchange bike with CredR. Offers are limited for a period; you should use the booking option to secure your favorite bike before the sale ends.</p>
      </>
    )
    case 'ALL_USED_BIKES_JAIPUR': return (
      <>
        <h1 className="mb-3"><b>Buy Second Hand Bikes in Jaipur at An Affordable Price from CredR</b></h1>
        <p>Stop by and gaze at the top collections of used bikes available at CredR. You can book online or visit the CredR showroom. The assorted collection of used bikes starts from a price range of ₹20,000 onwards. The most popular brands are TVS, Suzuki, Yamaha, Hero, Honda, Bajaj, KTM, Kawasaki, Royal Enfield, Piaggio, and many more. The top-selling models are Hero Duet VX (₹44,990), Hero Passion ProTr (₹28,990), Bajaj Avenger 220 (₹51,000), Bajaj Discover 100 STD (₹28,990), Hero Honda Passion Pro (₹26, 990), Hero Passion ProDrs (₹44,990), TVS Star City Std (₹ 31,000), Yamaha FZ std (₹47,000), Hero Splendor Pro ( ₹36,900) and many more.</p>
        <p>Used bikes in Jaipur are accessible in the best quality only at CredR. To explore more about the used two-wheeler, check the online website to find the collection of bikes. Figure out the bike prices, photos, mileage, reviews, and details, and book your favorite bike today!</p>
      </>
    )
    case 'USED-BIKES': return (
      <>
        <h1><b>Buy Second Hand Bikes Online in India at the Best Prices From CredR</b></h1>
        <p>Are you confused on whether you should buy a new motorcycle or <b>buy a second hand bike</b> is a matter of concern! We undoubtedly appreciate personal choices, but investing to <b>buy used bikes</b> will give you the same experience as you’ll enjoy your new two-wheeler. CredR, Your Trusted Used Two-Wheeler Brand is dedicated to serving the commoners with ease of buying, selling, and exchanging used two-wheelers. To make life easier, CredR has made the entire system of buying and selling easy and user-friendly.</p>
        <br />
        <h4><b>Best Second hand bike showroom in India</b></h4>
        <p>There are around 728+ <b>used bikes</b> accessible based on locations including Bangalore, Pune, Delhi, Gurgaon, Jaipur, Ahmedabad, Faridabad, Bhilwara, Sikar, Chittorgarh. Choose your favorite refurbished bikes at an affordable price from CredR. The <b>best 2nd hand bike for sale</b> brands include Hero, Honda, Bajaj, Mahindra, Suzuki, Yamaha, TVS, KTM, Royal Enfield, Harley Davidson, Kawasaki, and many more.</p>
        <br />
        <h4><b>Premium second hand bike price at CredR</b></h4>
        <p>CredR has the assorted collection of bikes from the top brands as already mentioned. Our premium <b>second hand bikes for sale</b> includes;</p>
        <br />
        <ul>
          <li>Honda Shine (₹1,16,128), </li>
          <li>Hero HF Deluxe (₹1,o6,304), </li>
          <li>Bajaj Pulsar (₹65,094), </li>
          <li>Bajaj Platina ((₹54,606),</li>
          <li>Hero Passion (₹75,400),</li>
          <li>Royal Enfield Classic 350 (₹1,52,000),</li>
          <li>Royal Enfield Meteor (₹1,86,000),</li>
          <li>Honda Unicorn (₹86,128),</li>
          <li>Hero Glamour (₹80,000),</li>
          <li>Yamaha YZF R15 V3 ((₹1,55,000),</li>
          <li>TVS Apache RTR 160 (₹1,02,000),</li>
          <li>KTM Duke (₹1,82,000) and many more. </li>
        </ul>
        <p>The <b>used bikes prices</b> are as per industry standards. The <b>2nd hand bike for sale</b> prices is estimated based on the type of bike. Its model, variant, specifications, and year. <b>The best second hand bikes</b> from these top brands will leave you in awe to choose one from such an amazing lot of bikes. </p>
        <br />
        <h4><b>CredR one-stop for countless benefits</b></h4>
        <p>CredR helps you to enable a seamless and easy process, especially when you are trying to choose more popular <b>used bikes at the best price</b>. Going up by the higher demand to buy certified bikes, we make sure the bikes have undergone 120+ checkpoints by an auto expert before it is up for sale. All the bikes are refurbished for good quality maintenance. The collection of the <b>best bikes in India 2021</b> are all accessible here.</p>
        <br />
        <p>You’ll be amazed to witness the collection of <a href="https://www.credr.com/used-bikes-in-Bangalore"><b>used bikes in Bangalore</b></a> or <a href="https://www.credr.com/used-bikes-in-Delhi-NCR "><b>used bikes in Delhi</b></a>, or <a href="https://www.credr.com/all-used-bikes-in-Pune"><b>used bikes in Pune</b></a>. All <b>the used bikes for sale</b> are accessible to all the locations for an easy buying experience for ordinary people. </p>
        <br />
        <h4><b>Buy Refurbished bikes at a reasonable price</b></h4>
        <p>The second hand used bikes at CredR are all refurbished budget bikes. The two-wheelers are collected from genuine sellers with all the documents. The bikes run through a thorough analysis by the auto experts before it is put up on the website for sale. <b>Buy a second hand bike online</b> from CredR today!</p>
        <br />
        <h4><b>100% Certified used bikes</b></h4>
        <p>All the Second hand bikes at CredR are certified from a trusted source. We do not sell stolen bikes or without proper documentation. Our entire service is transparent and genuine. Delays in the process seem unwelcome, and we do not prefer to let our customers wait. We like clarity in communication and stand by it to make the customer experience user-friendly. </p>
        <br />
        <h4><b>Get the Best deals & Affordable prices on second hand bikes</b></h4>
        <p>The most important factor when it comes to buying a vehicle is budget. People tend to <b>buy used bikes</b> for two main reasons: Good price and good benefits. If both are provided in swift order, then why buy a new bike? At CredR, you’ll get a fair deal of your favorite <a href="https://www.credr.com/used-bikes-in-Bangalore"><b>used bikes in Bangalore</b></a> or <a href="https://www.credr.com/used-bikes-in-Delhi-NCR "><b>used bikes in Delhi</b></a>, or <a href="https://www.credr.com/all-used-bikes-in-Pune"><b>used bikes in Pune</b></a>.  The bikes are priced based on the exact conditions that are checked and tested.</p>
        <br />
        <h4><b>Second hand bike Test drive at ₹399</b></h4>
        <p>Be it a commuter bike or a performance-oriented bike, and if it is in demand, we make sure we have the collection of it here too for you to choose from the bike ranges. The <b>used bikes prices</b> are reasonable. There are no hidden charges as well as follow transparent and genuine service orders. Check on the specifications, colors, model, variants, and other parallels before you <b>buy used bikes</b>. Take a test ride and enjoy the service.</p>
        <br />
        <h4>Benefits to buy second bikes from CredR</h4>
        <p>There are innumerable hosts of benefits that you’ll enjoy once you purchase a used bike from CredR. Apart from enjoying the refurbished bikes and good deal, other added benefits are listed below;</p>
        <br />
        <ul>
          <li>6 months of warranty</li>
          <li>Free 7 day buy protect</li>
          <li>Hassle-free documentation</li>
        </ul>
        <h4><b>Conveniently buy used bikes</b></h4>
        <p>The <b>2nd hand bike for sale at CredR</b> is effortless, simple, and most convenient for the buyers. By just visiting the website or downloading the CredR app, you can select your preferred brand, price, and variant to streamline or filter the collection of bikes in one go. You can choose any bike at an affordable price in just a few clicks.</p>
        <br />
        <h4><b>Buy best second hand bikes Online</b></h4>
        <p>From CredR’s <b>second hand bike showroom</b>, you can buy used bikes at an overwhelming price. We do everything possible to guarantee you a smooth two-wheeler buying experience. Do not forget to use the platform’s benefits once you purchase a used bike. You’ll get all popular brand bikes. The possibilities are endless at CredR. Buying the <b>best second hand bike online</b> was never this easy as it is in CredR. You’ll experience a hassle-free buy. You can even book from home comfort and get doorstep service. The feature of the test ride is highly appreciated and encouraged so that you buy based on your comfortability and reliability on the specific bike you choose.</p>
      </>
    )
    case 'ALL_USED_BIKES': return (
      <>
      <h1><b>Buy second hand bike online at Affordable Price From CredR</b></h1>
      <p>Are you confused on whether you should buy a new motorcycle or <b>buy a second hand bike</b> is a matter of concern! We undoubtedly appreciate personal choices, but investing to <b>buy used bikes</b> will give you the same experience as you’ll enjoy your new two-wheeler. CredR, Your Trusted Used Two-Wheeler Brand is dedicated to serving the commoners with ease of buying, selling, and exchanging used two-wheelers. To make life easier, CredR has made the entire system of buying and selling easy and user-friendly.</p>
      <br />
      <h4><b>Best Second hand bike showroom in India</b></h4>
      <p>There are around 728+ <b>used bikes</b> accessible based on locations including Bangalore, Pune, Delhi, Gurgaon, Jaipur, Ahmedabad, Faridabad, Bhilwara, Sikar, Chittorgarh. Choose your favorite refurbished bikes at an affordable price from CredR. The <b>best 2nd hand bike for sale</b> brands include Hero, Honda, Bajaj, Mahindra, Suzuki, Yamaha, TVS, KTM, Royal Enfield, Harley Davidson, Kawasaki, and many more.</p>
      <br />
      <h4><b>Premium second hand bike price at CredR</b></h4>
      <p>CredR has the assorted collection of bikes from the top brands as already mentioned. Our premium <b>second hand bikes for sale</b> includes;</p>
      <br />
      <ul>
        <li>Honda Shine (₹1,16,128), </li>
        <li>Hero HF Deluxe (₹1,o6,304), </li>
        <li>Bajaj Pulsar (₹65,094), </li>
        <li>Bajaj Platina ((₹54,606),</li>
        <li>Hero Passion (₹75,400),</li>
        <li>Royal Enfield Classic 350 (₹1,52,000),</li>
        <li>Royal Enfield Meteor (₹1,86,000),</li>
        <li>Honda Unicorn (₹86,128),</li>
        <li>Hero Glamour (₹80,000),</li>
        <li>Yamaha YZF R15 V3 ((₹1,55,000),</li>
        <li>TVS Apache RTR 160 (₹1,02,000),</li>
        <li>KTM Duke (₹1,82,000) and many more. </li>
      </ul>
      <p>The <b>used bikes prices</b> are as per industry standards. The <b>2nd hand bike for sale</b> prices is estimated based on the type of bike. Its model, variant, specifications, and year. <b>The best second hand bikes</b> from these top brands will leave you in awe to choose one from such an amazing lot of bikes. </p>
      <br />
      <h4><b>CredR one-stop for countless benefits</b></h4>
      <p>CredR helps you to enable a seamless and easy process, especially when you are trying to choose more popular <b>used bikes at the best price</b>. Going up by the higher demand to buy certified bikes, we make sure the bikes have undergone 120+ checkpoints by an auto expert before it is up for sale. All the bikes are refurbished for good quality maintenance. The collection of the <b>best bikes in India 2021</b> are all accessible here.</p>
      <br />
      <p>You’ll be amazed to witness the collection of <a href="https://www.credr.com/used-bikes-in-Bangalore"><b>used bikes in Bangalore</b></a> or <a href="https://www.credr.com/used-bikes-in-Delhi-NCR "><b>used bikes in Delhi</b></a>, or <a href="https://www.credr.com/all-used-bikes-in-Pune"><b>used bikes in Pune</b></a>. All <b>the used bikes for sale</b> are accessible to all the locations for an easy buying experience for ordinary people. </p>
      <br />
      <h4><b>Buy Refurbished bikes at a reasonable price</b></h4>
      <p>The second hand used bikes at CredR are all refurbished budget bikes. The two-wheelers are collected from genuine sellers with all the documents. The bikes run through a thorough analysis by the auto experts before it is put up on the website for sale. <b>Buy a second hand bike online</b> from CredR today!</p>
      <br />
      <h4><b>100% Certified used bikes</b></h4>
      <p>All the Second hand bikes at CredR are certified from a trusted source. We do not sell stolen bikes or without proper documentation. Our entire service is transparent and genuine. Delays in the process seem unwelcome, and we do not prefer to let our customers wait. We like clarity in communication and stand by it to make the customer experience user-friendly. </p>
      <br />
      <h4><b>Get the Best deals & Affordable prices on second hand bikes</b></h4>
      <p>The most important factor when it comes to buying a vehicle is budget. People tend to <b>buy used bikes</b> for two main reasons: Good price and good benefits. If both are provided in swift order, then why buy a new bike? At CredR, you’ll get a fair deal of your favorite <a href="https://www.credr.com/used-bikes-in-Bangalore"><b>used bikes in Bangalore</b></a> or <a href="https://www.credr.com/used-bikes-in-Delhi-NCR "><b>used bikes in Delhi</b></a>, or <a href="https://www.credr.com/all-used-bikes-in-Pune"><b>used bikes in Pune</b></a>.  The bikes are priced based on the exact conditions that are checked and tested.</p>
      <br />
      <h4><b>Second hand bike Test drive at ₹399</b></h4>
      <p>Be it a commuter bike or a performance-oriented bike, and if it is in demand, we make sure we have the collection of it here too for you to choose from the bike ranges. The <b>used bikes prices</b> are reasonable. There are no hidden charges as well as follow transparent and genuine service orders. Check on the specifications, colors, model, variants, and other parallels before you <b>buy used bikes</b>. Take a test ride and enjoy the service.</p>
      <br />
      <h4>Benefits to buy second bikes from CredR</h4>
      <p>There are innumerable hosts of benefits that you’ll enjoy once you purchase a used bike from CredR. Apart from enjoying the refurbished bikes and good deal, other added benefits are listed below;</p>
      <br />
      <ul>
        <li>6 months of warranty</li>
        <li>Free 7 day buy protect</li>
        <li>Hassle-free documentation</li>
      </ul>
      <h4><b>Conveniently buy used bikes</b></h4>
      <p>The <b>2nd hand bike for sale at CredR</b> is effortless, simple, and most convenient for the buyers. By just visiting the website or downloading the CredR app, you can select your preferred brand, price, and variant to streamline or filter the collection of bikes in one go. You can choose any bike at an affordable price in just a few clicks.</p>
      <br />
      <h4><b>Buy best second hand bikes Online</b></h4>
      <p>From CredR’s <b>second hand bike showroom</b>, you can buy used bikes at an overwhelming price. We do everything possible to guarantee you a smooth two-wheeler buying experience. Do not forget to use the platform’s benefits once you purchase a used bike. You’ll get all popular brand bikes. The possibilities are endless at CredR. Buying the <b>best second hand bike online</b> was never this easy as it is in CredR. You’ll experience a hassle-free buy. You can even book from home comfort and get doorstep service. The feature of the test ride is highly appreciated and encouraged so that you buy based on your comfortability and reliability on the specific bike you choose.</p>
    </>
    )
    case 'USED-SCOOTERS': return (
      <>
        <h1><b>Buy Second Hand Scooty Online in India at the Best Prices From CredR</b></h1>
        <p>Travel? Daily Communication? Set. No more searching here and there when you have CredR. An assorted collection of 999+ second hand scooters is available to <b>buy Used scooty online in India</b>. Browse through the range of used scooters; if you are looking for a used activa scooter or a used Jupiter, you can check the list of available scooters we have. Brands are the first and foremost thing that we look into. Choosing a reputable brand Hero, Honda, TVS, Suzuki, Yamaha & Bajaj. Although, only looking for a brand is not the only option as you used scooters specifications, scooty models, price, and year.</p>
        <br />
        <p>Used scooty in India on sale are available at an affordable price. The range of Second Hand Scooty is in different locations like Bangalore, Pune, Delhi, Gurgaon, Jaipur, Ahmedabad, Faridabad, Bhilwara, Sikar, Chittorgarh. <b>All these are certified used scooters at CredR.</b>
        </p><br />
        <h4><b>Buy used refurbished scooty</b></h4>
        <p>The concept of refurbishment is greatly appreciated. It is revamping the used scooty in the best ways to get you a quality product. At CredR, the scooty is bought from genuine customers after driving it through 120+ levels of checkpoints. The auto experts run a thorough test so that the used scooty purchased by you is great in quality. At CredR warehouse, all the used scooters available are refurbished at best possible ways to get you the best-in-quality <b>used scooters in India under 50,000</b>. There is a great collection of used scooters under 30,000, which you need to check before you place your order to buy a used scooter online in India.</p>
        <br />
        <h4><b>Listing the best selling used scooters in India</b></h4>
        <p>It isn't easy to find the best used scooter in India when you have so many online used scooty available. We have sorted this for you. You can choose from the following <b>top selling used scooters in Delhi NCR</b> or <a href="https://credr.com/used-scooters-in-Bangalore"><b>Used scooters in Bangalore</b></a> on a budget and get the best riding experience.</p>
        <br />
        <ul>
          <li>Honda Activa(₹54,000)</li>
          <li>Yamaha Ray (₹45,000)</li>
          <li>Honda Dio (₹57,000)</li>
          <li>Suzuki Access 125 (₹71,000)</li>
          <li>TVS Jupiter (₹43,000)</li>
          <li>TVS Ntorq (₹40,000)</li>
          <li>Suzuki Burgman(₹47,000)</li>
          <li>Hero Destini (₹52,000)</li>
          <li>TVS Pep+(₹55,000)</li>
          <li>Honda Grazia (₹51,000)</li>
          <li>Honda Dio (₹52,000)</li>
        </ul>
        <h4><b>Used scooty is a Unisex preferred motorcycle.</b></h4>
        <p>The sky is the limit; when the leading manufacturer starts designing to create an exceptional scooter for the common people! Scooty was mainly designed for women. The ease of mobility, comfortability, reliability, and low maintenance features of the used scooters are the main reason that makes it girls hot favorite.<a href="https://credr.com/used-scooters-in-Pune"><b> Used scooters in Pune</b></a> are largely brought by female customers who have loved to review us timely. The easy accessibility of commuting has added considerable advantages to the rider's life.</p>
        <br />
        <p>With the dynamic features, now men too are loving the sleek and stylish scooters. You will notice numerous men riding it through and <b>buying used scooters at a reasonable price to use daily</b>. There are some collections of used scooty in Jaipur, like Suzuki Burgman. It not only has a smooth look but has an adventurous feel to it. Both men & women love the mixed combination of comfort and adventurous feeling of the two-wheeler. You can choose any of your favorite <b>used scooty online</b> to grab the attention of the masses while striking it through the street.</p>
        <br />
        <h4><b>All Used Scooters are 100% Certified.</b></h4>
        <p>At CredR, the collection of the best scooters in India are all 100% certified from a trusted source. The used scooter prices are based on the model, specifications, variants, colors, and year. The paperwork is done with ease. You'll have to feel no hassle when you're <Link to="/used-scooters" className="font-semibold"><b>buying a used scooty from CredR</b></Link> at your set budget.</p>
        <br />
        <h4><b>Why should I choose CredR to buy a used scooty in India?</b></h4>
        <p>CredR aims at offering the most effortless service when it comes to buying and selling used scooters in India. You will not only get a good quality used scooty, but you’ll also enjoy innumerable benefits. It includes;</p>
        <br />
        <ul>
          <li><b>6 months of warranty</b></li>
        </ul>
        <p>Secure your bike for the future. You’ll enjoy a 6 months warranty of a comprehensive benefit if you buy a used scooter from CredR. Experience the finest warranty covering the critical bike parts, including Engine & Gearbox.</p>
        <br /><ul>
          <li><b>Free 7 Days Buy Protect</b></li>
        </ul>
        <p>Buying a used scooty near me wasn’t this better as it is with CredR. You’ll get covered with our flagship product. Any unforeseen issues you face in 7 days will get resolved without spending any single penny. Ride out worry-free on your used scooty.</p>
        <br />
        <ul>
          <li><b>Great deals</b></li>
        </ul>
        <p>You’ll have an amazing deal while choosing the best used scooter online from CredR’s list. You can determine any top used two-wheeler online priced accurately based on specifications, model, variant, and year.</p>
        <br />
        <ul>
          <li><b>No Legal Hassle </b></li>
        </ul>
        <p>There is no hassle with legal documentation, as everything will be done step-by-step. You’ll get RC, insurance, and related papers in time. Book a used scooter online now.</p>
        <br />
        <p>Make your investment in used scooty perfect with a reasonable purchase of budget bikes like <b>used scooty under 35,000</b> from CredR.</p>
      </>
    )
    case 'ALL-USED-BIKES-AT-HOME': return (
      <>
        <h1>Buy Second Hand Bikes in India - buy at home From CredR</h1>
        <p>Your Trusted used Two-wheeler brand -CredR, aims to offer a transparent & genuine service when it comes to  sell and buy used bikes at an affordable price. We care about you, and thus we came up with an uncomplicated  option of now buying used bikes with 100% online service. Your chosen bike is delivered at home, maintaining all the formalities for a  hassle-free service. There is no change in the buying process. </p>
        <br />
        <p>Browse through the assorted collection of used bikes that are displayed above and book your favorite bike. Our collection of bikes cater to brands like Suzuki, Yamaha, Royal Enfield, Honda, TVS, Hero, KTM, Harley Davidson, Kawasaki, and many more.  The Second hand bikes come in diverse variants, manufacture dates, and budget. It includes Royal Enfield Classic, Hero Glamor DRS, Bajaj Avenger Street, TVS Apache 160, Honda Unicorn 160, Suzuki Gixxer SF, Yamaha FZS, Hero Splendor, Bajaj Pulsar, Hero Passion, Bajaj Platina, Honda Unicorn, and many more.</p>
        <br />
        <ul>
          <li>Visit the CredR website or download the CredR App.</li>
          <li>Browse the online collection to buy a used refurbished bike</li>
          <li>Choose your favorite bike at the best price</li>
          <li>Submit your details</li>
          <li>Book a  doorstep test drive at ₹399</li>
          <li>Finalize on the bike </li>
        </ul>

        <h4><b>Post buy Benefits:</b></h4>
        <ul>
          <li>6 months of warranty</li>
          <li>7 day buy protect</li>
          <li>Assured RC transfer</li>
        </ul>
        <p>Nonetheless, keeping the social distance in mind and safety of our customers, we make sure the online buy is smooth and effortless.  All the necessary documents and processes are our responsibility.  Have a stress-free riding experience with CredR.</p>
      </>
    )
    case 'ALL-USED-BIKES-AHMEDABAD': return (
      <>
        <h1><b>Looking to Buy a Second Hand Bike in Ahmedabad at a Reasonable Price?</b></h1>
        <p>There are many second hand bikes in Ahmedabad available for sale. The used bikes are of premium quality, starting from Rs. 15,000 onwards. The second hand bikes are refurbished for good quality. You’ll get the opportunity to enjoy a test ride before you choose any brand of bike. The collection of bikes are procured from a trusted source. You can enjoy buying a 2nd hand motorcycle at an affordable price.</p>
        <br />
        <h3><b>Assorted Used Bike for Sale in Ahmedabad</b></h3>
        <p>Browse through the assorted collection of best mileage bikes, top selling bikes in Ahmedabad are now accessible in the newest showroom of 2nd hand bikes in Ahmedabad. The second hand bikes are chosen based on specifications, variants, models, year and many more. There are comprehensive showrooms with a top class collection of 100% certified bikes.</p>
        <br />
        <p>Want to buy pre-owned bikes in Ahmedabad? It is a good idea to buy a second hand vehicle at half price. Buying used motorcycles will save a lot of money. If you purchase mileage bikes, then the user-friendliness will be diverse. Choose from any brand you want and get a fair deal at CredR.</p>
        <br />
        <h4><b>List of Second Hand Bike in Ahmedabad (Price)</b></h4>
        <p>At CredR, you can buy a used bike from various manufacturers, such as Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, and others. The range of variants includes:</p>
        <br />
        <ul>
          <li>Royal Enfield Classic (1,34,000),</li>
          <li>Hero Glamor (55,903),</li>
          <li>Bajaj Avenger Street (77,981),</li>
          <li>TVS Apache 160 (77,781),</li>
          <li>Honda Unicorn 160 (75,306),</li>
          <li>Yamaha Fz (81,400),</li>
          <li>Suzuki Gixxer SF (81,406),</li>
          <li>TVS Sport (45,990),</li>
          <li>Bajaj Platina 100 (39,987), </li>
          <li>TVS Star City Plus (46,428), </li>
          <li>Honda Dream Yuga ₹53,985 – ₹60,258),</li>
          <li>Yamaha Saluto RX (₹52,671), </li>
          <li>Hero Splendor Plus (₹49,990), </li>
          <li>TVS Radeon (₹64,287) and many more.</li>
        </ul>
        <h4><b>Why Should You Purchase Certified Second Hand bikes?</b></h4>
        <p>Purchasing a certified used bike from CredR will provide you with a plethora of benefits. It contains the following items:
        </p>
        <br />
        <ul>
          <li>Refurbished bikes</li>
          <li>Reasonable price</li>
          <li>Exceptional offer</li>
          <li>Test drive for Rs. 399 from your Home</li>
        </ul>
        <p>Our collection of unbiased, certified motorcycles is collected from reliable sources. Before purchasing a used bike or scooter, an auto mechanic does a thorough 120+ level of checkpoints. Before we sell the used bike, we make sure it has all of the necessary parts. CredR guarantees top quality used motorcycles at affordable prices with free delivery to your Home.</p>
        <br />
        <h4><b>Buy Used Bike in Ahmedabad from CredR</b></h4>
        <p>The premium collection of <b>second hand bikes in Ahmedabad</b> are of the gold standard. The bikes are collected from genuine sellers. The prices are placed based on the manufacturing year and model. CredR aims at making a buyer-friendly approach so that everyone can enjoy an affordable buy! The bikes are put up for sale once these are verified, run through 120+ levels of parameters by the auto experts. The refurbished bikes retain top quality. You'll enjoy effortless service and premium bike quality with CredR. </p>
        <br />
        <p>If you are looking for a second hand bike in Ahmedabad under 20,000/ 30,000/ 15000/ 10000, you'll get it from the assorted range. There is no limit to the collection of the bikes at CredR. You can get old bikes that are now no longer manufactured by the respective brands. The collection of the used bikes or used scooters are taken care of in terms of refurbishing. Be it colour, damaged parts or even any dent; the experts sort it all.</p>
        <br />
        <h4><b>Affordable Second Hand Motorcycle in Ahmedabad </b></h4>
        <p>Price is a crucial factor when it comes to second hand bike prices in Ahmedabad. There is no way you have to compromise with the price. Once you log in to the CredR website, filter the state, price and type of bikes to get the best-assorted motorcycle for yourself. You'll get everything in your set budget.</p>
        <br />
        <p>Be it a commuter bike, sportbike, cruiser bike or even any other type of bike; you'll get an assorted range of lists. Make up your mind to fetch the best bikes. Make sure that you get the clarity on which bike you are willing to buy. Used bikes like Yamaha FZS, Honda Hornet 2.0, TVS Apache RTR 160 4V, Suzuki Gixxer, Hero Honda Splendor, Bajaj Pulsar NS200 and many more. </p>
        <br />
        <p>Some motorcycles require less maintenance. You can choose bikes like Honda CD 110 Dream, TVS Star City, Bajaj Pulsar, and Honda Splendor iSmart. If you are diving into long-term used bikes, you should choose from the collections of Royal Enfield, Jawa, & Kawasaki brands.</p>
        <br />
        <p>Among all the used bikes in Ahmedabad available in the CredR second hand bikes showrooms, all are highest rated Honda bikes with an average rating of 93%. The famous used bikes brand in Ahmedabad- the cruiser bikes like Triumph take up the runner upshot in 91% along with Aprilia and 89% Kawasaki. Among all the commuter bikes, the city bikes mainly incorporated the main features of a hybrid bike and paired well for city commuters. You can choose to buy any of these used motorcycles from CredR listed bikes in Ahmedabad.</p>
        <br />
        <h4><b>Zero Legal Hassle while Second hand Bike Purchase Online</b></h4>
        <p>While you engrave in the second hand bike purchase online, paperwork is a crucial factor, and it is inevitable to get clarity. Whether an owner purchases a new bike or a used bike, you have to clear the paperwork. CredR makes sure that all the relevant documents are available and shared as per the customer needs. While the original documents are stored safely in the locker, photocopies must be carried in the vehicle. You'll get the paperwork on the documents, including;</p>
        <br />
        <ul>
          <li>Free RC certificate</li>
          <li>No objection certificate-NOC</li>
          <li>Two-wheeler insurance policy documents </li>
          <li>Pollution Under Control Certificate-POC</li>
        </ul>
        <p>Investing in any of the used bikes at CredR is an excellent choice and the right decision for you. Try to opt for the two-wheeler related insurance policy to essentially protect the unfortunate events like road mishaps, natural calamities, accidents and many more. Explore the benefits of CredR while buying a pre-owned motorcycle on a budget. </p>
        <br />
        <h4><b>Purchase Used Vehicle from Home in Ahmedabad
        </b></h4>
        <p>CredR offers a 100% online service without any requirement for you to come to the showroom. Once you have downloaded the CredR app, you can buy a second hand two-wheeler by following the step-wise process. Take a test ride for just Rs.399 only from your home. Check the bike comfortability and other notable features like noise, brake, clutch and gear before placing an order.</p>
        <br />
        <h4><b>Why Choose CredR- Your Trusted Two-Wheeler Brand?</b></h4>
        <p>CredR has the largest platform of used bikes available on the market, all of which have been reconditioned to the highest quality and are ready to sell. You can pick from a variety of brands. To acquire a range of second hand bikes in Ahmedabad, filter all the brands, models, types, variants, and pricing.</p>
        <br />
        <ul>
          <li><b>Excellent Value for Money:</b> The budget is an essential factor. We have a budget for everything we buy. CredR provides incredible options for purchasing a used motorcycle in Ahmedabad more accessible and affordable.</li>
          <li><b>Hassle-free Service:</b> We've only gotten this far because of trust and accurate service. All used motorcycles are sold step-by-step, both online and offline, for a hassle-free experience.</li>
          <li><b>Refurbished bikes- </b>At CredR, you'll get access to a wide selection of refurbished bikes. All second hand bikes in Ahmedabad are purchased from trusted suppliers. They are subjected to more than 120 checks, all of which are covered by auto experts. To assure the highest degree of quality, all of the motorcycles have been restored. You will not be charged any additional fees to service the used bike you purchased from CredR.</li>
          <li><b>Easy documentation:</b> You won't have to worry about any paperwork when buying used motorcycles with CredR. The service is far too accessible and straightforward to use. You can rely on us to respond immediately to your request and offer timely updates.</li>
        </ul>
        <p>Check out the collection of used motorcycles at CredR so that you can get second hand bike purchase online benefits with ease. </p>


      </>
    )
    case 'USED-BIKES-IN-PUNE-SUZUKI-ACCESS': return (
      <>
        <h1><b>Refurbished Second Hand Access in Pune at an Affordable Price </b></h1>
        <p>There are 71 used access scooters present in the Pune location. The used bikes are collected from genuine sellers. Second hand access bikes are quite popular among the youth and adults. Buy second hand access at the best price in Pune based on the model, specifications, features, and locations. </p>
        <br />
        <p>Suzuki Access has maintained a never-failing market for the leading scooter segment of the two-wheeler market in India for ages. The popular access in Pune variants is access 125, access 125 std, access drum cast, and access disc CBS. It has now become the household name as daily commuting was never as easy as with second hand Suzuki Access. Both men and women enjoy the comfortable riding experience that the Suzuki Access offers, and it is considered the best mileage scooter in India.</p>
        <br />
        <h3><b>100% Certified 2nd Suzuki Access Bikes in Pune with Interesting Features</b></h3>
        <p>Second hand Suzuki Access is bought by many users enjoying the bike ride in the best possible ways at a fair price. The demand for this bike is due to its lower cost. Second hand access price in Pune at an affordable price runs through verifications and step-by-step scrutiny by auto experts based on 120+ checkpoints. There are no hidden charges once you procure the used access from CredR.</p>
        <br />
        <ul>
          <li><b>Retains high-level performance </b></li>
        </ul>
        <p>Suzuki Access is most liked for its lightweight, 125cc engine with BS6 league. It offers highly useful features with an updated motor, full LED headlight and an easy start system. Very less time is spent on this used scooty for its positive characteristics. The power of the scooty is great while riding on the street.</p>
        <ul>
          <li><b>Superb riding experience</b></li>
        </ul>
        <p>It is highly safe to say that riding a second hand access scooty with a good suspension is one of the best in the market. It offers perfect balance and gobbles up potholes with speed breakers with ease. The modern touch to this scooter is the external fuel filler cap, which sounds pretty cool to the users. </p>
        <br />
        <h4><b>Six months warranty on purchasing a Second Hand Access bike</b></h4>
        <p>Second hand Suzuki Access 125 in Pune can be easily purchased from CredR, and you will get a 6 months warranty on your purchase. The collection of 2nd hand access in Pune is refurbished for sustaining quality. You won’t have to spend extra money once you buy a used scooter in India.</p>
        <br />
        <h4><b>Buy 2nd hand Access Scooter from Home Comfort</b></h4>
        <p>The used Suzuki Access for sale in Pune from the second hand two-wheeler showrooms. Once you have booked a Suzuki Access second hand in Pune, you can do it from your home comfort. It would help if you chose the test ride at Rs 399 only to check the parameters of the bike and comfortability before you purchase it. Do not hesitate to take the test ride and choose your bike. After purchase, the scooter is delivered to your home at your convenient time.</p>
        <br />
        <p>The used Suzuki Access is a must buy as it is the best mileage scooter available on the market. If you are in two minds, do not overthink; get in touch with CredR and get your favourite access at a much more desirable price.</p>
      </>
    )
    case 'ALL-USED-SCOOTERS-IN-PUNE-HONDA-ACTIVA': return (
      <>
        <h1><b>Top Quality Second Hand Activa in Pune at an Affordable Price</b></h1>
        <p>There are 131 used Activa present in Pune location. The used bikes are collected from genuine sellers. Second hand activa bikes are quite popular among the youth and adults. Buy second hand Activa at the best price in Pune based on the model, specifications, features, and locations. Honda Activa has maintained a never-failing market for the leading scooter segment of the two-wheeler market in India for ages. The popular used Activa in Pune variants includes Activa 3G, Activa 4G, Activa 5G, and Activa 6G. It has now turned out to be the household name. Both men and women enjoy the comfortable riding experience that the Honda Activa offers. It is considered to be the best mileage scooter in India.</p>
        <br />
        <h3><b>100% Certified 2nd Honda Activa Bikes in Pune</b></h3>
        <p>At a fair price, Honda Activa seconds are bought by many users enjoying the bike ride in the best possible ways. The demand for this bike is due to its lower price, which is listed below;</p>
        <ul>
          <li>Honda Activa Ss110- ₹58,000</li>
          <li>Honda Activa 3G- ₹46,500</li>
          <li>Honda Activa 5G- ₹66,000</li>
          <li>Honda Activa 180 ES- ₹64,600</li>
          <li>Honda Activa 4G- ₹40,500</li>
        </ul>
        <p>Second hand Activa price in Pune at an affordable price runs through verifications and step-by-step scrutiny by auto experts based on 120+ checkpoints. There are no hidden charges once you procure the used Activa from CredR.</p>
        <br />
        <h4><b>Six months warranty on purchasing a Second Hand Activa bike</b></h4>
        <p>Second hand Honda Activa 125 in Pune can be easily purchased from CredR, and you will get a 6 months warranty on your purchase. The collection of 2nd hand Activa in Pune is refurbished for sustaining quality, and you won’t have to spend extra money once you buy a used bike.</p>
        <br />
        <h4><b>Buy a 2nd hand Activa Scooter. Get easy Home Delivery.</b></h4>
        <p>The used Honda Activa for sale in Pune from the second hand showrooms. Once you have booked a Honda Activa second hand in Pune, you can do it from your home comfort. It would help if you chose the test ride at Rs 399 only to check the parameters of the bike and comfortability before you purchase it. Do not hesitate to take the test ride and choose your bike. After purchase, the home delivery will be done to your satisfaction at your convenient time.</p>
        <br />
        <p>The used Honda Activa is a must buy as it is the best mileage scooter available on the market. If you are in two minds, do not overthink; get in touch with CredR and get your favourite Activa at a much more desirable price. </p>
      </>
    )
    case 'USED-BIKES-IN-MUMBAI': return (
      <>
        <h1><b>Buy Refurbished Second Hand Bikes in Mumbai at an Affordable Price </b></h1>
        <p>Browse through the wide range of second hand bikes in Mumbai brought to you by CredR- Your Trusted Two-Wheeler Brand. There are more than 125+ used bikes and used scooty accessible on online websites. At the CredR showrooms, you’ll see your favourite brand's wide variety of used motorcycles, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, and Harley Davidson, Mahindra, Bajaj and more. The used bikes in Mumbai are of premium quality. The starting price is Rs.20,000 onwards. The second hand bikes are refurbished to retain good quality. You’ll get max benefits as you buy a 2nd hand bike in Mumbai. All the bikes are based on customers’ preferences and keep the demand in mind. You‘ll undoubtedly get your choice of a second bike at an affordable price.</p>
        <br />
        <h3><b>Choose Certified 2nd hand bikes in Mumbai.</b></h3>
        <p>Purchasing a certified used motorcycle will provide you with a host of benefits. It contains the following things;</p>
        <br />
        <ul>
          <li>Refurbished bikes</li>
          <li>Affordable price</li>
          <li>Exceptional offers - 6 months of warranty, free 7-day buy Protect</li>
          <li>Certified Bike by auto experts based on 120+ Inspection points </li>
        </ul>
        <p>Our second hand bikes for sale are chosen from trusted sellers with proper documents. The papers are verified to avoid issues in the future that a new buyer will face. Before we purchase a second hand two-wheeler, our expert auto mechanics will run down a thorough inspection of 120+ checkpoints. We guarantee you a top-quality used bike or used scooty at a desirable price with free delivery at your home.</p>
        <br />
        <h4><b>Premium Collection of Second hand bikes in Mumbai to meet your Demands</b></h4>
        <p>Are you new to Mumbai City? Are you looking for a commuter bike for daily movement? Are you looking for a travel bike to ride from Mumbai to other locations? All sorts of demands can be fulfilled as you choose to buy a used bike of good quality from us. All the bikes retain their excellent quality for their refurbished factors. While finalising a used bike, the auto mechanics ensure that the bike is in better condition. The second hand motorcycles are put up for sale once 120+ checkpoints are taken care of by the auto experts. </p>
        <br />
        <p>If you are looking for a second hand two-wheeler in Mumbai at a price range of 20,000 or above, you’ll get a second hand bike price under 40,000 as well. There is no limit to the collection of used bikes at CredR. You can get the old model bikes which the brands no longer manufacture. Whether it's colouring or repairing damaged parts, we refurbish the bikes in the best ways.</p>
        <br />
        <h4><b>No Delay in Processing Documents as you Buy Second hand Bike</b></h4>
        <p>While you are busy browsing through the collection of used bikes in Mumbai, the most important thing is that we take care of the paperwork. As you choose a used bike, we ensure that you get the paperwork transferred quickly. The relevant documents are arranged by our experts and shared as per your requirement. You will get the documents that include:</p>
        <br />
        <ul>
          <li>Free RC transfer</li>
          <li>NOC- No objection certificate </li>
          <li>Two-Wheeler Insurance Policy</li>
          <li>POC- Pollution Under Control certificate </li>
        </ul>
        <p>As you choose to invest in a second hand bike, it is best for you to commute daily. Try and opt for the two-wheeler related insurance policy, which is too essential for protecting the bike from unfortunate events like natural calamities, accidents, and road mishaps. All the documents will be transferred as you choose to buy a used motorcycle from CredR.</p>
        <br />
        <h4><b>Buy a second hand two-wheeler from the Comfort of your Home.</b></h4>
        <p>If you are not finding time to come to the CredR showroom near you, do not worry! Our assorted collection of second hand bikes in Mumbai online can be delivered to your location. You can buy a used bike from home by placing an order through the CredR website or CredR App. The filter option of choosing your choice of brand, model, and pre-set price range will offer you a good range of bikes as you desire. Once you select the bike, book a test ride for just 399 INR. A dedicated expert will be at your location with the bike for you to enjoy the test ride. </p>
        <br />
        <p>Check on the comfort level and other features like unwanted noise, brakes, clutch, and gear. If everything seems good to you, place your order online.</p>
        <br />
        <ul>
          <li>Choose a bike </li>
          <li>Book a test ride at 399/- at your convenient location</li>
          <li>Check the comfortability</li>
          <li>Place your order</li>
        </ul>
        <p>The used bikes in Mumbai will surely meet your desire, as we prefer to offer our customers gold standard bikes and services.</p>
        <br />
        <h4><b>Why should you choose CredR to Buy a Second hand Bike?</b></h4>
        <p>Your trusted two-wheeler brand CredR is the omnichannel consumer brand where the consumers get the privilege to enjoy easy buying, selling and servicing bikes. There are ample range of benefits that you are going to explore here are listed below;</p>
        <br />
        <p><b>Value for Money:</b> The collection of the used bikes at CredR is budget-friendly. You can choose your bike as per your wish based on your preferable price range. There are no added charges, and the service is transparent. You do not have to pay anything extra to buy a refurb second hand bike from us. </p>
        <br />
        <p><b>Refurbished bikes:</b> The bikes once collected from the sellers are refurbished to retain the best quality. Be it colour, gears, clutches, brakes or even engine motor, and all are set and verified to retain the premium condition of the bike that you’ll end up buying from us.</p>
        <br />
        <p><b>Certified Auto expert on 120+ checkpoints:</b> The bikes are 100% certified, and they are thoroughly tested. And these are certified by industry experienced Auto experts who come with 8+ years of experience.</p>
        <br />
        <p><b>Free 6 months of comprehensive warranty worth Rs.5000:</b> Once you buy a second hand two-wheeler from us, you’ll experience the best-in-class warranty covering critical bike parts like the engine and gearbox.</p>
        <br />
        <p><b>Free & Day Buy Protect: </b>You get covered with our flagship product. If you notice any unforeseen issues within a week, that will be resolved free of cost. Your riding experience will be convenient and flawless. </p>
        <br />
        <p>Thus, At CredR, you’ll get the best brand second hand bikes in India. CredR is the one-stop solution for your countless bike-related problems. Buy certified used bikes from genuine sellers. Book your choice of the bike today.</p>

      </>
    )
    case 'ALL-USED-BIKES-MUMBAI': return (
      <>
        <h1><b>Buy Refurbished Second Hand Bikes in Mumbai at an Affordable Price </b></h1>
        <p>Browse through the wide range of second hand bikes in Mumbai brought to you by CredR- Your Trusted Two-Wheeler Brand. There are more than 125+ used bikes and used scooty accessible on online websites. At the CredR showrooms, you’ll see your favourite brand's wide variety of used motorcycles, including Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, and Harley Davidson, Mahindra, Bajaj and more. The used bikes in Mumbai are of premium quality. The starting price is Rs.20,000 onwards. The second hand bikes are refurbished to retain good quality. You’ll get max benefits as you buy a 2nd hand bike in Mumbai. All the bikes are based on customers’ preferences and keep the demand in mind. You‘ll undoubtedly get your choice of a second bike at an affordable price.</p>
        <br />
        <h3><b>Choose Certified 2nd hand bikes in Mumbai.</b></h3>
        <p>Purchasing a certified used motorcycle will provide you with a host of benefits. It contains the following things;</p>
        <br />
        <ul>
          <li>Refurbished bikes</li>
          <li>Affordable price</li>
          <li>Exceptional offers - 6 months of warranty, free 7-day buy Protect</li>
          <li>Certified Bike by auto experts based on 120+ Inspection points </li>
        </ul>
        <p>Our second hand bikes for sale are chosen from trusted sellers with proper documents. The papers are verified to avoid issues in the future that a new buyer will face. Before we purchase a second hand two-wheeler, our expert auto mechanics will run down a thorough inspection of 120+ checkpoints. We guarantee you a top-quality used bike or used scooty at a desirable price with free delivery at your home.</p>
        <br />
        <h4><b>Premium Collection of Second hand bikes in Mumbai to meet your Demands</b></h4>
        <p>Are you new to Mumbai City? Are you looking for a commuter bike for daily movement? Are you looking for a travel bike to ride from Mumbai to other locations? All sorts of demands can be fulfilled as you choose to buy a used bike of good quality from us. All the bikes retain their excellent quality for their refurbished factors. While finalising a used bike, the auto mechanics ensure that the bike is in better condition. The second hand motorcycles are put up for sale once 120+ checkpoints are taken care of by the auto experts. </p>
        <br />
        <p>If you are looking for a second hand two-wheeler in Mumbai at a price range of 20,000 or above, you’ll get a second hand bike price under 40,000 as well. There is no limit to the collection of used bikes at CredR. You can get the old model bikes which the brands no longer manufacture. Whether it's colouring or repairing damaged parts, we refurbish the bikes in the best ways.</p>
        <br />
        <h4><b>No Delay in Processing Documents as you Buy Second hand Bike</b></h4>
        <p>While you are busy browsing through the collection of used bikes in Mumbai, the most important thing is that we take care of the paperwork. As you choose a used bike, we ensure that you get the paperwork transferred quickly. The relevant documents are arranged by our experts and shared as per your requirement. You will get the documents that include:</p>
        <br />
        <ul>
          <li>Free RC transfer</li>
          <li>NOC- No objection certificate </li>
          <li>Two-Wheeler Insurance Policy</li>
          <li>POC- Pollution Under Control certificate </li>
        </ul>
        <p>As you choose to invest in a second hand bike, it is best for you to commute daily. Try and opt for the two-wheeler related insurance policy, which is too essential for protecting the bike from unfortunate events like natural calamities, accidents, and road mishaps. All the documents will be transferred as you choose to buy a used motorcycle from CredR.</p>
        <br />
        <h4><b>Buy a second hand two-wheeler from the Comfort of your Home.</b></h4>
        <p>If you are not finding time to come to the CredR showroom near you, do not worry! Our assorted collection of second hand bikes in Mumbai online can be delivered to your location. You can buy a used bike from home by placing an order through the CredR website or CredR App. The filter option of choosing your choice of brand, model, and pre-set price range will offer you a good range of bikes as you desire. Once you select the bike, book a test ride for just 399 INR. A dedicated expert will be at your location with the bike for you to enjoy the test ride. </p>
        <br />
        <p>Check on the comfort level and other features like unwanted noise, brakes, clutch, and gear. If everything seems good to you, place your order online.</p>
        <br />
        <ul>
          <li>Choose a bike </li>
          <li>Book a test ride at 399/- at your convenient location</li>
          <li>Check the comfortability</li>
          <li>Place your order</li>
        </ul>
        <p>The used bikes in Mumbai will surely meet your desire, as we prefer to offer our customers gold standard bikes and services.</p>
        <br />
        <h4><b>Why should you choose CredR to Buy a Second hand Bike?</b></h4>
        <p>Your trusted two-wheeler brand CredR is the omnichannel consumer brand where the consumers get the privilege to enjoy easy buying, selling and servicing bikes. There are ample range of benefits that you are going to explore here are listed below;</p>
        <br />
        <p><b>Value for Money:</b> The collection of the used bikes at CredR is budget-friendly. You can choose your bike as per your wish based on your preferable price range. There are no added charges, and the service is transparent. You do not have to pay anything extra to buy a refurb second hand bike from us. </p>
        <br />
        <p><b>Refurbished bikes:</b> The bikes once collected from the sellers are refurbished to retain the best quality. Be it colour, gears, clutches, brakes or even engine motor, and all are set and verified to retain the premium condition of the bike that you’ll end up buying from us.</p>
        <br />
        <p><b>Certified Auto expert on 120+ checkpoints:</b> The bikes are 100% certified, and they are thoroughly tested. And these are certified by industry experienced Auto experts who come with 8+ years of experience.</p>
        <br />
        <p><b>Free 6 months of comprehensive warranty worth Rs.5000:</b> Once you buy a second hand two-wheeler from us, you’ll experience the best-in-class warranty covering critical bike parts like the engine and gearbox.</p>
        <br />
        <p><b>Free & Day Buy Protect: </b>You get covered with our flagship product. If you notice any unforeseen issues within a week, that will be resolved free of cost. Your riding experience will be convenient and flawless. </p>
        <br />
        <p>Thus, At CredR, you’ll get the best brand second hand bikes in India. CredR is the one-stop solution for your countless bike-related problems. Buy certified used bikes from genuine sellers. Book your choice of the bike today.</p>

      </>
    )
    case 'ALL-USED-BIKES-MYSORE': return (
      <>
        <h1><b>Buy Used Bike and Scooters At An Affordable Price in Mysore</b></h1>
        <p>The two-wheeler industry has observed significant growth over the years among the consumers. Whenever we think of easy movement to combat excess traffic or moving from here to there, the two-wheeler is the best transport. Investing money in a used bike in Mysore is better than buying a new one. The second hand bikes in Mysore are in high demand. Be it the model, variant or brand, all the best-in-class used bikes or used scooters in Mysore are easily accessible at the CredR showroom in Mysore. </p>
        <br />
        <p>Mysore, the cultural capital of Karnataka, is very famous for its culture and palace. The serenity and palaces of Mysore make it an ideal destination for the users, attracting both domestic and international tourists. To take a long ride on your two-wheeler, Mysore is a great destination. Eventually, having traffic congestion is pretty normal. To combat that congestion, you need to make your travelling experience better. In such cases, a second hand bike plays a convenient role in making your movement enjoyable. A wide range of used bikes in Mysore at CredR offers consumers the best used bikes.</p>
        <br />
        <p>If you are new to the city of Mysore, a second hand two-wheeler will be perfect for commuting daily. The used bikes are the best option to make the movement comfortable. Motorcycles fulfil the needs as a mode of transportation, offering comfort and convenience in movement. Used bikes in Mysore at an affordable price are perfect as mileage bikes from the top brands are accessible for you.</p>
        <br />
        <p>Browse through the collection of the used bikes online in Mysore at our website to get the best brand bike of your choice. The bikes are collected from trusted sources based on make, model and variant. All the used bikes have run through 120+ levels of checkpoints evaluated by the auto experts before putting them for sale on the CredR site.</p>
        <br />
        <p>As a seller, if you want to sell a used bike to CredR in Mysore, that is also possible. Enjoy a hassle-free buying and selling experience at your doorstep.</p>
        <br />
        <h3><b>Is it a good idea to buy used bikes in Mysore?</b></h3>
        <p>Yes, investing your money in buying a second hand bike is a great idea. You'll buy your favourite bike at an affordable price and enjoy the same level of comfortability, reliability and convenience. No wonder a second hand bike in Mysore gained way more popularity among the masses. Whether travelling to nearby places or using it for daily commuting, a second hand bike offers the same amount of fun as a new bike. At CredR, you'll get a good number of mileage bikes, among which you can choose your favourite one. Assured excellent quality used bikes are accessible at CredR.</p>
        <br />
        <p>All the bikes are refurbished to retain the best quality, which also extends the bike's longevity. Most people choose a second hand bike instead of a new one to enjoy the bike ride at a pocket-friendly price. The used bikes come with added benefits that make purchasing used bikes in Mysore more user-friendly</p>
        <br />
        <p>If you are confused about which bike you should buy? Worry not; figure out the requirement, filter the price and second hand bike brands to get yourself the best used bike or used scooter ever from CredR. Book a test ride, which will help you get a clear picture of the bike's condition. Choose wisely. There is no compulsion. You can choose whichever suits you the most in all ways, whereas everything is taken care of by CredR. </p>
        <br />
        <h4><b>List of the best used bikes in Mysore</b></h4>
        <p>Browse through the assorted collection of second hand bikes in Mysore. The top brands include Bajaj, Hero, Honda, Royal Enfield, Suzuki, Yamaha, TVS, KTM, Harley Davidson, Mahindra, etc. Royal Enfield Classic 350 (₹1,19,100); Hero Splendor pro (₹42,770); Hero Splendor Plus (₹42,315); Hero Passion pro i3s (₹66,430); Honda Dio Std (₹49,290); Honda CB Shine (₹61,750); TVS Star City Plus (₹53,010); Royal Enfield Bullet std (₹1,38,000) and more. </p>
        <br />
        <h4><b>Why is CredR the best place to buy second hand bikes in Mysore?</b></h4>
        <p>CredR, Your Trusted Two-Wheeler brand is the ideal place to get high-quality refurbished bikes, doorstep bike service, free evaluation by experts, easy documentation and quick payment. Whether you buy used bikes, sell old bikes or do bike service at home, we offer the most reliable service to our valuable customers. You’ll get your favourite brand bike at an affordable price in a few clicks.</p>
        <br />
        <p><b>Transparent service:</b> Our focus is on offering quality and transparent service to our valuable customers. Everything is done as per your consent for any of the services you choose. You can  select your favourite bike, take a test ride and finalise your decision. We've based our service on trust. </p>
        <br />
        <p><b>Refurbished bikes:</b> All second hand bikes in Mysore are collected from genuine sellers. A thorough run through based on 120+ parameters by Auto Experts helps to retain the best-in-class quality. The used bikes are refurbished to maintain the top quality at a pocket-friendly price. </p>
        <br />
        <p><b>Host of benefits:</b> You'll get ample benefits if you buy a used bike from us. Benefits like six months of warranty worth 5000 INR and 7 Day buy protection policy make it an excellent purchase for all. All these are added benefits that make your buy satisfactory. </p>
        <br />
        <p><b>Test ride from the doorstep: </b>As you book a used bike, take a doorstep test ride at 399 INR only. It is advised to take a test ride before purchasing the used bike, and this option makes this purchase perfect. </p>
        <br />
        <p><b>Easy Documentation:</b> As you confirm buying your favourite used bike, the paperwork is done quickly. Further, our executives will take the initiatives to get it done smoothly for you. </p>
        <br />
        <p><b>Added benefits:</b> We ensure easy checkouts, premium customer service, and easy selling, servicing, and buying of second hand bikes. </p>
        <br />
        <p>To conclude, the collection of the used bikes in Mysore or used scooters in Mysore are of top quality. It will offer you a sense of satisfaction, freedom and comfort. Buying a second hand scooty in Mysore was never as easy as it was with us. The process is done as quickly as possible in just a few steps: choosing, taking a test ride, and booking the used bike.  Choose CredR and ride with joy. </p>
      </>
    )
    case 'USED-SCOOTERS-IN-PUNE' :return (
      <>
      <h1><b>Buy Used Scooter in Pune At An Affordable Price From CredR </b></h1>
      <p>Are you looking for a used scooter in Pune? Have you ventured from one place to another? Are you a college student looking for a two-wheeler at low cost? Are you looking for daily commuting transport? All your requirements have one answer- Buy a used scooter in Pune.</p>
      <br/>
      <p>Pune is one of the busiest cities in India, where traveling is a matter of concern. To combat traffic congestion, you need a two-wheeler to help you commute efficiently. As we talk about two-wheelers, choosing a second hand scooty in Pune is a great idea. The collection of the used scooters in Pune is trendy among the pre-owned scooty buyers in the city. CredR brings in the best used scooters at an affordable price. </p>
      <br/>
      <p>Buying a second hand scooter is a great option to ease your traveling experience. The demand for scooters has significantly increased over the years. Especially the reliability, convenience, and comfort that a scooter brings make it a perfect choice to move from here to there.</p>
      <br/>
      <p>Used scooters in Pune are accessible in a wide range, and all the popular scooter brands from various manufacturers are now available for resale in the city. You can buy a used scooty for sale at a reasonable price tag displayed on the CredR site. The used scooty in Pune returns good value for money. The majority of the buyers are looking forward to buying a second hand scooter at a low price. If you are looking for something like that, CredR is your best option.</p>
      <br/>
      <h3><b>Popular used Scooters in Pune All Refurbished </b></h3>
      <p>There are 500+ used scooters in Pune at CredR. Popularly used scooters in Pune include many top models from notable brands sold in significant numbers in the used scooter market. Popular used scooters in Pune have used Honda Activa, used Hero Duet LX, used TVS Scooty Streak Std, used TVS Scooty Pep plus, used Suzuki Access UZ, used Honda Activa-I, used Yamaha, used Piaggio, used TVS Ntorq 125, used Honda Aviator Std and more. These used scooters in Pune are in good condition and refurbished to retain top quality.</p>
      <br/>
      <h4><b>Why choose CredR for buying 2nd hand scooty in Pune?</b></h4>
      <p>CredR is one of the Most Preferred Omnichannel Consumer brands for buying, selling, and servicing two-wheelers. 2nd hand scooty in Pune are best in terms of mileage and comfort. CredR showrooms are located at multiple places, second hand scooty prices in Pune are estimated based on the make, model, variant, and year.</p>
      <br/>
      <ul>
        <li>Refurbished bikes</li>
        <li>6 months warranty</li>
        <li>7 day buy protect</li>
        <li>Test ride from doorstep</li>
        <li>Quick Payment</li>
        <li>Reasonably priced</li>
      </ul>
      <p>Buying a second hand scooty in Pune gives you the advantage of traveling on your scooter rather than using crowded public transport. All the second hand scooters available at CredR are inspected multiple times by auto experts before every sale. You’ll get your choice of second hand Activa in Pune without any hassle.</p>
      <br/>
      <p>With the new launch of the doorstep delivery scheme, customers can now have a trial run and buy a second hand scooter in Pune from home comfort. You can visit the nearest CredR store to purchase second hand scooters. Some of the bestsellers in the second hand scooter in Pune include Honda Activa, Yamaha Ray, Honda Dio, Suzuki Access, and TVS Jupiter.</p>
      <br/>
      <p>CredR ensures an easy & hassle-free service. With our upgraded plans, added features, and extraordinary benefits, you’ll be satisfied with your purchase of a used scooter for sale in Pune.</p>
      
      </>
    )
    default:
    return null
  }
}

export function getContent(location, makeModel, vehicle) {
  console.log("scooter", vehicle)
  if (location === '/used-bikes-in-Bangalore') {
    return getContentHTMLString('USED-BIKES-IN-BANGALORE')
  }

  if (location === '/all-used-bikes-in-Bangalore/Bajaj-Pulsar') {
    return getContentHTMLString('ALL-USED-BIKES-IN-BANGALORE-BAJAJ-PULSAR')
  }

  if (location === '/used-scooters-in-Bangalore') {
    return getContentHTMLString('USED-SCOOTERS-IN-BANGALORE')
  }

  if (location === '/used-scooters-in-Pune') {
    return getContentHTMLString('USED-SCOOTERS-IN-PUNE')
  }

  if (location === '/all-used-bikes-in-Delhi-NCR') {
    return getContentHTMLString('USED-BIKES_DELHI')
  }

  if (location === '/all-used-bikes-in-Bangalore') {
    return getContentHTMLString('USED-BIKES-IN-BANGALORE')
  }

  if (location === `/used-bikes`) {
    return getContentHTMLString('USED-BIKES')
  }
  if (location === `/all-used-bikes-in-Nashik`) {
    return getContentHTMLString('ALL-USED-BIKES-NASHIK')
  }

  if (location === `/all-used-bikes-buy-at-home`) {
    return getContentHTMLString('ALL-USED-BIKES-AT-HOME')
  }

  if (location === `/all-used-scooters-in-Pune/Honda-Activa`) {
    return getContentHTMLString('ALL-USED-SCOOTERS-IN-PUNE-HONDA-ACTIVA')
  }

  if (location === `/all-used-scooters-in-Bangalore/Honda-Activa`) {
    return getContentHTMLString('ALL-USED-SCOOTERS-IN-BANGALORE-HONDA-ACTIVA')
  }

  if (location === `/used-scooters`) {
    return getContentHTMLString('USED-SCOOTERS')
  }

  if (location === `/all-used-bikes-in-Mysore`) {
    return getContentHTMLString('ALL-USED-BIKES-MYSORE')
  }

  if (location === '/all-used-bikes-in-Jaipur') {
    return getContentHTMLString('ALL_USED_BIKES_JAIPUR')
  }

  if (location === '/used-bikes-in-Delhi-NC') {
    return getContentHTMLString('USED-BIKES-IN-DELHI')
  }

  if (location === '/all-used-bikes-in-Ahmedabad') {
    return getContentHTMLString('ALL-USED-BIKES-AHMEDABAD')
  }

  if (location === '/all-used-bikes') {
    return getContentHTMLString('ALL_USED_BIKES')
  }

  if (location === '/all-used-bikes-in-Bangalore/Bajaj-Avenger') {
    return getContentHTMLString('USED-BIKE-BNG-BAJAJ-AVENGER')
  }
  if (location === '/all-used-bikes-in-Bangalore/Bajaj-CT100') {
    return getContentHTMLString('USED-BIKE-BNG-BAJAJ-CT100')
  }

  if (location === '/all-used-bikes-in-Bangalore/Bajaj-Discover') {
    return getContentHTMLString('USED-BIKE-BNG-BAJAJ-DISCOVER')
  }

  if (location === '/used-scooters-in-Bangalore/Piaggio-Vespa') {
    return getContentHTMLString('USED-SCOOTER-BNGLR-PIAGGIO')
  }

  if (location === '/all-used-bikes-in-Bangalore/Royal-Enfield') {
    return getContentHTMLString('USED-BIKES-BNGLR-ENFIELD')
  }
  if (location === '/all-used-bikes-in-Bangalore/Royal-Enfield-Classic') {
    return getContentHTMLString('USED-BIKES-BNGLR-ENFIELD-CLASSIC')
  }

  if (location === '/all-used-bikes-in-Delhi-NCR/Royal-Enfield') {
    return getContentHTMLString('USED-BIKES-DELHI-ENFIELD')
  }
  if (location === '/all-used-bikes-in-Delhi-NCR/Royal-Enfield-Classic') {
    return getContentHTMLString('USED-BIKES-DELHI-ENFIELD-CLASSIC')
  }

  if (location === '/used-scooters-in-Delhi-NCR') {
    return getContentHTMLString('USED-SCOOTERS-IN-DELHI')
  }

  if (location === '/all-used-bikes-in-Mumbai') {
    return getContentHTMLString('USED-BIKES-IN-MUMBAI')
  }

  if (location === '/all-used-bikes-in-Pune/Suzuki-Access') {
    return getContentHTMLString('USED-BIKES-IN-PUNE-SUZUKI-ACCESS')
  }

  if (location === '/all-used-bikes-in-Pune') {
    return getContentHTMLString('USED-BIKES-IN-PUNE')
  }

  if (location === `/used-bikes-in-Jaipur`) {
    return getContentHTMLString('USED-BIKES-IN-JAIPUR')
  }

  // if (location === `HONDA-ACTIVA`) {
  //   return getContentHTMLString('HONDA-ACTIVA')
  // }
  
  return getContentHTMLString(makeModel, vehicle)
}