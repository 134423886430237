import http from 'axios'
import { handleError, handleResponse } from '../utility'
import { API_ENDPOINTS } from '../constants/apiEndpoints'

const API_ENDPOINT_GET_STORE_DESCRIPTION = API_ENDPOINTS.STORE.GET_STORE_DESCRIPTION
const API_ENDPOINT_GET_POPULAR_BIKES = API_ENDPOINTS.STORE.GET_POPULAR_BIKES
const API_ENDPOINT_GET_POPULAR_SEARCHES = API_ENDPOINTS.STORE.GET_POPULAR_SEARCHES
const API_ENDPOINT_GET_TESTIMONIALS = API_ENDPOINTS.STORE.GET_TESTIMONIALS
const API_ENDPOINT_GET_RECENTLY_BOUGHT_BIKES = API_ENDPOINTS.STORE.GET_RECENTLY_BOUGHT_BIKES

function goToStorePage(store) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_GET_STORE_DESCRIPTION, store, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getPopularBikes(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_POPULAR_BIKES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getPopularSearches(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_POPULAR_SEARCHES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getTestimonials(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_TESTIMONIALS.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getRecentlyBoughtBikes(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_RECENTLY_BOUGHT_BIKES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

export {
  goToStorePage,
  getPopularBikes,
  getPopularSearches,
  getTestimonials,
  getRecentlyBoughtBikes
}