import { getPopularBikes } from '../../core/services/storePageServices'
import { GET_POPULAR_BIKES_ACTION } from '../../core/constants/actions'

export function getCredrPopularBikes(cityId = 0) {
  return dispatch => {
    dispatch(request())
    return getPopularBikes(cityId)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const {cityID,bikeNames} = apiResponse.popularBikesList
          dispatch(success({
            cityID,
            bikeNames
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: GET_POPULAR_BIKES_ACTION.REQUEST } }
  function success(popularBikes) { return { type: GET_POPULAR_BIKES_ACTION.SUCCESS, payload: { ...popularBikes } } }
  function failure(error) { return { type: GET_POPULAR_BIKES_ACTION.FAILURE, payload: { ...error } } }
}