import React, { useEffect } from 'react'
import './sideBar.scss'
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUrlSanatizedString } from '../../../core/utility'
import { FRANCHISE } from '../../../core/constants/pageConstatnts'
import Drawer from '@material-ui/core/Drawer'
import { setSideBar } from '../../../redux/actions/sidebarAction'
import { getCity } from '../../../core/services/storageService'

import { API_ENDPOINTS } from '../../../core/constants/apiEndpoints'
let imageSrcPath = API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let closeIcon = imageSrcPath + "/close-btn.svg"

const getHomepageUrl = city => {
  return city ? `/${getUrlSanatizedString(city)}` : '/'
}

const getBikeUrl = city => {
  return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}` : `/all-used-bikes`
}

const SideBar = ({ city }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.sidebar.isOpen)
  const currentCity = useSelector(state => state.currentCity.currentCity)
  const currentPage = useSelector(state => state.activePage.currentPage)

  const getCityOnCondition = () => {
    if (city === 'select' || city === 1) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <Drawer open={isOpen} anchor="right" onClose={() => dispatch(setSideBar(!isOpen))}>
      <button type="button" className="bgTransparent float-right" onClick={() => dispatch(setSideBar(!isOpen))}>
        <img src={closeIcon} alt="close icon" className="closeIcon" />
      </button>
      <div className="sidebar-list-container" onClick={() => dispatch(setSideBar(!isOpen))}>
        <div className="link-container">
          <Link to={getHomepageUrl(currentCity)}>Home</Link>
        </div>
        <div className="link-container">
          <Link to={getBikeUrl(currentCity)}>Buy CredR Bike</Link>
        </div>
        {/* {
          getCityOnCondition() &&
          <div className="link-container">
            <Link to={getCity() ? `/all-used-bikes-buy-at-home-in-${getUrlSanatizedString(getCity().cityName)}?buyathome` : `/all-used-bikes-buy-at-home?buyathome`}>
              <Badge className='buyAtHome' color="secondary" badgeContent='New' showZero>
                Buy At Home
              </Badge>
            </Link>
          </div>
        } */}
        <div className="link-container">
          <Link to='/sellbikes'>Sell Your Bike</Link>
        </div>
        <div className="link-container">
          <Link to='/new-bikes'>New Bikes</Link>
        </div>
        <div className="link-container">
          <Link to="/showroom-locator" >Showroom Locator</Link>
        </div>
        <div className="link-container">
          <Link to='/franchise'>Own A Franchise</Link>
        </div>
        <div className="link-container">
          <Link to='/student-ambassador'>Student Ambassador</Link>
        </div>
        <div className="link-container">
          <Link to="/investors" >Our Investors</Link>
        </div>
        {/* <div className="link-container">
          <Link to="/door-step-service" >Service Your Bike</Link>
        </div> */}
        {/* <Link className="csDetails font10 font-semibold text-white" to='/door-step-service-landing'>New Service</Link> */}
        {/* <div className="link-container">
          <Link to="/sellbikes-enhanced" >Sell enhanced</Link>
        </div> */}
        {/* <div className="link-container">
          <Link to="/buy-landing-page" >Buy Bike Ads</Link>
        </div> */}
        <div className="link-container">
          <Link to="/sell-your-bike-buy-new-bike">Sell Your Bike & Buy New Bike</Link>
        </div>
        {/* <div className="link-container">
          <Link to="/sell-your-bike-buy-new-bike-upgrade">Sell Your Bike & Buy New Bike Upgrade</Link>
        </div> */}
        {/* <div className="link-container">
        <Link to="/sell-landing-page-for-ads">Sell Your Bike For Ads</Link>
        </div> */}
        {/* <div className="link-container">
          <Link to="/careers" >Careers</Link>
        </div> */}
        <div className="link-container" style={{ position: 'relative' }}>
          <Link to="/contact-us">For Queries/Complaints</Link>
          <div className='big-circle'>
          </div>
        </div>
        {/* <div className="link-container">
          <Link to="/meet-our-ceo">Meet Our CEO</Link>
        </div> */}
        {/* <div className="link-container">
          <Link to="/service-ads/">New Service</Link>
        </div> */}
        <div className="link-container">
          <a href="https://www.credr.com/blog/">Blog</a>
        </div>
        <div className="link-container">
          <a href="https://docs.google.com/spreadsheets/u/3/d/e/2PACX-1vQo98pyxhIWw9kmd_x6BXrybtlCjES5_TNDEOudv1XjOAmqyzp7XJMbtX-vb6y31xLI-bti2VUIl5Bl/pubhtml?gid=634347005&single=true" target="_blank" rel="noopener noreferrer">Ex-Employees Verification</a>
        </div>
        {
          currentPage === FRANCHISE &&
          <span className="navContact" itemProp="telephone">
            <i className="navPhoneIcon float-left"></i>
              091 5200 9765
              </span>
          //   :
          // <span className="navContact" itemProp="telephone">
          //   <a href="mailto:cs@credr.com" className="pl-0 pt-0 pb-0">
          //     <i className="mailIcon float-left"></i>
          //     <div className="franchiseEmails">cs@credr.com</div>
          //   </a>
          // </span>
        }
        {/* {
          currentPage !== FRANCHISE && <span className="navContact">
            <a href="https://wa.me/917353333200?text=Hello%20CredR" target="_blank" rel="noopener noreferrer" className="pl-0 pt-0 pb-0">
              <i className="Icon float-left"></i>
              <div className="franchiseEmails">73533 33200</div>
            </a>
          </span>
        } */}
      </div>

    </Drawer>
  )
}

export default SideBar