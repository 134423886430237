import { SEARCH_BIKE_BY_CITY_ACTION } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  searchedBikes: [],
  isLoading: false
}

export default function sellSearchBikeReducer(state = intialState, action) {

  switch (action.type) {
    case SEARCH_BIKE_BY_CITY_ACTION.REQUEST:
      return newState(state, { isLoading: true })

    case SEARCH_BIKE_BY_CITY_ACTION.SUCCESS:
      const { sellBikes } = action.payload
      return newState(state, { searchedBikes: sellBikes, isLoading: false })

    case SEARCH_BIKE_BY_CITY_ACTION.FAILURE:
      const { error } = action.payload
      return newState(state, { error, ...intialState })

    default:
      return state
  }
}