import { SET_DRAWER_SUPERBIKE } from '../../core/constants/actions'
import { newState } from '../../core/utility'

const intialState = {
  isOpenSuperBikeInfo: false
}


export default function drawerPageReducerSuperBike(state = intialState, action) {

  if (action.type === SET_DRAWER_SUPERBIKE) {
    const { isOpenSuperBikeInfo } = action.payload
    return newState(state, { isOpenSuperBikeInfo })
  }
  return state
}
