import { getAllMake } from '../../core/services/bikeServices'
import { BRAND_ACTION } from '../../core/constants/actions'

export function getAllBrands() {
  return dispatch => {
    dispatch(request())
    return getAllMake()
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const brandList = apiResponse.makeList
          dispatch(success({
            brandList
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: BRAND_ACTION.REQUEST } }
  function success(brands) { return { type: BRAND_ACTION.SUCCESS, payload: { ...brands } } }
  function failure(error) { return { type: BRAND_ACTION.FAILURE, payload: { ...error } } }
}