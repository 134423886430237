import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getStore } from '../shared/app/store'
import App from '../shared/app/App'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import { loadableReady } from '@loadable/component'
import Fingerprint2 from 'fingerprintjs2'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { RECAPTCHA_KEY } from '../shared/core/constants/apiEndpoints'

const initialState = window.__INITIAL_STATE__

delete window.__INITIAL_STATE__

const store = getStore(initialState)

const generateDevId = async () => {
  const savedId = localStorage.getItem('devId')
  if (!savedId) {
    const components = await Fingerprint2.getPromise()
    const value = components.map(component => component.value)
    const devId = Fingerprint2.x64hash128(value.join(''), 30101992)
    localStorage.setItem('devId', devId)
  }
}
generateDevId()

ReactGA.initialize('UA-58228248-1')

loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter>
      <GoogleReCaptchaProvider useEnterprise={true} reCaptchaKey={RECAPTCHA_KEY}>
        <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </Provider>
    , document.getElementById('root'))
})