import { getBikes } from '../../redux/actions/bikeListAction'
import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { BIKE_LIST } from '../constants/pageConstatnts'
import { setCurrentCity } from '../../redux/actions/selectedCityAction'
import { parseString, getPayload } from '../utility'
import query from 'query-string'
import { getStoreList } from '../../redux/actions/storeListAction'

export function bikeListPreload(store, url) {
  const promises = []
  promises.push(store.dispatch(setCurrentPage(BIKE_LIST)))
  const search = query.stringify(url.search)
  const params = Object.assign({}, url.params)
  let payload = getPayload(params, search)
  if ('city' in params) {
    const cityName = parseString(params.city)
    const state = store.getState()
    const cities = state.cities.cityList
    const city = cities.find(cityObject => {
      return cityName.toLowerCase() === cityObject.cityName.toLowerCase()
    })
    if (city) {
      const { cityName, ...rest } = payload
      payload = { ...rest, cityId: city.cityId }
      promises.push(store.dispatch(getStoreList(city.cityId)))
      promises.push(store.dispatch(setCurrentCity(city.cityName)))
    }
  } else {
    promises.push(store.dispatch(setCurrentCity()))
  }
  promises.push(store.dispatch(getBikes(payload)))
  return promises
}