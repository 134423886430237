import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { BIKE_DETAIL } from '../constants/pageConstatnts'
import { setCurrentCity } from '../../redux/actions/selectedCityAction'
import { parseString } from '../utility'
import { getBikeById } from '../../redux/actions/bikeAction'

export function bikeDetailPreload(store, url) {
  const promises = []
  promises.push(store.dispatch(setCurrentPage(BIKE_DETAIL)))
  const params = Object.assign({}, url.params)
  if ('city' in params) {
    const cityName = parseString(params.city)
    const state = store.getState()
    const cities = state.cities.cityList
    const city = cities.find(cityObject => {
      return cityName.toLowerCase() === cityObject.cityName.toLowerCase()
    })
    if (city) {
      promises.push(store.dispatch(setCurrentCity(city.cityName)))
    }
  }
  if ('vehicleId' in params) {
    promises.push(store.dispatch(getBikeById(params.vehicleId)))
  }
  return promises
}