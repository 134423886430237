import { API_ENDPOINTS } from '../constants/apiEndpoints'
import { handleError, handleResponse } from '../utility'
import http from 'axios'

const API_ENDPOINTS_GET_ALL_BANNERS = API_ENDPOINTS.MISC.GET_BANNERS
const API_ENDPOINTS_GET_ALL_CITIES = API_ENDPOINTS.MISC.GET_CITIES
const API_ENDPOINTS_GET_STORES = API_ENDPOINTS.MISC.GET_STORE_LIST
const API_ENDPOINTS_GET_ALL_STORES = API_ENDPOINTS.MISC.GET_ALL_STORES
const API_ENDPOINTS_SAVE_RESULT = API_ENDPOINTS.MISC.SAVE_SEARCH
const API_ENDPOINTS_GET_AREA_NAMES = API_ENDPOINTS.MISC.GET_AREA_NAME
const API_ENDPOINTS_GET_SUB_AREAS = API_ENDPOINTS.MISC.GET_LOCALITY_NAME
const API_ENDPOINTS_GET_MAKES = API_ENDPOINTS.MISC.GET_MAKES
const API_ENDPOINTS_GET_MODELS = API_ENDPOINTS.MISC.GET_MODELS
const API_ENDPOINTS_GENERATE_OTP = API_ENDPOINTS.MISC.GENERATE_OTP
const API_ENDPOINTS_VERIFY_OTP = API_ENDPOINTS.MISC.VERIFY_OTP
const API_ENDPOINTS_GET_SHOWROOMS = API_ENDPOINTS.MISC.SHOWROOM_LIST
const API_ENDPOINTS_GET_TIME_SLOTS = API_ENDPOINTS.MISC.GET_TIME_SLOTS
const API_ENDPOINTS_VALIDATE_COUPON = API_ENDPOINTS.MISC.VALIDATE_COUPON
const API_ENDPOINTS_GET_MODELS_BY_MAKE = API_ENDPOINTS.MISC.GET_VARIANTS_BY_MODEL
const API_ENDPOINTS_GET_SERVICE_CITIES = API_ENDPOINTS.MISC.GET_SERVICE_CITIES
const API_ENDPOINTS_GET_AVAILABLE_TIME_SLOTS = API_ENDPOINTS.MISC.GET_AVIALABLE_TIME_SLOTS
const API_ENDPOINTS_GET_PRICE = API_ENDPOINTS.BIKES.GET_EXCHANGE_SELL_PRICE
const API_ENDPOINTS_GET_RUNNER_AVAILABLE_TIME_SLOTS = API_ENDPOINTS.MISC.GET_RUNNER_AVAILABLE_SLOTS
const API_ENDPOINTS_CHECK_PAYMENT_STATUS = API_ENDPOINTS.MISC.CHECK_PAYMENT_STATUS
const GET_BIKE_DETAILS = API_ENDPOINTS.MISC.GET_BIKE_DETAILS
const GET_NOTIFICATION = API_ENDPOINTS.MISC.GET_NOTIFICATION
const GET_VARIANTS_BY_ID = API_ENDPOINTS.BIKES.GET_VARIANTS_BY_ID
const GET_SERVICE_PINCODES = API_ENDPOINTS.MISC.GET_SERVICE_SLOTS
const GET_CITIES_ON_TYPE = API_ENDPOINTS.MISC.GET_CITY_LIST_ON_TYPE
const GET_SERVICE_LEAD_DETAILS = API_ENDPOINTS.MISC.GET_SERVICE_LEAD_DETAILS
const GET_QR_DATA = API_ENDPOINTS.MISC.GET_QR_DATA
const GET_SERVICE_PLANS = API_ENDPOINTS.MISC.GET_SERVICE_PLANS


function getBanners() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  console.log(API_ENDPOINTS_GET_ALL_BANNERS,"API_ENDPOINTS_GET_ALL_BANNERS aakash")
  console.log(process.env,"process.env aakash")
  console.log(JSON.stringify(process.env.env, null, 2),"process.env.env aakash")
  return http.get(API_ENDPOINTS_GET_ALL_BANNERS, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getPrice(){
  const headers = {}
  if(typeof window !== 'undefined') {
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINTS_GET_PRICE, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getCities() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINTS_GET_ALL_CITIES, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getStores(cityId = 0) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINTS_GET_STORES.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAllStores() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINTS_GET_ALL_STORES, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function saveSearchResult(searchResult) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_SAVE_RESULT, searchResult,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getBikeInfo(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(GET_BIKE_DETAILS, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getNotification(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(GET_NOTIFICATION, payload,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getMakes(cityId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINTS_GET_MAKES.replace('<CITY_ID>', cityId)
  return http.get(url,{headers})
    .then(handleResponse)
    .catch(handleError)
}

function getModels(makeName, cityId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  let url = API_ENDPOINTS_GET_MODELS.replace('<CITY_ID>', cityId)
  url = url.replace("<MAKE_NAME>", makeName)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getVariants(modelName) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  let url = API_ENDPOINTS_GET_MODELS_BY_MAKE.replace('<MODEL_NAME>', modelName)
  url = url.replace("<MODEL_NAME>", modelName)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getVariantsById(modelId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  let url = GET_VARIANTS_BY_ID.replace('<MODEL_ID>', modelId)
  url = url.replace("<MODEL_ID>", modelId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAreasByCity(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GET_AREA_NAMES, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getSubAreas(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GET_SUB_AREAS, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function generateOTP(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GENERATE_OTP, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function verifyOTP(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_VERIFY_OTP, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getShowrooms(cityId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINTS_GET_SHOWROOMS.replace('<CITY_ID>', cityId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAvailableSlots(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GET_TIME_SLOTS, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getAvailableTimeSlots(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GET_AVAILABLE_TIME_SLOTS, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getRunnerAvailableTimeSlots(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_GET_RUNNER_AVAILABLE_TIME_SLOTS, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function validateCoupon(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINTS_VALIDATE_COUPON, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getServiceCities(){
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINTS_GET_SERVICE_CITIES, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function checkStatus() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINTS_CHECK_PAYMENT_STATUS, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getServiceAvailableTimeSlots(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(GET_SERVICE_PINCODES, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getCitiesOnType(type) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = GET_CITIES_ON_TYPE.replace('<TYPE>', type)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getServiceLeadInfo(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(GET_SERVICE_LEAD_DETAILS, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getQRdata(leadId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = GET_QR_DATA.replace('<LEAD_ID>', leadId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getServicePlans() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(GET_SERVICE_PLANS, {headers})
    .then(handleResponse)
    .catch(handleError)
}

export {
  getBanners,
  getCities,
  getStores,
  saveSearchResult,
  getAllStores,
  getAreasByCity,
  getSubAreas,
  getMakes,
  getModels,
  generateOTP,
  verifyOTP,
  getShowrooms,
  getAvailableSlots,
  validateCoupon,
  getServiceCities,
  getVariants,
  getAvailableTimeSlots,
  getPrice,
  getRunnerAvailableTimeSlots,
  checkStatus,
  getBikeInfo,
  getNotification,
  getVariantsById,
  getServiceAvailableTimeSlots,
  getCitiesOnType,
  getServiceLeadInfo,
  getQRdata,
  getServicePlans
}