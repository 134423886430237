import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Link } from 'react-router-dom'
import SideBar from './Side-Bar/sideBar'
import SearchBar from './Search-Bar/searchBar'
import InfoBar from './Info-Bar/infoBar'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import query from 'query-string'
import { HOME, BIKE_LIST, BIKE_DETAIL, FRANCHISE, AD_SELL_PAGE, GOOGLE_ADS_SELL } from '../../core/constants/pageConstatnts'
import { removeCity } from '../../core/services/storageService'
import { API_ENDPOINTS } from '../../core/constants/apiEndpoints'
import { getUrlSanatizedString } from '../../core/utility'
let imageSrcPath = API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let logo = imageSrcPath + '/crdrlogo.png'

const getClass = page => {
  return page === FRANCHISE ? 'franchiseNavRight float-right' : 'navbarRight float-right'
}

const getHomepageUrl = city => {
  return city ? `/${getUrlSanatizedString(city)}` : '/'
}
// const getAdClass = page => {
//   return page === AD_SELL_PAGE ? 'franchiseNavRight float-right' : 'navbarRight float-right'
// }

const getNavClass = page => {
  return (page === HOME || page === BIKE_DETAIL || page === BIKE_LIST) ? 'navbar navebarCustom bg-dark' : (page === GOOGLE_ADS_SELL) ? 'navbar navebarCustom nav-ads' : 'navbar navebarCustom nav-bg'
}

const Header = ({ show, showHeaderLayot }) => {
  const [disable, setDisable] = useState(false)
  const [disableHeader, setDisableHeader] = useState(true)
  const [disableAds, setDisableAds] = useState(false)
  const [disableHeaderAds, setDisableHeaderAds] = useState(true)
  const [city, setCity] = useState('')
  const [disableNavbar, setDisableNavbar] = useState(true)
  const location = useLocation()
  const currentPage = useSelector(state => state.activePage.currentPage)
  const currentCity = useSelector(state => state.currentCity.currentCity)
  const device = useSelector(state => state.deviceDetail.device)

  useEffect(() => {
    if (location.search) {
      const queryParams = query.parse(location.search)
      if ('utm_campaign' in queryParams) {
        if (queryParams.utm_campaign === "Leads-Francise_bangalore") {
          setDisable(true)
          setDisableAds(true)
        }
        if (queryParams.utm_campaign === "Leads-Francise_jaipur") {
          setDisable(true)
          setDisableAds(true)
        }
      }
    }
    if (location.pathname === '/sell-landing-page-for-ads-bangalore') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-nashik') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-delhi') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads-mumbai') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_bangalore') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-ads/') {
      setDisableHeader(false)
    }
    if (location.pathname === '/buy-landing-page') {
      setDisableHeader(false)
    }
    if (location.pathname === '/buy-landing-page-for-zest-bikes') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/sell_bike_fb_bangalore') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_mumbai') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_nashik') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-landing-page-for-google-ads') {
      setDisableHeaderAds(false)
    }
    if (location.pathname === '/struggling-to-sell-your-Honda-Activa-or-Honda-CD-Deluxe') {
      setDisableHeaderAds(false)
    }
    if (location.pathname === '/sell_bike_fb_delhi') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell_bike_fb_jaipur') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sellbikes/banglore/yamaha/') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/banglore/yamaha') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/delhi/yamaha') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/pune') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/jaipur') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/ahmedabad') {
      setDisableHeader(false)
    }
    if (location.pathname === '/sell-your-bike-buy-new-bike/bhilwara') {
      setDisableHeader(false)
    }
    if(location.pathname === '/sell-your-bike-buy-new-bike/pune') {
      setDisableHeader(false)
    }
    if(location.pathname === '/sell-your-bike-buy-new-bike/jaipur') {
      setDisableHeader(false)
    }
    if(location.pathname === '/sell-your-bike-buy-new-bike/ahmedabad') {
      setDisableHeader(false)
    }
    if(location.pathname === '/sell-your-bike-buy-new-bike/bhilwara') {
      setDisableHeader(false)
    }
    if (location.state === '?care=yes') {
      setDisableHeader(false)
      // setDisable(true)
    }
    if (location.pathname === '/door-step-service-landing') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/student-ambassador') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/ad-sell-page') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/v') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/ins') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/ad-sell-page/thank-you') {
      setDisableHeader(false)
      setDisableNavbar(false)
    }
    if (location.pathname === '/s') {
      setDisableHeader(false)
    }

    
  }, [])


  const setHeaderVisibile = () => {
    if ( location.pathname !== '/student-ambassador' && location.pathname !=='/ad-sell-page') {
      console.log("showHeader", showHeaderLayot,location.pathname )
      if(showHeaderLayot){
        setDisableNavbar(true)
        setDisableHeader(true)
      }
    }
    // if ( location.pathname !== '/v') {
    //   if(showHeaderLayot){
    //     setDisableNavbar(true)
    //     setDisableHeader(true)
    //   }
    // }
  }

  useEffect(() =>{

    if(location && location.pathname) setHeaderVisibile()
  }, [location.pathname])

  const onHomeIconChange = () => {
    window.scrollTo(0, 0)
  }

  const onHandleCity = (city) => {
    setCity(city)
  }
  return (
    <>
      {
        disableNavbar && showHeaderLayot ?
          <div className={device === 'phone' && show ? "header selected" : 'header'} >
            {
              <nav className={getNavClass(currentPage)}>
                {
                  disable || !disableHeader ? <img src={logo} alt="CredR" className='navbar-brand' /> : disableAds || !disableHeaderAds ? <img src={logo} alt="CredR" className='navbar-brand-ads' /> : <Link to={getHomepageUrl(currentCity)} className="navbar-brand"><img src={logo} alt="CredR" onClick={onHomeIconChange} /></Link>
                }
                {
                  Boolean(currentPage === HOME || currentPage === BIKE_LIST || currentPage === BIKE_DETAIL) && <SearchBar onChangeOfCity={onHandleCity} />
                }
                <div className={getClass(currentPage)} >
                  {
                    disableHeader && disableHeaderAds &&
                    <>
                      <InfoBar page={currentPage} city={city} />
                      <SideBar city={city} />
                    </>
                  }
                </div>
              </nav>
            }
          </div>
          :
          <div className='landingPageHeaderRemoval'></div>
      }
    </>
  )
}

export default Header