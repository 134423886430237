import React, { useEffect } from 'react'
import Loader from '../Loader'

const FullPageLoader = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    root.classList.add('loader-show')
    return () => {
      root.classList.remove('loader-show')
    }
  })
  return (
    <div className='fallback-loader-backdrop show'>
      <Loader />
    </div>
  )
}

export default FullPageLoader