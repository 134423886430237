export const BANNER_ACTION = {
  REQUEST: 'BANNER_REQUEST',
  SUCCESS: 'BANNER_SUCCESS',
  FAILURE: 'BANNER_FAILURE'
}

export const BIKE_LIST_ACTION = {
  REQUEST: 'BIKE_LIST_REQUEST',
  SUCCESS: 'BIKE_LIST_SUCCESS',
  FAILURE: 'BIKE_LIST_FAILURE'
}

export const BIKE_ACTION = {
  REQUEST: 'BIKE_REQUEST',
  SUCCESS: 'BIKE_SUCCESS',
  FAILURE: 'BIKE_FAILURE'
}

export const CITIES_ACTION = {
  REQUEST: 'CITIES_REQUEST',
  SUCCESS: 'CITIES_SUCCESS',
  FAILURE: 'CITIES_FAILURE'
}

export const BRAND_ACTION = {
  REQUEST: 'BRAND_REQUEST',
  SUCCESS: 'BRAND_SUCCESS',
  FAILURE: 'BRAND_FAILURE'
}

export const BUDGET_BIKE_LIST_ACTION = {
  REQUEST: 'BUDGET_BIKE_LIST_REQUEST',
  SUCCESS: 'BUDGET_BIKE_LIST_SUCCESS',
  FAILURE: 'BUDGET_BIKE_LIST_FAILURE'
}

export const SIMILAR_BIKE_LIST_ACTION = {
  REQUEST: 'SIMILAR_BIKE_LIST_REQUEST',
  SUCCESS: 'SIMILAR_BIKE_LIST_SUCCESS',
  FAILURE: 'SIMILAR_BIKE_LIST_FAILURE'
}

export const POPULAR_BIKE_LIST_ACTION = {
  REQUEST: 'POPULAR_BIKE_LIST_REQUEST',
  SUCCESS: 'POPULAR_BIKE_LIST_SUCCESS',
  FAILURE: 'POPULAR_BIKE_LIST_FAILURE'
}

export const STORE_LIST_ACTION = {
  REQUEST: 'STORE_LIST_REQUEST',
  SUCCESS: 'STORE_LIST_SUCCESS',
  FAILURE: 'STORE_LIST_FAILURE',
  RESET: 'STORE_LIST_RESET'
}

export const ALL_STORE_LIST_ACTION = {
  REQUEST: 'ALL_STORE_LIST_REQUEST',
  SUCCESS: 'ALL_STORE_LIST_SUCCESS',
  FAILURE: 'ALL_STORE_LIST_FAILURE',
  RESET: 'ALL_STORE_LIST_RESET'
}

export const SEARCH_BIKE_ACTION = {
  REQUEST: 'SEARCH_BIKE_REQUEST',
  SUCCESS: 'SEARCH_BIKE_SUCCESS',
  FAILURE: 'SEARCH_BIKE_FAILURE'
}

export const SELL_BIKE_SEARCH_ACTION = {
  REQUEST: 'SELL_SEARCH_BIKE_REQUEST',
  SUCCESS: 'SELL_SEARCH_BIKE_SUCCESS',
  FAILURE: 'SELL_SEARCH_BIKE_FAILURE',
  RESET: 'SELL_SEARCH_BIKE_RESET'
}

export const BIKE_INSPECTION_ACTION = {
  REQUEST: 'BIKE_INSPECTION_REQUEST',
  SUCCESS: 'BIKE_INSPECTION_SUCCESS',
  FAILURE: 'BIKE_INSPECTION_FAILURE'
}

export const JEV_LIST_ACTION = {
  REQUEST: 'JEV_LIST_REQUEST',
  SUCCESS: 'JEV_LIST_SUCCESS',
  FAILURE: 'JEV_LIST_FAILURE'
}

export const JEV_VEHICLE_ACTION = {
  REQUEST: 'JEV_VEHICLE_REQUEST',
  SUCCESS: 'JEV_VEHICLE_SUCCESS',
  FAILURE: 'JEV_VEHICLE_FAILURE'
}

export const TOP_SELLING_BIKE_LIST_ACTION = {
  REQUEST: 'TOP_SELLING_BIKE_LIST_REQUEST',
  SUCCESS: 'TOP_SELLING_BIKE_LIST_SUCCESS',
  FAILURE: 'TOP_SELLING_BIKE_LIST_FAILURE'
}

export const GET_STORE_ACTION = {
  REQUEST: 'GET_STORE_ACTION_REQUEST',
  SUCCESS: 'GET_STORE_ACTION_SUCCESS',
  FAILURE: 'GET_STORE_ACTION_FAILURE'
}

export const GET_POPULAR_BIKES_ACTION = {
  REQUEST: 'GET_POPULAR_BIKES_ACTION_REQUEST',
  SUCCESS: 'GET_POPULAR_BIKES_ACTION_SUCCESS',
  FAILURE: 'GET_POPULAR_BIKES_ACTION_FAILURE'
}

export const GET_POPULAR_SEARCHES_ACTION = {
  REQUEST: 'GET_POPULAR_SEARCHES_ACTION_REQUEST',
  SUCCESS: 'GET_POPULAR_SEARCHES_ACTION_SUCCESS',
  FAILURE: 'GET_POPULAR_SEARCHES_ACTION_FAILURE'
}

export const GET_TESTIMONIALS_ACTION = {
  REQUEST: 'GET_TESTIMONOIALS_ACTION_REQUEST',
  SUCCESS: 'GET_TESTIMONOIALS_ACTION_SUCCESS',
  FAILURE: 'GET_TESTIMONOIALS_ACTION_FAILURE'
}

export const RECENTLY_BOUGHT_BIKES_ACTION = {
  REQUEST: 'RECENTLY_BOUGHT_BIKES_ACTION_REQUEST',
  SUCCESS: 'RECENTLY_BOUGHT_BIKES_ACTION_SUCCESS',
  FAILURE: 'RECENTLY_BOUGHT_BIKES_ACTION_FAILURE'
}

export const GET_AREA_NAME_ACTION = {
  REQUEST: 'GET_AREA_NAME_ACTION_REQUEST',
  SUCCESS: 'GET_AREA_NAME_ACTION_SUCCESS',
  FAILURE: 'GET_AREA_NAME_ACTION_FAILURE'
}

export const GET_LOCALITY_ACTION = {
  REQUEST: 'GET_LOCALITY_ACTION_REQUEST',
  SUCCESS: 'GET_LOCALITY_ACTION_SUCCESS',
  FAILURE: 'GET_LOCALITY_ACTION_FAILURE'
}

export const SEARCH_BIKE_BY_CITY_ACTION = {
  REQUEST: 'SEARCH_BIKE_BY_CITY_REQUEST',
  SUCCESS: 'SEARCH_BIKE_BY_CITY_SUCCESS',
  FAILURE: 'SEARCH_BIKE_BY_CITY_FAILURE'
}

export const SELECT_BRAND_ACTION = {
  REQUEST: 'SELECT_BRAND_REQUEST',
  SUCCESS: 'SELECT_BRAND_SUCCESS',
  FAILURE: 'SELECT_BRAND_FAILURE'
}

export const SELECT_CITY_ACTION = {
  REQUEST: 'SELECT_CITY_REQUEST',
  SUCCESS: 'SELECT_CITY_SUCCESS',
  FAILURE: 'SELECT_CITY_FAILURE'
}

export const SET_BIKE_FILTER = 'SET_BIKE_FILTER'
export const RESET_BIKE_FILTER = 'RESET_BIKE_FILTER'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY'
export const SET_CURRENT_CITY_ID = 'SET_CURRENT_CITY_ID'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_DRAWER = 'SET_DRAWER'
export const SET_DRAWER_SUPERBIKE='SET_DRAWER_SUPERBIKE'
export const SET_LOADER = 'SET_LOADER'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const SET_DEVICE = 'SET_DEVICE'
export const SET_TITLE = 'SET_TITLE'
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE'
export const RESET_SELECTED_STORE = 'RESET_SELECTED_STORE'
export const SET_THANK_YOU = 'SET_THANK_YOU'
export const RESET_THANK_YOU = 'RESET_THANK_YOU'
export const SET_SHOW = 'SET_SHOW'